<template>
  <ul class="list-news col3">
    <li v-for="news in newsList" :key="news.id" @click="onClicked(news)">
      <h3>{{ news.title }}</h3>
      <p class="fig">
        <img :src="'/images/' + news.thumb">
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'News',

  computed: {
    newsList: function() {
      return this.$store.state.newsList;
    },
  },

  methods: {
    onClicked(news) {
      if (news.nativeTab && window.UMNative) {
        window.UMNative.changeAppNativeTab(
          news.nativeTab.tab,
          news.nativeTab.sub,
          news.nativeTab.content
        );
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list-news {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
  li {
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: $box_shadow;
    background-color: $color_white;
    h3 {
      color: $color_black;
      font-weight: $font_weight_bold;
      line-height: 50px;
      height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: rgb(240, 240, 240);
      @include ellipsis;
    }
    .fig {
      width: 100%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.graph {
        img {
          object-fit: contain;
        }
      }
      .content {
        position: absolute;
        left: 0;
        bottom: 0;
        color: $color_black;
        font-size: 16px;
        width: calc(100% - 28px);
        height: 40px;
        line-height: 40px;
        padding-left: 14px;
        padding-right: 14px;
        background-color: rgba(234, 234, 234, 0.86);
        @include ellipsis;
      }
    }
  }
  &.col3 {
    li {
      width: calc((100% - 120px) / 3);
      margin-bottom: 40px;
      h3 {
        font-size: 18px;
      }
      .fig {
        height: 184px;
      }
    }
  }
}

@include sp-screen {
  .list-news {
    &.col3 {
      li {
        width: 100%;
      }
    }
  }
}
</style>
