<template>
    <div>
        <Header current="analytics" />
        <div class="container">
            <div class="content-wrapper">
                <AnalyticsMenu tag="visitors-and-sales" :largeBizCd="largeBizCd" :visitorView="visitorView" />
                <div class="content">
                    <div class="fixed">
                        <AnalyticsHeader :title="title" :mode="$route.params.mode" @show-calendar="isDatePickerDialogShown = true" />
                        <AnalyticsTab :tabItems="tabItems" :currentIndex="currentTabIndex" @select="currentTabIndex = $event" />
                        <AnalyticsAxis
                            :mode="$route.params.mode"
                            :leftSide="leftSide"
                            :num="axisNum"
                            :start="startDate"
                            :end="endDate"
                            @update="onAxisUpdated"
                        />
                    </div>
                    <div class="content-analytics">
                        <div v-if="isWeatherShown" class="accordion" :class="{ 'is-open': isOpen }">
                            <AnalyticsWeather
                                :mode="$route.params.mode"
                                :leftSide="leftSide"
                                :num="axisNum"
                                :isOpen="isOpen"
                                @open="isOpen = !isOpen"
                            />
                            <AnalyticsEvent :mode="$route.params.mode" :leftSide="leftSide" :num="axisNum" />
                        </div>
                        <AnalyticsSalesGraph
                            :mode="$route.params.mode"
                            :leftSide="leftSide"
                            :num="axisNum"
                            :visitorOption="$store.getters.visitorOption"
                            @viewHint="viewHint"
                        />

                        <AnalyticsRecognitionGraph
                            v-if="currentTabIndex === 0"
                            :mode="$route.params.mode"
                            :visitorOption="$store.getters.visitorOption"
                            :leftSide="leftSide"
                            :num="axisNum"
                        />
                        <AnalyticsGenderGraph
                            v-if="currentTabIndex === 1"
                            :mode="$route.params.mode"
                            :leftSide="leftSide"
                            :num="axisNum"
                            @viewHint="viewHint"
                        />
                        <AnalyticsRepeaterGraph
                            v-if="currentTabIndex === 2"
                            :mode="$route.params.mode"
                            :leftSide="leftSide"
                            :num="axisNum"
                            @viewHint="viewHint"
                        />
                    </div>
                </div>
            </div>
            <DatePickerDialog v-if="isDatePickerDialogShown" @cancel="isDatePickerDialogShown = false" @next="onDatePickerNext" />
            <IntervalPickerDialog
                v-if="isIntervalPickerDialogShown"
                :fromDate="fromDate"
                :toDate="toDate"
                @cancel="isIntervalPickerDialogShown = false"
                @done="onIntervalPickerDone"
                @back="onIntervalDialogBack"
            />
            <HintDialog v-if="isHintDialogShown" @close="isHintDialogShown = false" :kind="kind" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import mixin from '@/mixin/index.js';
import analyticsMixin from '@/mixin/analytics.js';
import setparam from '@/mixin/setparam.js';
import apicall from '@/mixin/apicall.js';
import analyticssubmenu from '@/mixin/analyticssubmenu.js';
import Header from '@/components/Header.vue';
import AnalyticsMenu from '@/components/menu/AnalyticsMenu.vue';
import AnalyticsHeader from '@/components/analytics/AnalyticsHeader.vue';
import AnalyticsTab from '@/components/analytics/AnalyticsTab.vue';
import AnalyticsAxis from '@/components/analytics/AnalyticsAxis.vue';
import AnalyticsSalesGraph from '@/components/analytics/AnalyticsSalesGraph.vue';
import AnalyticsRecognitionGraph from '@/components/analytics/AnalyticsRecognitionGraph.vue';
import AnalyticsGenderGraph from '@/components/analytics/AnalyticsGenderGraph.vue';
import AnalyticsRepeaterGraph from '@/components/analytics/AnalyticsRepeaterGraph.vue';
import AnalyticsWeather from '@/components/analytics/AnalyticsWeather.vue';
import AnalyticsEvent from '@/components/analytics/AnalyticsEvent.vue';
import DatePickerDialog from '@/components/dialog/DatePickerDialog.vue';
import IntervalPickerDialog from '@/components/dialog/IntervalPickerDialog.vue';
import HintDialog from '@/components/dialog/HintDialog.vue';

export default {
    name: 'VisitorsAndSales',

    components: {
        Header,
        AnalyticsMenu,
        AnalyticsHeader,
        AnalyticsTab,
        AnalyticsAxis,
        AnalyticsSalesGraph,
        AnalyticsRecognitionGraph,
        AnalyticsGenderGraph,
        AnalyticsRepeaterGraph,
        AnalyticsWeather,
        AnalyticsEvent,
        DatePickerDialog,
        IntervalPickerDialog,
        HintDialog,
    },

    data() {
        return {
            isHintDialogShown: false,
            isDatePickerDialogShown: false,
            isIntervalPickerDialogShown: false,
            fromDate: new Date(),
            toDate: new Date(),
            tabItems: [
                {
                    title: '顔認識数',
                },
                {
                    title: '男女 x 年代別',
                },
                {
                    title: '新規 or リピーター',
                },
            ],
            currentTabIndex: 0,
            isOpen: false,
            kind: '',
        };
    },

    computed: {
        // envstg: function(){
        //     return process.env.NODE_ENV==="stg" ? true : false;
        // },
        largeBizCd: function () {
            return this.auth.user ? this.auth.user.customer_large_bizcd : '';
        },
        isWeatherShown: function () {
            return this.$route.params.mode === this.modeType.HOUR || this.$route.params.mode === this.modeType.DAY;
        },
        title: function () {
            return this.$store.getters.isUregiUser ? '売上 × 来客数':'来客数'
        }
    },

    created: async function () {
        this.leftSide = this.getInitialLeftSide(this.axisNum - 1);
        await this.fetchVisitorOption();
    },

    watch: {
        mode: function () {
            this.leftSide = this.getInitialLeftSide(this.axisNum - 1);
        },
        leftSide: async function () {
            if (this.mode === this.modeType.HOUR) {
                await this.fetchWeatherHour();
            }
        },
        $route: {
            handler() {
                if (this.$route.query.from != null && this.$route.query.to != null) {
                    this.leftSide = this.getInitialLeftSide();
                }
            },
            deep: true,
        },
    },

    methods: {
        fetchWeatherHour: async function () {
            const leftSideDate = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date, this.leftSide.hour);
            const rightSideDate = new Date(leftSideDate);
            rightSideDate.setHours(rightSideDate.getHours() + this.axisNum - 1);
            const from = this.getDateHourStr(leftSideDate);
            const to = this.getDateHourStr(rightSideDate);
            await this.$store.dispatch('fetchWeather', { mode: 'hour', from, to });
        },

        onDatePickerNext(e) {
            this.fromDate = e.fromDate;
            this.toDate = e.toDate;
            this.isDatePickerDialogShown = false;
            this.isIntervalPickerDialogShown = true;
        },

        onIntervalPickerDone(e) {
            const from = this.getDateStr(e.fromDate);
            const to = this.getDateStr(e.toDate);
            this.$router.push('/analytics/myshop/visitors-and-sales/' + e.mode + '?from=' + from + '&to=' + to);
            this.isIntervalPickerDialogShown = false;
        },

        onIntervalDialogBack() {
            this.isIntervalPickerDialogShown = false;
            this.isDatePickerDialogShown = true;
        },
        viewHint(kind) {
            console.log(kind);
            this.isHintDialogShown = true;
            this.kind = kind;
        },
    },

    mixins: [mixin, analyticsMixin, setparam, apicall, analyticssubmenu],
};
</script>

<style scoped lang="scss">
.content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: 100%;
    .fixed {
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .content-analytics {
        position: relative;
        padding-bottom: 40px;
        width: 100%;
        height: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        overflow-y: scroll;
        scroll-behavior: smooth;
    }
    .accordion {
        max-height: 132px;
        overflow: hidden;
        transition: all 300ms 0s ease;
        &.is-open {
            max-height: 9999px;
        }
    }
}
</style>
