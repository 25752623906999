<template>
    <div class="graph-wrapper">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'AnalyticsGraphWrapper',
};
</script>

<style scoped lang="scss">
.graph-wrapper {
    position: relative;
    // border-bottom: 1px solid rgb(168, 168, 168);
    margin-left: 24px;
    img {
        margin-bottom: 80px;
    }
    .control {
        @include flex_between;
        align-items: center;
        height: 50px;
        margin-left: 180px;
        margin-right: 50px;
        .gender {
            flex-grow: 1;
            input[type='checkbox'] {
                margin-right: 6px;
                width: 16px;
                height: 16px;
                position: relative;
                top: 2px;
            }
            label {
                font-size: 17px;
                font-weight: $font_weight_bold;
                color: $color_light_blue;
                cursor: pointer;
                user-select: none;
            }
        }
        .label {
            flex-grow: 0;
            font-size: 16px;
            font-weight: $font_weight_bold;
            line-height: 20px;
            margin-right: 20px;
            .color {
                display: inline-block;
                width: 20px;
                height: 20px;
                vertical-align: middle;
                margin-right: 6px;
                margin-left: 20px;
            }
            .line {
                display: inline-block;
                width: 20px;
                height: 2px;
                vertical-align: middle;
                margin-right: 2px;
                margin-left: 2px;
            }
        }
    }
    h4 {
        position: absolute;
        top: 13px;
        left: 6px;
        color: $color_black;
        font-weight: $font_weight_bold;
        font-size: 16px;
    }
    .top {
        position: absolute;
        top: 24px;
        left: 6px;
        color: $color_black;
        font-weight: $font_weight_bold;
        font-size: 16px;
    }
}
</style>
