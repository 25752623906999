<template>
  <div class="weather" :class="{ day: mode === modeType.DAY }">
    <p class="icon"><img v-if="weather" :src="weather ? '/images/icon_weather_' + weather.weather + '.svg' : ''"></p>
    <p v-if="mode === modeType.HOUR" class="temp">{{ weather ? weather.temperature + '℃' : '' }}</p>
    <p v-if="mode === modeType.DAY" class="temp"><span v-if="weather">{{ weather ? weather.max_temperature : '' }}℃/{{ weather ? weather.min_temperature : '' }}℃</span></p>
    <p v-if="mode === modeType.HOUR" class="humi">{{ weather ? weather.humidity + '%' : '' }}</p>
    <p class="rain">{{ weather ? weather.rainy + '%' : '' }}</p>
    <p v-if="mode === modeType.HOUR" class="wind">{{ weather ? weather.wind_direction : '' }}{{ weather ? weather.wind_flow + 'm/s' : '' }}</p>
  </div>
</template>

<script>
export default {
  name: 'TimelineWeatherItem',

  props: {
    weather: {
      required: true
    },
    mode: {
      type: String,
      default: 'hour'
    },
  },

  computed: {
    modeType: function() {
      return this.$store.state.analyticsModeType;
    },
  }
};
</script>

<style scoped lang="scss">
.weather {
  .icon {
    width: 80px;
    height: 40px;
    margin-top: 12px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .temp, .temp span, .rain, .wind, .humi {
    color: $color_light_gray;
    font-family: $number_font_family;
    padding-left: 23px;
    height: 40px;
    line-height: 40px;
  }
  .temp, .temp span, .humi, .rain {
    font-size: 20px;
  }
  .wind {
    font-size: 15px;
    white-space: nowrap;
  }
  .temp, .rain {
    background-color: rgb(243, 243, 243);
  }
}
</style>
