<template>
  <div>
    <div class="container">
      <div class="content-wrapper">
        <!-- <MypageMenu tag="shopinfo" /> -->
        <div class="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import MypageMenu from "@/components/menu/MypageMenu.vue";
import setparam from '@/mixin/setparam.js';
export default {
  name: "Auth",

  components: {
    // MypageMenu,
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    }
  },
  watch: {
    auth: {
      async handler() {
        if (this.auth.user != null) {
          if (this.$store.getters.lastLogin) {
            const currentUnixTime = Math.floor(Date.now() / 1000);
            if (
              currentUnixTime >
              this.$store.getters.lastLogin + 1 * 24 * 60 * 60
            ) {
              // console.log("expired.");
              this.$router.push({ path: "/mypage/login" });
            }
            // console.log("not expired.");
            this.$router.push({ path: "/mypage/shopinfo" });
          } else {
            if (this.$store.getters.passCodeExist) {
              this.$router.push({ path: "/mypage/login" });
            } else {
              this.$router.push({ path: "/mypage/password/set" });
            }
          }
        }
      },
      deep: true
    },
  },

  async created() {
    if (this.auth.user != null) {
      if (this.$store.getters.lastLogin) {
        const currentUnixTime = Math.floor(Date.now() / 1000);
        if (
          currentUnixTime >
          this.$store.getters.lastLogin + 1 * 24 * 60 * 60
        ) {
          console.log("expired.");
          this.$router.push({ path: "/mypage/login" });
        }
        console.log("not expired.");
        this.$router.push({ path: "/mypage/shopinfo" });
      } else {
        if (this.$store.getters.passCodeExist) {
          this.$router.push({ path: "/mypage/login" });
        } else {
          this.$router.push({ path: "/mypage/password/set" });
        }
      }
    }
  },
  mixins: [setparam]
  // computed: {
  //   unisCustomerCode() {
  //     return this.$store.getters.getUnisCustomerCode;
  //   },
  // },

  // mounted() {
  //   window.setParams = this.setParams;
  // },

  // async created() {
  //   if (this.$store.state.isDebug) {
  //     await this.setParams("123450001");
  //   }

  // },
  // methods: {
  //   async setParams(data) {
  //     console.log("setParams.");
  //     await this.$store.dispatch("fetchUser", { unisCustomerCode: data });

  //     if (this.$store.getters.lastLogin) {
  //       const currentUnixTime = Math.floor(Date.now() / 1000);
  //       if (
  //         currentUnixTime >
  //         this.$store.getters.lastLogin + 1 * 24 * 60 * 60
  //       ) {
  //         console.log("expired.");
  //         this.$router.push({ path: "/mypage/login" });
  //       }
  //       console.log("not expired.");
  //       this.$router.push({ path: "/mypage/shopinfo" });
  //     } else {
  //       if (this.$store.getters.passCodeExist) {
  //         this.$router.push({ path: "/mypage/login" });
  //       } else {
  //         this.$router.push({ path: "/mypage/password/set" });
  //       }
  //     }
  //   },
  // },
};
</script>

<style scoped lang="scss">
@include mypage-common;
</style>
