<template>
  <div class="weather-calendar">
    <div class="weather-calendar-daily-list" v-if="mode === modeType.HOUR || mode === modeType.THREE_HOURS">
      <WeatherCalendarDaily v-for="n in days" :key="n" :mode="mode" :date="getIndexOfDate(n - 1)" :displayNum="9" :initialStartHour="now" />
    </div>
    <WeatherCalendarMonthly v-if="mode === modeType.DAY" />
  </div>
</template>

<script>
import WeatherCalendarDaily from '@/components/WeatherCalendarDaily.vue';
import WeatherCalendarMonthly from '@/components/WeatherCalendarMonthly.vue';
import mixin from '@/mixin/index.js';

export default {
  name: 'WeatherCalendar',

  components: {
    WeatherCalendarDaily,
    WeatherCalendarMonthly,
  },

  props: {
    mode: {
      type: String,
      default: 'hour'
    },
    date: {
      type: String,
    },
    days: {
      type: Number,
    },
    now: {
      type: Number,
    }
  },

  computed: {
    modeType: function() {
      return this.$store.state.analyticsModeType;
    },
  },

  methods: {
    getIndexOfDate(index) {
      const date = this.getDate(this.date);
      const targetDate = this.getNDaysLater(date, index);
      return this.getDateStr(targetDate);
    }
  },

  mixins: [mixin]
}
</script>

<style scoped lang="scss">
</style>
