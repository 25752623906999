<template>
    <div class="dialog">
        <div class="dialog-base">
            <div class="dialog-header">
                <p class="title">{{ title }}</p>
                <p class="btn-close" @click.prevent="$emit('close')"></p>
            </div>
            <div class="dialog-content" v-html="contents"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HintDialog',

    props: {
        kind: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            title: '',
            contents: '',
        };
    },
    created: function () {
        switch (this.kind) {
            case 'faceDetect':
                this.title = '顔認識数';
                this.contents =
                    '<p>来客人数に対して未認識数（anonymous）を除いた人数、顔の特徴量が取得できた人数です。<br /><br />【ヒント】 <br />男女×年代別、新規orリピーターの数値は、顔認識数を母数にした集計となります。</p>';
                break;
            case 'genderRatio':
                this.title = '男性/女性の割合';
                this.contents =
                    '<p>来客人数に対して未認識数（anonymous）を除き、顔の特徴量が取得できた人数に対して、男性と女性の割合です。<br /><br />【ヒント】<br />「年代別」チェックボックスを選択すると年代別との掛け合わせでの割合をご確認頂けます。<br />更に期間選択を変更することで、曜日や指定期間中での傾向などもご確認頂けます。<br /> </p>';
                break;
            case 'ageRatio':
                this.title = '年代別の掛け合わせ表示';
                this.contents = ' <p>男性女性、新規orリピーターとの掛け合わせで、年代別の割合を表示します。</p>';
                break;
            case 'newRepeaterRatio':
                this.title = '新規/リピーターの割合';
                this.contents =
                    '<p>来客人数に対して未認識数（anonymous）を除き、顔の特徴量が取得できた人数に対して、新規のお客様とリピーターのお客様割合です。<br /><br />【ヒント】<br />「年代別」チェックボックスを選択すると年代別との掛け合わせでの割合をご確認頂けます。<br />更に期間選択を変更することで、曜日や指定期間中での傾向などもご確認頂けます。<br /></p>';
                break;
            case 'repeater':
                this.title = 'リピーター';
                this.contents =
                    '<p>AIベースユニットが保管している顔の特徴量から、同一人物と判別した人物を指します。マスクやつばの長い帽子など、顔の特徴量が少ないと、違う人物でも同一人物と判断してしまう場合が有ります。<br /><br />【ヒント】<br />リピーターの特徴量を保管する期間は、デフォルト設定30日間です。30日を超えたもの、またはデータが10,000人以上になると古いデータから削除されます。<br />設定は専用タブレットの「U Eyes RC」アプリの設定で調整が可能です。（30日～最大180日※30日単位）<br /></p>';
                break;
            case 'sales':
                this.title = 'Uレジ売上';
                this.contents =
                    ' <p>毎日16時以降に前日の売上が反映されます。<br>過去7日分のデータが更新・反映されます。それ以前の売上データをUレジ側で修正した場合、本グラフには反映されません。<br>例）12/1に更新・反映される売上データ<br>11/24~11/30までの売上データを更新・反映します<br>11/30に「11/24」と「11/1」の売上データをUレジ側で修正した場合、<br>・11/24の売上 → 12/1に最新データがグラフに反映されます<br>・11/1の売上 → 12/1含め、今後、修正した内容がグラフに反映されることはありません</p>';
                break;
        }
    },
};
</script>

<style scoped lang="scss">
.dialog {
    @include flex_center;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .dialog-base {
        background-color: $color_white;
        border-radius: 10px;
        box-shadow: $box_shadow;
        width: 1000px;
        .dialog-header {
            overflow: hidden;
            position: relative;
            height: 80px;
            border-bottom: 1px solid rgb(220, 220, 220);
            .title {
                text-align: center;
                width: 490px;
                margin-left: auto;
                margin-right: auto;
                color: $color_black;
                font-size: 20px;
                font-weight: $font_weight_bold;
                line-height: 1em;
                margin-top: 30px;
            }
            .btn-back {
                @include btn-back;
                position: absolute;
                top: 30px;
                left: 44px;
                margin-left: 0;
                margin-top: 0;
            }
            .btn-close {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 40px;
                height: 40px;
                background-image: url('/images/icon_close.svg');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                &:active {
                    opacity: 0.5;
                }
            }
        }
        .dialog-content {
            font-size: 18px;
            height: 200px;
            padding: 40px;
        }
        .dialog-footer {
            height: 80px;
            border-top: 1px solid rgb(220, 220, 220);
        }
    }
}
</style>
