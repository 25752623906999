export default {

    data() {
      return {
        // payView: '',
      };
    },
  
    computed: {
      auth() {
        return this.$store.state.auth;
      },
      payView: function () {
        return this.$store.state.paymentCheck;
      },
    },
  
    async created() {
      if (this.auth.user == null) {
        return;
      }
      await this.$store.dispatch('fetchPaymentCheck',{
        unisCustomerCode: this.unisCustomerCode,
      });
    },
  
    watch: {
      auth: {
        async handler() {
          if (this.auth.user != null) {
            await this.$store.dispatch('fetchPaymentCheck',{
                unisCustomerCode: this.unisCustomerCode,
              });
          }
        },
        deep: true
      }
    },
  }
  