var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AnalyticsGraphWrapper", [
    _c("h4", [
      _vm._v("新規 or リピーター"),
      _c("img", {
        staticClass: "info-img-label",
        attrs: { src: "/images/icon_info.png" },
        on: {
          click: function ($event) {
            return _vm.viewHint("ageRatio")
          },
        },
      }),
    ]),
    _c("div", { staticClass: "control" }, [
      _c("div", { staticClass: "gender" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.showAge,
                expression: "showAge",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.showAge)
                ? _vm._i(_vm.showAge, null) > -1
                : _vm.showAge,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.showAge,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.showAge = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.showAge = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.showAge = $$c
                }
              },
            },
          }),
          _vm._v("年代別を表示"),
        ]),
      ]),
      _c("div", { staticClass: "label" }, [
        _c("span", { staticClass: "color color-new" }),
        _vm._v("新規"),
      ]),
      _c("div", { staticClass: "label" }, [
        _c("span", { staticClass: "color color-repeater" }),
        _vm._v("リピーター"),
      ]),
      _c("img", {
        staticClass: "info-img-right",
        attrs: { src: "/images/icon_info.png" },
        on: {
          click: function ($event) {
            return _vm.viewHint("repeater")
          },
        },
      }),
    ]),
    _c(
      "p",
      { staticClass: "graph" },
      [
        _c(
          "span",
          {
            staticClass: "scale-label",
            style: { left: _vm.graphType === "Bar" ? "20px" : "70px" },
          },
          [_vm._v("人")]
        ),
        _vm.graphType === "Bar"
          ? _c("StackedBarChart", {
              attrs: { height: 300, chartData: _vm.chartData },
            })
          : _vm._e(),
        _vm.graphType === "Line"
          ? _c("LineChart", {
              attrs: {
                height: 300,
                chartData: _vm.chartData,
                options: _vm.lineGraphOptions,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "ul",
      { staticClass: "ratio-list first", class: { "show-age": _vm.showAge } },
      [
        _c("li", { staticClass: "title" }, [
          _c("ul", { staticClass: "title-list" }, [
            _c(
              "li",
              {
                staticClass: "total info",
                on: {
                  click: function ($event) {
                    return _vm.viewHint("newRepeaterRatio")
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "info-img",
                  attrs: { src: "/images/icon_info.png" },
                }),
                _vm._v("新規全体"),
              ]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("10代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("20代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("30代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("40代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("50代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("60代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("70代以上")]
            ),
          ]),
        ]),
        _vm._l(_vm.visitorsList, function (visitors, index) {
          return _c("li", { key: index }, [
            _c(
              "ul",
              { staticClass: "value-list" },
              [
                _c("li", { staticClass: "total" }, [
                  _c("span", { staticClass: "text total" }, [
                    _vm._v(
                      _vm._s((visitors ? visitors.newCustomer.ratio : 0) + "%")
                    ),
                  ]),
                ]),
                _vm._l(7, function (n) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showAge,
                          expression: "showAge",
                        },
                      ],
                      key: n,
                    },
                    [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            (visitors
                              ? visitors.newCustomer.ratioByAge[n - 1]
                              : 0) + "%"
                          )
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ])
        }),
      ],
      2
    ),
    _c(
      "ul",
      { staticClass: "ratio-list second", class: { "show-age": _vm.showAge } },
      [
        _c("li", { staticClass: "title" }, [
          _c("ul", { staticClass: "title-list" }, [
            _c(
              "li",
              {
                staticClass: "total info",
                on: {
                  click: function ($event) {
                    return _vm.viewHint("newRepeaterRatio")
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "info-img",
                  attrs: { src: "/images/icon_info.png" },
                }),
                _vm._v("ﾘﾋﾟｰﾀｰ全体"),
              ]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("10代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("20代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("30代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("40代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("50代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("60代")]
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAge,
                    expression: "showAge",
                  },
                ],
              },
              [_vm._v("70代以上")]
            ),
          ]),
        ]),
        _vm._l(_vm.visitorsList, function (visitors, index) {
          return _c("li", { key: index }, [
            _c(
              "ul",
              { staticClass: "value-list" },
              [
                _c("li", { staticClass: "total" }, [
                  _c("span", { staticClass: "text total" }, [
                    _vm._v(
                      _vm._s((visitors ? visitors.repeater.ratio : 0) + "%")
                    ),
                  ]),
                ]),
                _vm._l(7, function (n) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showAge,
                          expression: "showAge",
                        },
                      ],
                      key: n,
                    },
                    [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            (visitors
                              ? visitors.repeater.ratioByAge[n - 1]
                              : 0) + "%"
                          )
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }