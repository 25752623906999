var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", { attrs: { current: "mypage" } }),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "content-wrapper" }, [
          _c("div", { staticClass: "content mypage" }, [
            _c("h2", [_vm._v("4ケタの数字で、パスワードを設定してください")]),
            _c("dl", [
              _c("dt", [_vm._v("パスワード")]),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password",
                    },
                  ],
                  attrs: { type: "password" },
                  domProps: { value: _vm.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("dl", { class: { error: _vm.isError } }, [
              _c("dt", [_vm._v("パスワード確認")]),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.passwordConfirm,
                      expression: "passwordConfirm",
                    },
                  ],
                  attrs: { type: "password" },
                  domProps: { value: _vm.passwordConfirm },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.passwordConfirm = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm.isError
              ? _c("p", { staticClass: "message" }, [
                  _vm._v(" パスワードが一致しません。再度確認してください "),
                ])
              : _vm._e(),
            _vm.isPostError
              ? _c("p", { staticClass: "message" }, [
                  _vm._v(
                    " ログイン時のエラーです。もう一度ためしてください。 "
                  ),
                ])
              : _vm._e(),
            _c("p", { staticClass: "btn-gradient" }, [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onClicked.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("設定する")]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }