<template>
    <div>
        <Header current="analytics" />
        <div class="container">
            <div class="content-wrapper">
                <AnalyticsMenu tag="years" :largeBizCd="largeBizCd"  :visitorView="visitorView"/>
                <div class="content">
                    <div class="content-shop-analytics">
                        <ShopSearchHeader
                            :areas="areaCode"
                            :genres="bizCode"
                            :pref="pref"
                            :city="city"
                            :large="largeBizCd"
                            :middle="middleBizCd"
                            :small="smallBizCd"
                            @show-info="isInfoDialogShown = true"
                        />
                        <ShopAnalyticsAxis mode="MODE_COUNT" :shopCount="storeTotal()" :created="{ year: 2020, month: 10 }" />
                        <ShopAnalyticsGraph title="営業年数"
                            :labels="restaurantYears.labels ? restaurantYears.labels : []"
                            :data-collection="restaurantYears.data ? restaurantYears.data : []"
                        />
                        <InfoDialog v-if="isInfoDialogShown" @close="isInfoDialogShown = false" :kind="kind"/>
                        <p class="notice">
                            公開情報を基に当社が独自で行った分析結果になりますので参考値としてご利用ください。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import setparam from '@/mixin/setparam.js';
import shopapicall from '@/mixin/shopapicall.js';
import analyticssubmenu from '@/mixin/analyticssubmenu.js';
import Header from '@/components/Header.vue';
import AnalyticsMenu from '@/components/menu/AnalyticsMenu.vue';
import ShopSearchHeader from '@/components/analytics/ShopSearchHeader.vue';
import ShopAnalyticsAxis from '@/components/analytics/ShopAnalyticsAxis.vue';
import ShopAnalyticsGraph from '@/components/analytics/ShopAnalyticsGraph.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';

export default {
    name: 'ShopYears',

    computed: {
        restaurantYears: function () {
            return this.$store.getters.restaurantYears;
        },
    },

    components: {
        Header,
        AnalyticsMenu,
        ShopSearchHeader,
        ShopAnalyticsAxis,
        ShopAnalyticsGraph,
        InfoDialog
    },

    data: () => {
        return {
            category: '2',
            graph: '10',
            storeTotal: function () {
                if (this.restaurantYears.data == null) {
                    return 0;
                }
                return this.restaurantYears.data.reduce((acc, val) => acc + Number(val), 0);
            },
            isInfoDialogShown:false,
            kind:"years"
        };
    },

    mixins: [setparam, shopapicall,analyticssubmenu]
};
</script>

<style scoped lang="scss">
.content-shop-analytics {
    margin-left: 24px;
    margin-right: 24px;
    .notice {
        @include shop-notice;
    }
}
</style>
