var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog" }, [
    _c("div", { staticClass: "dialog-base" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("p", { staticClass: "btn-back" }, [
          _c(
            "a",
            {
              attrs: { href: "/" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("back")
                },
              },
            },
            [_vm._v("戻る")]
          ),
        ]),
        _c("p", {
          staticClass: "btn-close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        }),
      ]),
      _c("div", { staticClass: "dialog-content" }, [_vm._t("content")], 2),
      _c("div", { staticClass: "dialog-footer" }, [_vm._t("footer")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }