var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "analytics-tab" }, [
    _c(
      "ul",
      _vm._l(_vm.tabItems, function (tabItem, index) {
        return _c(
          "li",
          {
            key: tabItem.title,
            class: { selected: index === _vm.currentIndex },
            on: {
              click: function ($event) {
                return _vm.$emit("select", index)
              },
            },
          },
          [_vm._v(" " + _vm._s(tabItem.title) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }