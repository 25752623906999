var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AnalyticsGraphWrapper", [
    _vm.showSales
      ? _c(
          "h4",
          {
            staticClass: "info",
            on: {
              click: function ($event) {
                return _vm.viewHint("sales")
              },
            },
          },
          [
            _c("img", {
              staticClass: "info-img",
              attrs: { src: "/images/icon_info.png" },
            }),
            _vm._v("売上"),
          ]
        )
      : _vm._e(),
    _vm.showSales ? _c("div", { staticClass: "control sales" }) : _vm._e(),
    _vm.showSales
      ? _c(
          "p",
          [
            _c("LineChart", {
              attrs: {
                height: 140,
                chartData: _vm.chartData,
                options: _vm.options,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.visitorOption
      ? _c("div", { staticClass: "list-vistors" }, [
          _c("h4", [_vm._v("来客人数")]),
          _c(
            "ul",
            _vm._l(_vm.num, function (n) {
              return _c("li", { key: n }, [
                _vm._v(
                  _vm._s(
                    (_vm.visitorsValueList[n - 1]
                      ? Number(_vm.visitorsValueList[n - 1]).toLocaleString()
                      : 0) + "人"
                  )
                ),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "list-vistors" }, [
      _c(
        "h4",
        {
          staticClass: "info",
          on: {
            click: function ($event) {
              return _vm.viewHint("faceDetect")
            },
          },
        },
        [
          _c("img", {
            staticClass: "info-img",
            attrs: { src: "/images/icon_info.png" },
          }),
          _vm._v("顔認識数"),
        ]
      ),
      _c(
        "ul",
        _vm._l(_vm.num, function (n) {
          return _c("li", { key: n }, [
            _vm._v(
              " " +
                _vm._s(
                  (_vm.visitorsKnownValueList[n - 1]
                    ? Number(_vm.visitorsKnownValueList[n - 1]).toLocaleString()
                    : 0) + "人"
                ) +
                " "
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }