export default {
  data: function() {
    return {
      leftSide: {}
    };
  },

  computed: {
    modeType: function() {
      return this.$store.state.analyticsModeType;
    },
    mode: function() {
      return this.$route.params.mode;
    },
    today: function() {
      return this.getDateObj(new Date());
    },
    axisNum: function() {
      switch (this.mode) {
        case this.modeType.HOUR:
          return 9;
        case this.modeType.DAY:
          return 9;
        case this.modeType.WEEK:
          return 6;
        case this.modeType.MONTH:
          return 9;
        case this.modeType.THREE:
          return 6;
        default:
          return 0;
      }
    },
    startDate: function() {
      const fromDate = this.$route.query.from;
      if (fromDate == null) {
        return null
      } else {
        const startDate = this.getDate(fromDate);
        return {
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
          date: startDate.getDate(),
          hour: startDate.getHours(),
        }
      }
    },
    endDate: function() {
      const date = this.getDate(this.today);
      const toDate = this.$route.query.to;
      let endDate;
      if (toDate == null) {
        switch (this.mode) {
          case this.modeType.HOUR:
            endDate = date;
            break;
          case this.modeType.DAY:
            endDate = date;
            break;
          case this.modeType.WEEK:
            endDate = this.getFirstDateOfWeek(date);
            break;
          case this.modeType.MONTH:
            endDate = date;
            break;
          case this.modeType.THREE:
            endDate = this.getFirstMonthOfThree(date);
            break;
          default:
            break;
        }
      } else {
        endDate = this.getDate(toDate);
      }
      return {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        date: endDate.getDate(),
        hour: endDate.getHours(),
      }
    }
  },

  methods: {
    getInitialLeftSide(offset) {
      const date = this.getDate(this.today);
      const fromDate = this.$route.query.from;
      let leftSideDate;
      let firstDateOfWeek;
      let firstMonthOfThree;

      if (fromDate == null) {
        switch (this.mode) {
          case this.modeType.HOUR:
            date.setHours(date.getHours() - offset);
            leftSideDate = date;
            break;
          case this.modeType.DAY:
            date.setDate(date.getDate() - offset);
            leftSideDate = date;
            break;
          case this.modeType.WEEK:
            firstDateOfWeek = this.getFirstDateOfWeek(date);
            firstDateOfWeek.setDate(firstDateOfWeek.getDate() - offset * 7);
            leftSideDate = firstDateOfWeek;
            break;
          case this.modeType.MONTH:
            date.setMonth(date.getMonth() - offset);
            leftSideDate = date;
            break;
          case this.modeType.THREE:
            firstMonthOfThree = this.getFirstMonthOfThree(date);
            firstMonthOfThree.setMonth(firstMonthOfThree.getMonth() - offset * 3);
            leftSideDate = firstMonthOfThree;
            break;
          default:
            break;
        }
      } else {
        leftSideDate = this.getDate(fromDate);
      }
      return {
        year: leftSideDate.getFullYear(),
        month: leftSideDate.getMonth() + 1,
        date: leftSideDate.getDate(),
        hour: leftSideDate.getHours(),
      }
    },
    onAxisUpdated: function(e) {
      this.leftSide = e.date;
    }
  }
};
