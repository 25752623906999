<template>
  <div class="weather-calendar-list-item" :class="[weather ? weather.type : '', dayClass]">
    <template v-if="isHourMode">
      <p class="hour">{{ hour }}時</p>
    </template>
    <template v-else>
      <p class="date">{{ date }}日</p>
      <p class="day">{{ day }}曜日</p>
    </template>
    <p class="icon"><img :src="weather ? '/images/icon_weather_' + weather.weather + '.svg' : ''"></p>
    <p v-if="isHourMode" class="temp">{{ weather ? weather.temperature + '℃' : '' }}</p>
    <p v-if="isDayMode" class="temp"><span v-if="weather">{{ weather ? weather.max_temperature : '' }}℃/{{ weather ? weather.min_temperature : '' }}℃</span></p>
    <p v-if="isHourMode" class="humi">{{ weather ? weather.humidity + '%' : '' }}</p>
    <p class="rain">{{ weather ? weather.rainy + '%' : '' }}</p>
    <p v-if="isHourMode" class="wind">{{ weather ? weather.wind_direction : '' }}{{ weather ? weather.wind_flow + 'm/s' : '' }}</p>
  </div>
</template>

<script>
export default {
  name: 'WeatherCalendarListItem',

  props: {
    hour: {
      type: Number,
      default: 0,
    },
    date: {
      type: Number,
      default: 0,
    },
    day: {
      type: String,
      default: '',
    },
    weather: {
      required: true
    },
    mode: {
      type: String,
      default: 'hour'
    },
  },

  computed: {
    modeType: function() {
      return this.$store.state.analyticsModeType;
    },
    dayClass: function() {
      if (this.mode === this.modeType.DAY) {
        if (this.day === '日') {
          return 'sun';
        } else if (this.day === '土') {
          return 'sat'
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    isHourMode: function() {
      return this.mode === this.modeType.HOUR || this.mode === this.modeType.THREE_HOURS;
    },
    isDayMode: function() {
      return this.mode === this.modeType.DAY;
    },
  },
}
</script>

<style scoped lang="scss">
.weather-calendar-list-item {
  padding: 6px;
  border-radius: 10px;
  overflow: hidden;
  border: 4px solid transparent;
  &.current {
    border: 4px solid $color_light_blue;
  }
  &.past {
    opacity: 0.3;
  }
  &.sun {
    .date, .day {
      color: rgb(201, 47, 47);
    }
  }
  &.sat {
    .date, .day {
      color: rgb(0, 155, 255);
    }
  }
  p {
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: $color_black;
    font-size: 20px;
    font-weight: $font_weight_bold;
    &.hour, &.date {
      font-size: 24px;
    }
    &.day {
      position: relative;
      top: -4px;
      margin-bottom: 6px;
    }
    span {
      color: $color_black;
      font-size: 20px;
      font-weight: $font_weight_bold;
      line-height: 40px;
    }
  }
}
</style>
