var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", { attrs: { current: "analytics" } }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "content-wrapper" },
            [
              _c("AnalyticsMenu", {
                attrs: {
                  tag: "visitors-average",
                  largeBizCd: _vm.largeBizCd,
                  visitorView: _vm.visitorView,
                },
              }),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("AnalyticsHeader", {
                    attrs: {
                      isAverage: true,
                      title: "来客数の期間平均",
                      mode: _vm.$route.params.mode,
                    },
                    on: {
                      "show-calendar": function ($event) {
                        _vm.isDatePickerDialogShown = true
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "content-analytics" },
                    [
                      _c("AnalyticsAxis", {
                        attrs: {
                          mode: _vm.$route.params.mode,
                          leftSide: _vm.leftSide,
                          num: _vm.axisNum,
                          start: _vm.startDate,
                          end: _vm.endDate,
                        },
                        on: { update: _vm.onAxisUpdated },
                      }),
                      _c("AnalyticsSalesGraph", {
                        attrs: {
                          mode: _vm.$route.params.mode,
                          leftSide: _vm.leftSide,
                          num: _vm.axisNum,
                        },
                      }),
                      _c("AnalyticsGenderGraph", {
                        attrs: {
                          mode: _vm.$route.params.mode,
                          leftSide: _vm.leftSide,
                          num: _vm.axisNum,
                          graphType: "Line",
                        },
                      }),
                      _c("AnalyticsRepeaterGraph", {
                        attrs: {
                          mode: _vm.$route.params.mode,
                          leftSide: _vm.leftSide,
                          num: _vm.axisNum,
                          graphType: "Line",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isDatePickerDialogShown
            ? _c("DatePickerDialog", {
                on: {
                  cancel: function ($event) {
                    _vm.isDatePickerDialogShown = false
                  },
                  next: _vm.onDatePickerNext,
                },
              })
            : _vm._e(),
          _vm.isIntervalPickerDialogShown
            ? _c("IntervalPickerDialog", {
                attrs: {
                  hideDayMode: true,
                  fromDate: _vm.fromDate,
                  toDate: _vm.toDate,
                },
                on: {
                  cancel: function ($event) {
                    _vm.isIntervalPickerDialogShown = false
                  },
                  done: _vm.onIntervalPickerDone,
                  back: _vm.onIntervalDialogBack,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }