var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "weather-calendar-header" },
    [
      _c("p", { staticClass: "location" }, [
        _vm._v(
          " " +
            _vm._s(_vm.userData ? _vm.userData.customer_address1 : "") +
            _vm._s(_vm.userData ? _vm.userData.customer_address2 : "")
        ),
        _c("br"),
        _vm._v(_vm._s(_vm.weatherTime) + " "),
      ]),
      _c("h2", [_vm._v("天気予報")]),
      _c("SelectBox", {
        attrs: { title: "", options: _vm.selectBoxOptions, value: _vm.mode },
        on: { update: _vm.onUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }