<template>
    <div class="header-event-calendar">
        <h2>
            <Date>
                <span class="num">{{ $route.params.year }}</span
                >年<span class="num">{{ $route.params.month }}</span
                >月
                <span class="prev sp"
                    ><router-link :to="'/calendar/' + prevMonth.year + '/' + zeroPadding(prevMonth.month)" replace
                        ><img src="/images/icon_prev.svg" /></router-link
                ></span>
                <span class="next sp"
                    ><router-link :to="'/calendar/' + nextMonth.year + '/' + zeroPadding(nextMonth.month)" replace
                        ><img src="/images/icon_next.svg" /></router-link
                ></span>
            </Date>
        </h2>
        <p class="location sp">{{ userData ? userData.customer_address1 : '' }}{{ userData ? userData.customer_address2 : '' }}</p>
        <!-- <p class="title-sales-visitors">前年度来客数</p>
        <p class="title-sales-visitors past">今年来客数</p> -->
    </div>
</template>

<script>
import Date from '@/components/Date.vue';
import mixin from '@/mixin/index.js';

export default {
    name: 'EventCalendarHeader',

    components: {
        Date,
    },

    computed: {
        userData: function () {
            return this.$store.getters.getUserData;
        },

        prevMonth: function () {
            let year = parseInt(this.$route.params.year);
            let month = parseInt(this.$route.params.month) - 1;
            if (month < 1) {
                year--;
                month = 12;
            }
            return { year, month };
        },

        nextMonth: function () {
            let year = parseInt(this.$route.params.year);
            let month = parseInt(this.$route.params.month) + 1;
            if (month > 12) {
                year++;
                month = 1;
            }
            return { year, month };
        },
    },

    mixins: [mixin],
};
</script>

<style scoped lang="scss">
.header-event-calendar {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    padding-top: 24px;
    margin-left: 80px;
    margin-bottom: 10px;
    h2 {
        flex-grow: 1;
        margin-left: 10px;
    }
    .title-sales-visitors,
    .title-visitors {
        flex-grow: 0;
        font-size: 17px;
        font-weight: $font_weight_bold;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 4px;
        white-space: nowrap;
        &.past {
            color: $color_blue;
        }
    }
    .title-sales-visitors {
        width: 150px;
    }
    .title-visitors {
        width: 70px;
    }
}

@include sp-screen {
    .header-event-calendar {
        display: block;
        margin-left: 0;
        padding-top: 15px;
        h2 {
            text-align: center;
            .date {
                @include calendar-prev-next;
            }
        }
        .location {
            color: $color_black;
            font-size: 18px;
            font-weight: $font_weight_bold;
            margin-bottom: 4px;
            text-align: center;
        }
        .title-sales-visitors,
        .title-visitors {
            display: none;
        }
    }
}
</style>
