<template>
  <div>
    <Header current="mypage"/>
    <div class="container">
      <div class="content-wrapper">
        <MypageMenu tag="shopinfo"/>
        <div class="content mypage">
          <h2>登録変更を申請中です。</h2>
          <p class="message">
            USENで登録変更を手続きしています。<br>
            もうしばらくおまちください。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
// import MypageMenu from '@/components/menu/MypageMenu.vue';

export default {
  name: 'Loading',

  components: {
    Header,
    // MypageMenu,
  },
};
</script>

<style scoped lang="scss">
@include mypage-common;
</style>
