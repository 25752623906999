<template>
    <div>
        <Header current="analytics" />
        <div class="container">
            <div class="content-wrapper">
                <AnalyticsMenu tag="all" :largeBizCd="largeBizCd" :visitorView="visitorView" />
                <div class="content">
                    <div class="content-shop-analytics">
                        <ShopSearchHeader
                            :areas="areaCode"
                            :genres="bizCode"
                            :pref="pref"
                            :city="city"
                            :large="largeBizCd"
                            :middle="middleBizCd"
                            :small="smallBizCd"
                            :hideSmall="true"
                            @show-info="isInfoDialogShown = true"
                        />
                        <ShopAnalyticsAxis mode="MODE_AXIS" :axisNum="axisNum" type="TARGET" />
                        <ShopAnalyticsGraph title="対象店舗数" :labels="labels" :data-collection="dataCollection" />
                        <InfoDialog v-if="isInfoDialogShown" @close="isInfoDialogShown = false" :kind="kind"/>
                        <p class="notice">
                            2021年6月以降の集計データを表示しています。<br />
                            当社の独自調査および公開情報を基に行った分析結果となりますので参考値としてご利用ください。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import mixin from '@/mixin/index.js';
import setparam from '@/mixin/setparam.js';
import shopapicall from '@/mixin/shopapicall.js';
import analyticssubmenu from '@/mixin/analyticssubmenu.js';
import Header from '@/components/Header.vue';
import AnalyticsMenu from '@/components/menu/AnalyticsMenu.vue';
import ShopSearchHeader from '@/components/analytics/ShopSearchHeader.vue';
import ShopAnalyticsAxis from '@/components/analytics/ShopAnalyticsAxis.vue';
import ShopAnalyticsGraph from '@/components/analytics/ShopAnalyticsGraph.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
export default {
    name: 'ShopAll',

    components: {
        Header,
        AnalyticsMenu,
        ShopSearchHeader,
        ShopAnalyticsAxis,
        ShopAnalyticsGraph,
        InfoDialog
    },

    data() {
        return {
            axisNum: 9,
            category: '1',
            graph: '1',
            isInfoDialogShown:false,
            kind:"all"
        };
    },

    computed: {
        labels() {
            return new Array(this.axisNum).fill('');
        },
        dataCollection() {
            const today = new Date();
            const leftSide = new Date(today.getFullYear(), today.getMonth(), 1);
            //過去データが蓄積されるまでの対応
            if (leftSide.getFullYear() === 2021) {
                leftSide.setMonth(5);
            } else {
                leftSide.setMonth(leftSide.getMonth() - (this.axisNum - 1));
            }
            const data = [];
            for (let i = 0; i < this.axisNum; i++) {
                const date = new Date(leftSide);
                date.setMonth(date.getMonth() + i);
                const dateStr = this.getMonthStr(date);
                const count = this.$store.getters.getStoreCountAll(dateStr);
                data.push(count);
            }
            return data;
        },
    },

    mixins: [mixin, setparam, shopapicall, analyticssubmenu],
};
</script>

<style scoped lang="scss">
.content-shop-analytics {
    margin-left: 24px;
    margin-right: 24px;
    .notice {
        @include shop-notice;
    }
}
</style>
