import Vue from 'vue';
import VueRouter from 'vue-router';
import Top from '../views/Top.vue';
import Timeline from '../views/Timeline.vue';
import EventCalendar from '../views/EventCalendar.vue';
import VisitorsAndSales from '../views/analytics/VisitorsAndSales.vue';
import VisitorsAverage from '../views/analytics/VisitorsAverage.vue';
import VisitorsPrediction from '../views/analytics/VisitorsPrediction.vue';
import ShopAll from '../views/analytics/ShopAll.vue';
import ShopNew from '../views/analytics/ShopNew.vue';
import ShopSeats from '../views/analytics/ShopSeats.vue';
import ShopPriceLunch from '../views/analytics/ShopPriceLunch.vue';
import ShopPriceDinner from '../views/analytics/ShopPriceDinner.vue';
import ShopTakeout from '../views/analytics/ShopTakeout.vue';
import ShopHoliday from '../views/analytics/ShopHoliday.vue';
import ShopOpening from '../views/analytics/ShopOpening.vue';
import ShopClosing from '../views/analytics/ShopClosing.vue';
import ShopCard from '../views/analytics/ShopCard.vue';
import ShopPayment from '../views/analytics/ShopPayment.vue';
import ShopYears from '../views/analytics/ShopYears.vue';
import ShopParking from '../views/analytics/ShopParking.vue';
import ShopPrivate from '../views/analytics/ShopPrivate.vue';
import ShopSmoking from '../views/analytics/ShopSmoking.vue';
import ShopCdvInfo from '../views/analytics/ShopCdvInfo.vue';
import ShopBeautySeats from '../views/analytics/ShopBeautySeats.vue';
import ShopPriceCut from '../views/analytics/ShopPriceCut.vue';
import ShopBeautyHoliday from '../views/analytics/ShopBeautyHoliday.vue';
import ShopBeautyOpening from '../views/analytics/ShopBeautyOpening.vue';
import ShopBeautyClosing from '../views/analytics/ShopBeautyClosing.vue';
import ShopBeautyCard from '../views/analytics/ShopBeautyCard.vue';
import ShopBeautyPayment from '../views/analytics/ShopBeautyPayment.vue';
import ShopBeautyObsession from '../views/analytics/ShopBeautyObsession.vue';
import Login from '../views/mypage/Login.vue';
import SetPassword from '../views/mypage/SetPassword.vue';
import ResetPassword from '../views/mypage/ResetPassword.vue';
import ConfirmUser from '../views/mypage/ConfirmUser.vue';
import Auth from '../views/mypage/Auth.vue';
import Loading from '../views/mypage/Loading.vue';
import ShopInfo from '../views/mypage/ShopInfo.vue';
import MyBusiness from '../views/mypage/MyBusiness.vue';
import UPayPayment from '../views/mypage/UPayPayment.vue';
import UPayPaymentDetail from '../views/mypage/UPayPaymentDetail.vue';
import UPayTransition from '../views/mypage/UPayTransition.vue';
import ErrorMessage from '../views/ErrorMessage.vue';
import AnalyticsHandler from '../views/analytics/AnalyticsHandler.vue';
import AnalyticsPromo from '../views/analytics/AnalyticsPromo.vue';

import store from '../store';

Vue.use(VueRouter);

const zeroPadding = (n) => {
    return ('00' + n).slice(-2);
};

const routes = [
    {
        path: '/',
        name: 'Top',
        component: Top,
    },
    {
        path: '/timeline',
        redirect: () => {
            const today = new Date();
            return '/timeline/' + today.getFullYear() + '/' + zeroPadding(today.getMonth() + 1) + '/' + zeroPadding(today.getDate());
        },
    },
    {
        path: '/timeline/:year/:month/:date',
        name: 'Timeline',
        component: Timeline,
    },
    {
        path: '/calendar',
        redirect: () => {
            const today = new Date();
            return '/calendar/' + today.getFullYear() + '/' + zeroPadding(today.getMonth() + 1);
        },
    },
    {
        path: '/calendar/:year/:month',
        name: 'EventCalendar',
        component: EventCalendar,
    },
    {
        path: '/analytics',
        name: 'AnalyticsHandler',
        component: AnalyticsHandler,
        // beforeEnter: async (to, from, next) => {
        //     await store.dispatch('fetchVisitorsDataExists', {
        //         unisCustomerCode: store.state.auth.unisCustomerCode,
        //     });
        //     if(store.state.visitorsExists){
        //         return next({ path: '/analytics/myshop/visitors-and-sales/day' });
        //     }else{
        //         return next({ path: '/analytics/shops/all' });
        //     }
        // },
    },
    {
        path: '/analytics/promo',
        name: 'AnalyticsPromo',
        component: AnalyticsPromo,
        // beforeEnter() {
        //     window.location = 'https://iot.usen.com/u-eyes/';
        // },
    },
    {
        path: '/analytics/myshop/visitors-and-sales/:mode',
        name: 'VisitorsAndSales',
        component: VisitorsAndSales,
    },
    {
        path: '/analytics/myshop/visitors-average/:mode',
        name: 'VisitorsAverage',
        component: VisitorsAverage,
    },
    {
        path: '/analytics/myshop/visitors-prediction/:mode',
        name: 'VisitorsPrediction',
        component: VisitorsPrediction,
    },
    {
        path: '/analytics/shops/all',
        name: 'ShopAll',
        component: ShopAll,
    },
    {
        path: '/analytics/shops/new',
        name: 'ShopNew',
        component: ShopNew,
    },
    {
        path: '/analytics/shops/restaurant/seats',
        name: 'ShopSeats',
        component: ShopSeats,
    },
    {
        path: '/analytics/shops/restaurant/price/lunch',
        name: 'ShopPriceLunch',
        component: ShopPriceLunch,
    },
    {
        path: '/analytics/shops/restaurant/price/dinner',
        name: 'ShopPriceDinner',
        component: ShopPriceDinner,
    },
    {
        path: '/analytics/shops/restaurant/takeout',
        name: 'ShopTakeout',
        component: ShopTakeout,
    },
    {
        path: '/analytics/shops/restaurant/holiday',
        name: 'ShopHoliday',
        component: ShopHoliday,
    },
    {
        path: '/analytics/shops/restaurant/hours/opening',
        name: 'ShopOpening',
        component: ShopOpening,
    },
    {
        path: '/analytics/shops/restaurant/hours/closing',
        name: 'ShopClosing',
        component: ShopClosing,
    },
    {
        path: '/analytics/shops/restaurant/card',
        name: 'ShopCard',
        component: ShopCard,
    },
    {
        path: '/analytics/shops/restaurant/payment',
        name: 'ShopPayment',
        component: ShopPayment,
    },
    {
        path: '/analytics/shops/restaurant/years',
        name: 'ShopYears',
        component: ShopYears,
    },
    {
        path: '/analytics/shops/restaurant/parking',
        name: 'ShopParking',
        component: ShopParking,
    },
    {
        path: '/analytics/shops/restaurant/private',
        name: 'ShopPrivate',
        component: ShopPrivate,
    },
    {
        path: '/analytics/shops/restaurant/smoking',
        name: 'ShopSmoking',
        component: ShopSmoking,
    },
    {
        path: '/analytics/shops/restaurant/info',
        name: 'ShopCdvInfo',
        component: ShopCdvInfo,
    },
    {
        path: '/analytics/shops/beauty/seats',
        name: 'ShopBeautySeats',
        component: ShopBeautySeats,
    },
    {
        path: '/analytics/shops/beauty/price/cut',
        name: 'ShopPriceCut',
        component: ShopPriceCut,
    },
    {
        path: '/analytics/shops/beauty/holiday',
        name: 'ShopBeautyHoliday',
        component: ShopBeautyHoliday,
    },
    {
        path: '/analytics/shops/beauty/hours/opening',
        name: 'ShopBeautyOpening',
        component: ShopBeautyOpening,
    },
    {
        path: '/analytics/shops/beauty/hours/closing',
        name: 'ShopBeautyClosing',
        component: ShopBeautyClosing,
    },
    {
        path: '/analytics/shops/beauty/card',
        name: 'ShopBeautyCard',
        component: ShopBeautyCard,
    },
    {
        path: '/analytics/shops/beauty/payment',
        name: 'ShopBeautyPayment',
        component: ShopBeautyPayment,
    },
    {
        path: '/analytics/shops/beauty/obsession',
        name: 'ShopBeautyObsession',
        component: ShopBeautyObsession,
    },
    {
        path: '/mypage',
        redirect: '/mypage/auth',
    },
    {
        path: '/mypage/auth',
        name: 'Auth',
        component: Auth,
    },
    {
        path: '/mypage/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/mypage/password/set',
        name: 'SetPassword',
        component: SetPassword,
    },
    {
        path: '/mypage/password/reset',
        name: 'ResetPassword',
        component: ResetPassword,
    },
    {
        path: '/mypage/user/confirm',
        name: 'ConfirmUser',
        component: ConfirmUser,
    },
    {
        path: '/mypage/loading',
        name: 'Loading',
        component: Loading,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/mypage/shopinfo',
        name: 'ShopInfo',
        component: ShopInfo,
        // meta: {
        //     requiresAuth: true,
        // },
    },
    {
        path: '/mypage/mybusiness',
        name: 'MyBusiness',
        component: MyBusiness,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/mypage/upay/payment',
        name: 'UPayPayment',
        component: UPayPayment,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/mypage/upay/payment/:type/:date',
        name: 'UPayPaymentDetail',
        component: UPayPaymentDetail,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/mypage/upay/transition',
        name: 'UPayTransition',
        component: UPayTransition,
        meta: {
            requiresAuth: true,
        },
    },
    // {
    //     path: '/mypage/upay/admin',
    //     name: 'UPayAdmin',
    //     beforeEnter() {
    //         window.location ="https://mgmt.netmove.co.jp/management/login"
    //       }
    // },
    {
        path: '/error',
        name: 'ErrorMessage',
        component: ErrorMessage,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeResolve(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            // console.log('[OK] Router beforeResolve isAuthenticated');
        } else {
            // console.log('[NG] Router beforeResolve isAuthenticated');
            await store.dispatch('clearLogin');
            return next({ path: '/mypage/login' });
        }
    }
    return next();
});

export default router;
