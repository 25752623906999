<template>
    <AnalyticsGraphWrapper class="graph-wrapper">
        <h4>{{ title }}</h4>
        <p id="wrapper">
            <bar-chart :height="120" :chart-data="chartData" :options="options"></bar-chart>
            <!-- <img :src="graphImage"> -->
        </p>
    </AnalyticsGraphWrapper>
</template>

<script>
import AnalyticsGraphWrapper from '@/components/analytics/AnalyticsGraphWrapper.vue';
import BarChart from '@/components/analytics/BarChart.vue';

export default {
    name: 'ShopAnalyticsGraph',

    components: {
        AnalyticsGraphWrapper,
        BarChart,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        labels: {
            type: Array,
            required: true,
        },
        dataCollection: {
            type: Array,
            required: true,
        },
    },

    computed: {
        chartData: function () {
            return {
                labels: this.labels,
                datasets: [
                    {
                        label: '',
                        data: this.dataCollection,
                        backgroundColor: '#5F6E9B',
                        borderWidth: 1,
                    },
                ],
            };
        },
    },

    data: () => {
        return {
            options: {
                responsive: true,
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            scaleLabel: {
                                display: false,
                                labelString: 'Month',
                            },
                            barPercentage: 0.45,
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                // stepSize: 10,
                                userCallback: function (label) {
                                    if (Math.floor(label) === label) {
                                        return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    }
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem) {
                            return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        },
                    },
                    titleFontSize: 17,
                    titleSpacing: 10,
                    titleMarginBottom: 10,
                    bodyFontSize: 17,
                    bodySpacing: 10,
                    footerFontSize: 17,
                    footerSpacing: 10,
                    footerMarginTop: 10,
                    xPadding: 10,
                    yPadding: 10,
                    caretPadding: 12,
                },
            },
        };
    },
};
</script>

<style scoped lang="scss">
.graph-wrapper {
    position: relative;
    margin-left: 0;
    h4 {
        position: relative;
        top: 0;
        left: 0;
        margin-top: 13px;
        margin-left: 6px;
        color: $color_black;
        font-weight: $font_weight_bold;
        font-size: 16px;
        margin-bottom: 30px;
    }
    p {
        margin-bottom: 30px;
    }
}
</style>
