<template>
  <div class="analytics-axis" :class="{ prediction: isPrediction }">
    <div v-if="isPrediction" class="bar-box">
      <div class="past" :style="pastStyle">実績値と予測の比較</div>
      <div class="future" :style="futureStyle">予測</div>
    </div>
    <ul>
      <li v-for="n in num"  :key="n" :class="currentClass(n - 1)">
        <h3 v-if="isTitleShown(n - 1)">
          <span class="date"><span class="num">{{ getDateTitleStr(n - 1) }}</span>{{ getDayTitleStr(n - 1) }}</span>
        </h3>
        <p :class="getDayClass(n - 1)"><span class="num">{{ getDateStr(n - 1) }}</span>{{ getDayStr(n - 1) }}</p>
      </li>
    </ul>
    <div class="btn-prev" :class="{ disabled: !isPrevButtonEnable }"><a href="" @click.prevent="onPrevButtonClicked"></a></div>
    <div class="btn-next" :class="{ disabled: !isNextButtonEnable }"><a href="" @click.prevent="onNextButtonClicked"></a></div>
  </div>
</template>

<script>
import mixin from '@/mixin/index.js';

export default {
  name: 'AnalyticsAxis',

  props: {
    isPrediction: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      required: true
    },
    start: {
      default: null
    },
    end: {
      default: null
    },
    leftSide: {
      type: Object,
      required: true
    },
    num: {
      type: Number,
      required: true
    }
  },

  computed: {
    modeType: function() {
      return this.$store.state.analyticsModeType;
    },
    today: function() {
      return this.getDateObj(new Date());
    },
    pastStyle: function() {
      let todayDate = this.getDate(this.today);
      let distance = 0;
      let unitWidth = 100;
      switch (this.mode) {
        case this.modeType.WEEK:
          todayDate = this.getFirstDateOfWeek(todayDate);
          unitWidth = 150;
          break;
        case this.modeType.THREE:
          todayDate = this.getFirstMonthOfThree(todayDate);
          unitWidth = 150;
          break;
        default:
          break;
      }
      for (let index = 0; index < this.num + 1; index++) {
        const date = this.getDateOfIndex(index);
        if (date.getTime() < todayDate.getTime()) {
          distance++;
        } else {
          break;
        }
      }
      const width = 'calc(' + unitWidth + 'px * ' + distance + ')';
      return { width };
    },
    futureStyle: function() {
      let todayDate = this.getDate(this.today);
      let distance = 0;
      let unitWidth = 100;
      switch (this.mode) {
        case this.modeType.WEEK:
          todayDate = this.getFirstDateOfWeek(todayDate);
          unitWidth = 150;
          break;
        case this.modeType.THREE:
          todayDate = this.getFirstMonthOfThree(todayDate);
          unitWidth = 150;
          break;
        default:
          break;
      }
      for (let index = this.num - 1; index >= -1; index--) {
        const date = this.getDateOfIndex(index);
        if (date.getTime() >= todayDate.getTime()) {
          distance++;
        } else {
          break;
        }
      }
      let width;
      if (distance === 0) {
        width = '0px';
      } else {
        width = 'calc(' + unitWidth + 'px * ' + distance + ' + 20px)';
      }
      return { width };
    },
    isPrevButtonEnable: function() {
      if (this.start == null) {
        return true;
      }
      return this.getDateOfIndex(0) > this.getDate(this.start);
    },
    isNextButtonEnable: function() {
      if (this.end == null) {
        return true;
      }
      return this.getDateOfIndex(this.num - 1) < this.getDate(this.end);
    }
  },

  methods: {
    isTitleShown: function(index) {
      if (index === 0) {
        return true;
      }
      switch (this.mode) {
        case this.modeType.HOUR:
          if (index <= 2 || index >= this.num - 1) {
            return false;
          } else {
            return this.getDateTitleStr(index - 1) !== this.getDateTitleStr(index);
          }
        default:
          return this.getDateTitleStr(index - 1) !== this.getDateTitleStr(index);
      }
    },
    getDateOfIndex: function(index) {
      let date;
      let firstDate;
      let firstMonth;
      switch (this.mode) {
        case this.modeType.HOUR:
          date = this.getDate(this.leftSide);
          date.setHours(date.getHours() + index);
          return date;
        case this.modeType.DAY:
          date = this.getDate(this.leftSide);
          date.setDate(date.getDate() + index);
          return date;
        case this.modeType.WEEK:
          date = this.getDate(this.leftSide);
          firstDate = this.getFirstDateOfWeek(date);
          firstDate.setDate(firstDate.getDate() + (index * 7));
          return firstDate;
        case this.modeType.MONTH:
          date = this.getDate(this.leftSide);
          date.setMonth(date.getMonth() + index);
          return date
        case this.modeType.THREE:
          date = this.getDate(this.leftSide);
          firstMonth = this.getFirstMonthOfThree(date);
          firstMonth.setMonth(firstMonth.getMonth() + (index * 3));
          return firstMonth;
        default:
          break;
      }
      return null;
    },
    currentClass: function(index) {
      let current = false;
      switch (this.mode) {
        case this.modeType.HOUR:
          current = this.getDateOfIndex(index).getTime() === this.getDate(this.today).getTime();
          break;
        case this.modeType.DAY:
          current = this.getDateOfIndex(index).getTime() === this.getDate(this.today).getTime();
          break;
        case this.modeType.WEEK:
          current = this.getDateOfIndex(index).getTime() === this.getFirstDateOfWeek(this.getDate(this.today)).getTime();
          break;
        case this.modeType.MONTH:
          current = this.getDateOfIndex(index).getTime() === this.getDate(this.today).getTime();
          break;
        case this.modeType.THREE:
          current = this.getDateOfIndex(index).getTime() === this.getFirstMonthOfThree(this.getDate(this.today)).getTime();
          break;
        default:
          break;
      }
      return { current }
    },
    getDateTitleStr: function(index) {
      const dateObj = this.getDateOfIndex(index);
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1);
      const date = dateObj.getDate();
      switch (this.mode) {
        case this.modeType.HOUR:
          return year + '/' + month + '/' + date;
        case this.modeType.DAY:
          return year + '/' + month;
        case this.modeType.WEEK:
          return year + '/' + month;
        case this.modeType.MONTH:
          return year;
        case this.modeType.THREE:
          return year;
        default:
          break;
      }
      return '';
    },
    getDayTitleStr: function(index) {
      const dateObj = this.getDateOfIndex(index);
      const days = ['日', '月', '火', '水', '木', '金', '土'];
      const day = days[dateObj.getDay()];
      switch (this.mode) {
        case this.modeType.HOUR:
          return ' ' + day + '曜日';
        default:
          return '';
      }
    },
    getDateStr: function(index) {
      const dateObj = this.getDateOfIndex(index);
      const month = dateObj.getMonth() + 1;
      const date = dateObj.getDate();
      const hour = dateObj.getHours();
      let endDate;
      switch (this.mode) {
        case this.modeType.HOUR:
          return hour;
        case this.modeType.DAY:
          return date;
        case this.modeType.WEEK:
          endDate = new Date(dateObj);
          endDate.setDate(dateObj.getDate() + 6);
          return date + ' - ' + endDate.getDate();
        case this.modeType.MONTH:
          return month;
        case this.modeType.THREE:
          return month + ' - ' + (month + 2);
        default:
          break;
      }
      return '';
    },
    getDayStr: function(index) {
      const dateObj = this.getDateOfIndex(index);
      const days = ['日', '月', '火', '水', '木', '金', '土'];
      const day = days[dateObj.getDay()];
      switch (this.mode) {
        case this.modeType.HOUR:
          return '時';
        case this.modeType.DAY:
          return day;
        case this.modeType.WEEK:
          return '日';
        case this.modeType.MONTH:
          return '月';
        case this.modeType.THREE:
          return '月';
        default:
          return '';
      }
    },
    getDayClass: function(index) {
      const dateObj = this.getDateOfIndex(index);
      const day = dateObj.getDay();
      if (this.mode === this.modeType.DAY) {
        if (day === 0) {
          return 'sun';
        } else if (day === 6) {
          return 'sat'
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    onPrevButtonClicked: function() {
      if (!this.isPrevButtonEnable) {
        return;
      }
      const prevDate = this.getDateOfIndex(-this.num);
      const date = {
        year: prevDate.getFullYear(),
        month: prevDate.getMonth() + 1,
        date: prevDate.getDate(),
        hour: prevDate.getHours()
      }
      this.$emit('update', { date });
    },
    onNextButtonClicked: function() {
      if (!this.isNextButtonEnable) {
        return;
      }
      const nextDate = this.getDateOfIndex(this.num);
      const date = {
        year: nextDate.getFullYear(),
        month: nextDate.getMonth() + 1,
        date: nextDate.getDate(),
        hour: nextDate.getHours()
      }
      this.$emit('update', { date });
    },
  },

  mixins: [mixin]
};
</script>

<style scoped lang="scss">
.analytics-axis {
  position: relative;
  padding-top: 46px;
  padding-bottom: 4px;
  padding-left: 44px;
  padding-right: 44px;
  margin-left: 42px;
  margin-right: 10px;
  background-color: rgb(231, 238, 247);
  border-radius: 6px;
  overflow: hidden;
  &.prediction {
    padding-top: 100px;
    ul {
      li {
        h3 {
          top: -84px;
        }
      }
    }
  }
  .bar-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    height: 32px;
    margin-left: 44px;
    margin-right: 44px;
    .past, .future {
      @include ellipsis;
      position: absolute;
      top: 0;
      height: 32px;
      line-height: 32px;
      color: $color_white;
      font-size: 14px;
      font-weight: $font_weight_bold;
      text-align: center;
    }
    .past {
      left: 0;
      border-top-right-radius: 16px;
      -webkit-border-top-right-radius: 16px;
      -moz-border-radius-topright: 16px;
      border-bottom-right-radius: 16px;
      -webkit-border-bottom-right-radius: 16px;
      -moz-border-radius-bottomright: 16px;
      background: -webkit-linear-gradient(left, rgba(125, 125, 125, 0.3), rgb(125, 125, 125));
      background: linear-gradient(to right, rgba(125, 125, 125, 0.3), rgb(125, 125, 125));
    }
    .future {
      right: 0;
      border-top-left-radius: 16px;
      -webkit-border-top-left-radius: 16px;
      -moz-border-radius-topleft: 16px;
      border-bottom-left-radius: 16px;
      -webkit-border-bottom-left-radius: 16px;
      -moz-border-radius-bottomleft: 16px;
      background: -webkit-linear-gradient(left, rgb(100, 163, 198), rgba(100, 163, 198, 0.3));
      background: linear-gradient(to right, rgb(100, 163, 198), rgba(100, 163, 198, 0.3));
    }
  }
  .btn-prev, .btn-next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 44px;
    background-color: $color_light_blue;
    &.disabled {
      opacity: 0.3;
      a {
        cursor: default;
      }
    }
    a {
      display: block;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 11px 19px;
      background-position: center center;
    }
  }
  .btn-prev {
    left: 0;
    a {
      background-image: url("/images/triangle_left.svg");
    }
  }
  .btn-next {
    right: 0;
    a {
      background-image: url("/images/triangle_right.svg");
    }
  }
  ul {
    @include flex_between;
    padding-left: 36px;
    li {
      width: 100%;
      position: relative;
      left: -22px;
      text-align: center;
      h3 {
        position: absolute;
        top: -40px;
        left: 20px;
        width: 240px;
        min-width: 100%;
        text-align: left;
        line-height: 30px;
        .date {
          font-size: 20px;
          font-weight: $font_weight_bold;
          color: $color_black;
          .num {
            font-family: $number_font_family;
            font-weight: $font_weight_number_bold;
            font-size: 26px;
            color: $color_black;
            margin-right: 6px;
          }
        }
      }
      p {
        display: inline-block;
        border-radius: 4px;
        padding-left: 14px;
        padding-right: 10px;
        min-width: 62px;
        font-size: 20px;
        vertical-align: middle;
        border: 2px solid transparent;
        .num {
          font-family: $number_font_family;
          font-size: 28px;
          font-weight: $font_weight_number_bold;
          margin-right: 4px;
        }
        &.sun {
          color: rgb(201, 47, 47);
        }
        &.sat {
          color: rgb(0, 155, 255);
        }
      }
      &.current {
        p {
          border: 2px solid $color_light_blue;
          color: $color_light_blue;
          background-color: $color_white;
          .num {
            color: $color_light_blue;
          }
        }
      }
    }
  }
}
</style>
