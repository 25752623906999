<template>
  <div>
    <Header current="mypage" />
    <div class="container">
      <div class="content-wrapper">
        <!-- <MypageMenu tag="shopinfo" /> -->
        <div class="content mypage">
          <h2>4ケタの数字のパスワードを入力してください</h2>
          <dl>
            <dt>パスワード</dt>
            <dd><input type="password" v-model="password" /></dd>
          </dl>
          <p v-if="isError" class="message">
            パスワードが間違っています。再度確認してください
          </p>
          <p class="btn-gradient">
            <a href="" @click.prevent="onClicked">ログイン</a>
          </p>
          <p class="message">
            <a href="" @click.prevent="onForget">パスワードを忘れた場合はこちら</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
// import MypageMenu from "@/components/menu/MypageMenu.vue";

export default {
  name: "Login",

  components: {
    Header,
    // MypageMenu,
  },

  data() {
    return {
      errMsg: "",
      password: "",
      isError: false,
    };
  },

  methods: {
    async onClicked() {
      const blCheck = await this.$store.dispatch("postPassCodeCheck", {
        unisCustomerCode: this.$store.getters.getUnisCustomerCode,
        passcode: this.password,
      });
      if (blCheck) {
        this.$router.push({ path: "/mypage/shopinfo" });
      } else {
        this.isError = true;
      }
    },
    onForget() {
      this.$router.push({ path: "/mypage/user/confirm" });
    }
  },
};
</script>

<style scoped lang="scss">
@include mypage-common;
</style>
