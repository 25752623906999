<template>
    <div>
        <Header current="mypage" />
        <div class="container">
            <div class="content-wrapper">
                <MypageMenu tag="shopinfo" :payView="payView" />
                <div class="content mypage">
                    <div class="editing" v-show="isEditing">
                        <dl>
                            <dt>店舗名</dt>
                            <dd><input type="text" v-model="tmpUserData.customer_name" /></dd>
                        </dl>
                        <dl>
                            <dt>郵便番号</dt>
                            <dd>
                                <input type="text" v-model="tmpUserData.customer_zip_cd" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>住所1</dt>
                            <dd>
                                <input type="text" v-model="tmpUserData.customer_address1" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>住所2</dt>
                            <dd>
                                <input type="text" v-model="tmpUserData.customer_address2" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>住所3</dt>
                            <dd>
                                <input type="text" v-model="tmpUserData.customer_address3" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>電話番号</dt>
                            <dd><input type="text" v-model="tmpUserData.customer_tel" /></dd>
                        </dl>
                        <!-- <dl>
              <dt>メールアドレス</dt>
              <dd><input type="text" v-model="tmpUserData.mailAddress"/></dd>
            </dl>
            <dl>
              <dt>Webサイト</dt>
              <dd><input type="text" v-model="tmpUserData.website"/></dd>
            </dl> -->
                        <div class="my-business"><input type="checkbox" />Googleのマイビジネスに反映する<span class="icon-guide"></span></div>
                        <div class="button-wrapper">
                            <p class="btn-white">
                                <a href="" @click.prevent="endEditing">キャンセル</a>
                            </p>
                            <p class="btn-gradient">
                                <a href="" @click.prevent="">送信する</a>
                            </p>
                        </div>
                    </div>
                    <div class="prview" v-show="!isEditing">
                        <dl>
                            <dt>店舗名</dt>
                            <dd>
                                <p>
                                    {{ getUserData != null ? getUserData.customer_name : '' }}
                                </p>
                            </dd>
                        </dl>
                        <dl>
                            <dt>郵便番号</dt>
                            <dd>
                                <p>
                                    {{ getUserData != null ? getUserData.customer_zip_cd : '' }}
                                </p>
                            </dd>
                        </dl>
                        <dl>
                            <dt>住所1</dt>
                            <dd>
                                <p>
                                    {{ getUserData != null ? getUserData.customer_address1 : '' }}
                                </p>
                            </dd>
                        </dl>
                        <dl>
                            <dt>住所2</dt>
                            <dd>
                                <p>
                                    {{ getUserData != null ? getUserData.customer_address2 : '' }}
                                </p>
                            </dd>
                        </dl>
                        <dl>
                            <dt>住所3</dt>
                            <dd>
                                <p>
                                    {{ getUserData != null ? getUserData.customer_address3 : '' }}
                                </p>
                            </dd>
                        </dl>
                        <dl>
                            <dt>電話番号</dt>
                            <dd>
                                <p>{{ getUserData != null ? getUserData.customer_tel : '' }}</p>
                            </dd>
                        </dl>
                        <!-- <dl>
              <dt>メールアドレス</dt>
              <dd><p>{{ getUserData != null ? getUserData.mailAddress : '' }}</p></dd>
            </dl>
            <dl>
              <dt>Webサイト</dt>
              <dd><p>{{ getUserData != null ? getUserData.website : '' }}</p></dd>
            </dl> -->
                        <!-- <div class="my-business">
              Googleのマイビジネスに反映しています<span class="icon-guide"></span>
            </div>
            <p class="btn-gradient"><a href="" @click.prevent="startEditing">登録内容を変更する</a></p>
            <p class="bar"></p> -->
                        <p class="btn-gradient">
                            <a href="" @click.prevent="$router.push('/mypage/password/set')">パスワード変更</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import MypageMenu from '@/components/menu/MypageMenu.vue';
import setparam from '@/mixin/setparam.js';
import mypageapicall from '@/mixin/mypageapicall.js';
import { mapGetters } from 'vuex';
export default {
    name: 'ShopInfo',

    computed: mapGetters(['getUserData']),

    components: {
        Header,
        MypageMenu,
    },

    data() {
        return {
            isEditing: false,
            tmpUserData: {
                customer_name: '',
                customer_zip_cd: '',
                customer_address1: '',
                customer_address2: '',
                customer_address3: '',
                customer_tel: '',
                // mailAddress: '',
                // website: '',
            },
        };
    },
    methods: {
        startEditing() {
            this.tmpUserData = Object.assign(this.tmpUserData, this.getUserData);
            this.isEditing = true;
        },
        endEditing() {
            this.isEditing = false;
        },
    },

    mixins: [setparam, mypageapicall],
};
</script>

<style scoped lang="scss">
@include mypage-common;
.content.mypage {
    padding-top: 30px;
    dl {
        dd {
            p {
                font-size: 16px;
                color: $color_black;
                font-weight: $font_weight_bold;
                line-height: 46px;
            }
        }
    }
    .prview {
        dl {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -10px;
                height: 1px;
                width: 900px;
                background-color: rgb(216, 216, 216);
            }
            &:first-child {
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: -10px;
                    height: 1px;
                    width: 900px;
                    background-color: rgb(216, 216, 216);
                }
            }
        }
    }
    .my-business {
        margin-top: 50px;
        font-size: 16px;
        color: $color_black;
        line-height: 40px;
        vertical-align: middle;
        text-align: center;
        input {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-right: 10px;
        }
        .icon-guide {
            display: inline-block;
            width: 40px;
            height: 40px;
            background-image: url('/images/icon_guide.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            vertical-align: middle;
            margin-left: 10px;
            cursor: pointer;
        }
    }
    .btn-gradient,
    .btn-white {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .bar {
        height: 1px;
        width: 900px;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(216, 216, 216);
    }
}
</style>
