var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", { attrs: { current: "analytics" } }),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "content-wrapper" },
          [
            _c("AnalyticsMenu", {
              attrs: {
                tag: "visitors-prediction",
                largeBizCd: _vm.largeBizCd,
                visitorView: _vm.visitorView,
              },
            }),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("AnalyticsHeader", {
                  attrs: {
                    isPrediction: true,
                    title: "来客数の予測（β版）",
                    mode: _vm.$route.params.mode,
                  },
                }),
                _c(
                  "div",
                  { staticClass: "content-analytics" },
                  [
                    _c("AnalyticsAxis", {
                      attrs: {
                        isPrediction: true,
                        mode: _vm.$route.params.mode,
                        leftSide: _vm.leftSide,
                        num: _vm.axisNum,
                      },
                      on: { update: _vm.onAxisUpdated },
                    }),
                    _c("AnalyticsSalesGraph", {
                      attrs: { mode: _vm.$route.params.mode, num: _vm.axisNum },
                    }),
                    _c("AnalyticsGenderGraph", {
                      attrs: {
                        isPrediction: true,
                        mode: _vm.$route.params.mode,
                      },
                    }),
                    _c("AnalyticsRepeaterGraph", {
                      attrs: {
                        isPrediction: true,
                        mode: _vm.$route.params.mode,
                      },
                    }),
                    _vm.isWeatherShown
                      ? _c("AnalyticsWeather", {
                          attrs: {
                            mode: _vm.$route.params.mode,
                            leftSide: _vm.leftSide,
                            num: _vm.axisNum,
                          },
                        })
                      : _vm._e(),
                    _vm.isWeatherShown
                      ? _c("AnalyticsEvent", {
                          attrs: {
                            mode: _vm.$route.params.mode,
                            leftSide: _vm.leftSide,
                            num: _vm.axisNum,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }