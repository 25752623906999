<template>
  <div class="header-mypage">
    <p class="btn-back"><a href="/" @click.prevent="back">戻る</a></p>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'MypageHeader',

  methods: {
    back: function() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
.header-mypage {
  overflow: hidden;
  height: 70px;
  border-bottom: 1px solid rgb(193, 193, 193);
  background-color: rgb(240, 240, 240);
  .btn-back {
    position: absolute;
    top: 25px;
    left: 40px;
    a {
      color: $color_light_blue;
      font-size: 19px;
      font-weight: $font_weight_bold;
      line-height: 22px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -24px;
        top: -1px;
        width: 24px;
        height: 22px;
        background-image: url("/images/icon_back.svg");
        background-repeat: no-repeat;
        background-size: 14px 22px;
      }
    }
  }
  ul {
    @include flex_center;
    margin-top: 14px;
    li {
      width: 120px;
      height: 42px;
      border-radius: 3px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        color: $color_black;
        font-size: 20px;
        font-weight: $font_weight_bold;
        line-height: 42px;
        text-align: center;
      }
      &.selected {
        background-color: $color_light_blue;
        a {
          color: $color_white;
        }
      }
    }
  }
}
</style>
