var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error" }, [
    _c("div", { staticClass: "base" }, [
      _c("h2", [
        _vm._v("問題が発生したため、ただいまアプリをご利用いただけません"),
      ]),
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "info" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v("USENインフォメーションセンター お問い合わせフォーム"),
        ]),
        _c("p", { staticClass: "btn-gradient" }, [
          _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onClicked.apply(null, arguments)
                },
              },
            },
            [_vm._v("お問い合わせフォーム")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" サービスに関するお問い合わせをお受けいたしております。"),
      _c("br"),
      _vm._v(" USENインフォメーションセンターまでお問い合わせください。 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("p", { staticClass: "title" }, [
        _vm._v("USENインフォメーションセンター"),
      ]),
      _c("p", { staticClass: "phone" }, [_vm._v("0120-117-440")]),
      _c("p", { staticClass: "sub" }, [_vm._v("9:00～22:30 (年中無休)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }