<template>
    <div id="app" :class="{ debug: isDebug }">
        <router-view />
    </div>
</template>

<script>
export default {
    computed: {
        isDebug: function () {
            return this.$store.state.isDebug;
        },
    },
    async mounted() {
        const unisCustomerCode = this.$route.query.unis_cd;
        console.log(unisCustomerCode);
        await this.$store.dispatch('fetchUser', {'unisCustomerCode': unisCustomerCode});
    },
    // created() {
        // if (this.isDebug) {
        //     setTimeout(() => {
        //         this.$store.commit('setUserData', {
        //             user: {
        //                 lastLogin:1704285495,
        //                 passcode:'03ac674216f3e15c761ee1a5e255f067953623c8b388b4459e13f978d7c846f4',
        //                 data: {
        //                     customer_group_cd: '',
        //                     customer_chain_name: '',
        //                     contract_agency_cd: '',
        //                     customer_group_name_kana: '',
        //                     bgm_industry_name: '',
        //                     neos_item_name: 'U EYES 専用8インチ・タブレット(黒)',
        //                     customer_address3: '1丁目5-23',
        //                     contract_start_date: '2020-09-04',
        //                     sales_person_name: '野村拓史',
        //                     customer_group_authority_cd: '',
        //                     renewal_date: '2020-09-04T07:55:43+09:00',
        //                     customer_tel: '0357677535',
        //                     service_cd: 'U03',
        //                     sales_branch_name: 'その他',
        //                     unis_industry_name: '居酒屋・海鮮料理',
        //                     create_date: '2020-09-04T07:55:43+09:00',
        //                     customer_large_bizcd: '10',
        //                     customer_middle_bizcd: '131', //和風居酒屋/焼鳥/寿司/割烹
        //                     customer_small_bizcd: '013101', //居酒屋/海鮮料理
        //                     // customer_large_bizcd: '20', //理美容
        //                     // customer_middle_bizcd: '210', //美容室/ネイル/理容室
        //                     // customer_small_bizcd: '030201', //美容室
        //                     // customer_large_bizcd: '30', //小売
        //                     // customer_middle_bizcd: '330', //ドラッグストア/薬局/化粧品店
        //                     // customer_small_bizcd: '020601', //ドラッグストア/薬局
        //                     // customer_zip_cd: '140-0013',
        //                     bgm_industry_cd: '',
        //                     contract_status_cd: '1',
        //                     service_name: 'U EYES',
        //                     sales_branch_cd: '0000000000',
        //                     contract_status_name: '受注',
        //                     customer_chain_cd: '',
        //                     sales_division_cd: '0000000000',
        //                     customer_update_date: '2020-09-04T07:49:01+09:00',
        //                     // unis_customer_cd: '490001083',
        //                     // unis_customer_cd: '490001211',//SHIPS
        //                     // unis_customer_cd: '490001212',//SHIPS
        //                     unis_customer_cd: '123450002',
        //                     // unis_customer_cd: '490001253',//坂本さん
        //                     // unis_customer_cd: '490001071',//浜野さん
        //                     customer_name_kana: 'テストダミー',
        //                     status_update_date: '2020-09-04T07:49:01+09:00',
        //                     unis_industry_cd: '013101',
        //                     contract_cd: 'N12345000000000000001',
        //                     customer_branch_cd: '0204141000',
        //                     customer_state_cd: '13',
        //                     cm_industry_cd: '',
        //                     sales_person_cd: '37832',
        //                     customer_state_name: '東京都',
        //                     sales_division_name: 'その他',
        //                     customer_branch_name: 'セントラルスクエア支店',
        //                     cm_industry_name: '',
        //                     customer_group_name: '',
        //                     customer_address1: '東京都品川区',
        //                     customer_address2: '上大崎',
        //                     contract_agency_name: '',
        //                     customer_name: 'FirstForNotes',
        //                     customer_area: '13109',
        //                     customer_area_name: '東京都品川区',
        //                     neos_item_cd: 'UT8M810_n04',
        //                 },
        //             },
        //         });
        //     }, 1000);
        // }
    // },
};
</script>

<style lang="scss">
* {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    background: transparent;
    letter-spacing: 0;
    -webkit-tap-highlight-color: transparent;
    box-sizing: content-box;
}

img,
button {
    border: 0;
}

img,
textarea {
    vertical-align: top;
}

textarea {
    font-family: inherit;
}

a:focus {
    outline: none;
}

div:focus,
button:focus {
    outline: none;
}

ul,
ol {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;
}

.clearfix {
    zoom: 1;
    /* for IE5.5 - IE7 */
}

.clearfix:after {
    /* for modern browser */
    content: ' ';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

body {
    font-family: $default_font_family;
    background-color: #e2f1e3;
    background-image: url('/images/bg_gradient.png');
    background-repeat: repeat-x;
    background-attachment: fixed;
    font-size: 16px;
    color: $color_black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#app {
    height: 100%;
    overflow: hidden;
}

.container {
    height: 100%;
    overflow: hidden;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.content-wrapper {
    height: 100vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    display: flex;
    flex-flow: row nowrap;
    .menu {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .content {
        flex-grow: 1;
        flex-shrink: 1;
    }
}

.content {
    height: 100vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color: $color_white;
    position: relative;
    min-height: 100vh;
}
.debug {
    .content {
        min-height: calc(100vh - 80px);
    }
}

.sp {
    display: none;
}

@include sp-screen {
    .tablet {
        display: none;
    }

    .sp {
        display: block;
    }
}
</style>
