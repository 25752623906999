export default {
    data: function () {
        return {
            timer: null,
            lastFetched: null,
        };
    },

    computed: {
        today: function () {
            return this.getDateObj(new Date());
        },
        auth() {
            return this.$store.state.auth;
        },
    },

    methods: {
        fetchWeather: async function () {
            const today = new Date();
            const from = this.getDateStr(this.getNDaysLater(today, -30));
            const to = this.getDateStr(this.getNDaysLater(today, 7));
            await this.$store.dispatch('fetchWeather', { mode: 'day', from, to });
        },

        fetchSales: async function () {
            if (!this.unisCustomerCode || !this.mode || !this.leftSide) {
                return;
            }
            let date;
            let from;
            let to;
            let tmpTo;
            switch (this.mode) {
                case this.modeType.HOUR:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date, this.leftSide.hour);
                    from = this.getDateStr(this.getNDaysLater(date, -1));
                    to = this.getDateStr(this.getNDaysLater(date, 1));
                    break;
                case this.modeType.DAY:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date);
                    from = this.getDateStr(this.getNDaysLater(date, -1 * this.axisNum));
                    to = this.getDateStr(this.getNDaysLater(date, this.axisNum * 2 - 1));
                    break;
                case this.modeType.WEEK:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date);
                    from = this.getDateStr(this.getNDaysLater(date, -1 * this.axisNum * 7));
                    to = this.getDateStr(this.getNDaysLater(date, this.axisNum * 7 * 2 - 1));
                    break;
                case this.modeType.MONTH:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1);
                    from = this.getDateStr(this.getNMonthsLater(date, -1 * this.axisNum));
                    tmpTo = this.getNMonthsLater(date, this.axisNum * 2);
                    tmpTo = this.getNDaysLater(tmpTo, -1);
                    to = this.getDateStr(tmpTo);
                    break;
                case this.modeType.THREE:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1);
                    from = this.getDateStr(this.getNMonthsLater(date, -1 * this.axisNum * 3));
                    tmpTo = this.getNMonthsLater(date, this.axisNum * 3 * 2);
                    tmpTo = this.getNDaysLater(tmpTo, -1);
                    to = this.getDateStr(tmpTo);
                    break;
                default:
                    break;
            }
            await this.$store.dispatch('fetchSales', { unisCustomerCode: this.unisCustomerCode, mode: this.mode, from, to });
        },

        fetchVisitors: async function () {
            const today = new Date();
            const from = this.getDateStr(this.getNDaysLater(today, -30));
            const to = this.getDateStr(this.getNDaysLater(today, 0));
            await this.$store.dispatch('fetchVisitors', { unisCustomerCode: this.unisCustomerCode, mode: 'day', from, to });
        },
        fetchVisitorOption: async function () {
            await this.$store.dispatch('fetchVisitorOption', { unisCustomerCode: this.unisCustomerCode });
        },
        fetchSalesAndVisitors: async function () {
            if (!this.unisCustomerCode || !this.mode || !this.leftSide) {
                return;
            }
            let date;
            let from;
            let to;
            let tmpTo;
            switch (this.mode) {
                case this.modeType.HOUR:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date, this.leftSide.hour);
                    from = this.getDateStr(this.getNDaysLater(date, -1));
                    to = this.getDateStr(this.getNDaysLater(date, 1));
                    break;
                case this.modeType.DAY:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date);
                    from = this.getDateStr(this.getNDaysLater(date, -1 * this.axisNum));
                    to = this.getDateStr(this.getNDaysLater(date, this.axisNum * 2 - 1));
                    break;
                case this.modeType.WEEK:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date);
                    from = this.getDateStr(this.getNDaysLater(date, -1 * this.axisNum * 7));
                    to = this.getDateStr(this.getNDaysLater(date, this.axisNum * 7 * 2 - 1));
                    break;
                case this.modeType.MONTH:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1);
                    from = this.getDateStr(this.getNMonthsLater(date, -1 * this.axisNum));
                    tmpTo = this.getNMonthsLater(date, this.axisNum * 2);
                    tmpTo = this.getNDaysLater(tmpTo, -1);
                    to = this.getDateStr(tmpTo);
                    break;
                case this.modeType.THREE:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1);
                    from = this.getDateStr(this.getNMonthsLater(date, -1 * this.axisNum * 3));
                    tmpTo = this.getNMonthsLater(date, this.axisNum * 3 * 2);
                    tmpTo = this.getNDaysLater(tmpTo, -1);
                    to = this.getDateStr(tmpTo);
                    break;
                default:
                    break;
            }
            // if (this.mode !== this.modeType.HOUR) {
            //     await this.$store.dispatch('fetchSales', { unisCustomerCode: this.unisCustomerCode, mode: this.mode, from, to });
            // }
            await this.$store.dispatch('fetchVisitors', { unisCustomerCode: this.unisCustomerCode, mode: this.mode, from, to });
        },

        fetchVisitorsAverage: async function () {
            if (!this.unisCustomerCode || !this.mode || !this.leftSide) {
                return;
            }
            let date;
            let from;
            let to;
            let tmpTo;
            switch (this.mode) {
                case this.modeType.HOUR:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date, this.leftSide.hour);
                    from = this.getDateStr(this.getNDaysLater(date, -1));
                    to = this.getDateStr(this.getNDaysLater(date, 1));
                    break;
                case this.modeType.DAY:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date);
                    from = this.getDateStr(this.getNDaysLater(date, -1 * this.axisNum));
                    to = this.getDateStr(this.getNDaysLater(date, this.axisNum * 2 - 1));
                    break;
                case this.modeType.WEEK:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1, this.leftSide.date);
                    from = this.getDateStr(this.getNDaysLater(date, -1 * this.axisNum * 7));
                    to = this.getDateStr(this.getNDaysLater(date, this.axisNum * 7 * 2 - 1));
                    break;
                case this.modeType.MONTH:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1);
                    from = this.getDateStr(this.getNMonthsLater(date, -1 * this.axisNum));
                    tmpTo = this.getNMonthsLater(date, this.axisNum * 2);
                    tmpTo = this.getNDaysLater(tmpTo, -1);
                    to = this.getDateStr(tmpTo);
                    break;
                case this.modeType.THREE:
                    date = new Date(this.leftSide.year, this.leftSide.month - 1);
                    from = this.getDateStr(this.getNMonthsLater(date, -1 * this.axisNum * 3));
                    tmpTo = this.getNMonthsLater(date, this.axisNum * 3 * 2);
                    tmpTo = this.getNDaysLater(tmpTo, -1);
                    to = this.getDateStr(tmpTo);
                    break;
                default:
                    break;
            }
            await this.$store.dispatch('fetchVisitorsAverage', { unisCustomerCode: this.unisCustomerCode, mode: this.mode, from, to });
        },

        startPolling: async function () {
            if (this.timer != null) {
                return;
            }
            const date = new Date();
            await this.fetchWeather();
            await this.fetchVisitors();
            await this.fetchSales();
            this.lastFetched = date;

            this.timer = setInterval(async () => {
                const date = new Date();
                // 前回実行時から日付が変わっていれば実行
                if (date.getDate() !== this.lastFetched.getDate()) {
                    // 0:00〜0:05 の間の取得は避ける
                    if (date.getHours() === 0 && date.getMinutes() < 5) {
                        return;
                    }
                    await this.fetchWeather();
                    await this.fetchVisitors();
                    this.lastFetched = date;
                }
            }, 5 * 60 * 1000); // 5分に1回実行
        },
        fetchUserProductCode: async function () {
            await this.$store.dispatch('fetchUserProductCode', { unisCustomerCode: this.unisCustomerCode });
        }
    },

    async created() {
        // console.log('created');
        if (this.auth.user != null) {
            await this.$store.dispatch('fetchEvents');
            await this.fetchSalesAndVisitors();
            // await this.fetchVisitorsAverage();
            await this.startPolling();
            await this.fetchUserProductCode();
        }
    },

    watch: {
        auth: {
            async handler() {
                // console.log('handler');
                if (this.auth.user != null) {
                    await this.$store.dispatch('fetchEvents');
                    await this.fetchSalesAndVisitors();
                    // await this.fetchVisitorsAverage();
                    await this.fetchSales();
                    await this.startPolling();
                }
            },
            deep: true,
        },
        async leftSide() {
            // console.log('leftSide');
            await this.fetchSalesAndVisitors();
            // await this.fetchVisitorsAverage();
            await this.fetchSales();
        },
    },

    beforeDestroy: function () {
        if (this.timer != null) {
            // console.log('stopPolling');
            clearInterval(this.timer);
            this.timer = null;
        }
    },
};
