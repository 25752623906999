<template>
  <div class="shop-analytics-axis">
    <h3>
      <span v-if="mode === modeType.MODE_AXIS" class="date"><span class="num">{{ leftSide.year }}</span>年</span>
      <span v-if="mode === modeType.MODE_COUNT" class="prefix">対象:</span><span v-if="mode === modeType.MODE_COUNT" class="date"><span class="num">{{ shopCount }}</span>店舗</span>
    </h3>
    <ul v-if="mode === modeType.MODE_AXIS">
      <li v-for="n in axisNum"  :key="n" :class="currentClass(n - 1)">
        <h3 v-if="isTitleShown(n - 1)">
          <span class="date"><span class="num">{{ getTitleStr(n - 1) }}</span>年</span>
        </h3>
        <p><span class="num">{{ getDateStr(n - 1) }}</span>月</p>
      </li>
    </ul>
    <p class="created" v-if="mode === modeType.MODE_COUNT">{{ prevMonth.substring(0,4)}}年{{ prevMonth.substring(4,6)}}月のデータ</p>
  </div>
</template>

<script>
import mixin from '@/mixin/index.js';

export default {
  name: 'ShopAnalyticsAxis',

  props: {
    mode: {
      type: String,
      required: true
    },
    axisNum: {
      type: Number,
      default: 0
    },
    shopCount: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    }
  },

  data: function() {
    return {
      modeType: {
        MODE_AXIS: 'MODE_AXIS',
        MODE_COUNT: 'MODE_COUNT',
      }
    };
  },

  computed: {
    today: function() {
      return this.getDateObj(new Date());
    },
    prevMonth: function() {
      return this.getPrevMonth();
    },
    leftSide: function() {
      const date = this.getDate(this.today);
      //過去データが蓄積されるまでの対応
      if(this.type === 'TARGET' && date.getFullYear() === 2021){
          date.setMonth(5);
      }else{
          date.setMonth(date.getMonth() - (this.axisNum - 1));
      }
      date.setDate(1);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        date: date.getDate(),
        hour: date.getHours(),
      };
    }
  },

  methods: {
    isTitleShown: function(index) {
      if (index === 0) {
        return false;
      }
      return this.getTitleStr(index - 1) !== this.getTitleStr(index);
    },
    getDateOfIndex: function(index) {
      const date = this.getDate(this.leftSide);
      date.setMonth(date.getMonth() + index);
      return date
    },
    currentClass: function(index) {
      const current = this.getDateOfIndex(index).getTime() === this.getDate(this.today).getTime();
      return { current }
    },
    getTitleStr: function(index) {
      const dateObj = this.getDateOfIndex(index);
      const year = dateObj.getFullYear();
      return year;
    },
    getDateStr: function(index) {
      const dateObj = this.getDateOfIndex(index);
      const month = dateObj.getMonth() + 1;
      return month;
    },    
    getMonthStr: function(index) {
      const dateObj = this.getDateOfIndex(index);
      const month = dateObj.getMonth() + 1;
      return month;
    },
  },

  mixins: [mixin]
};
</script>

<style scoped lang="scss">
.shop-analytics-axis {
  position: relative;
  margin-top: 20px;
  border-bottom: 2px solid $color_black;
  h3 {
    margin-bottom: 10px;
    .date {
      font-size: 20px;
      color: $color_black;
      .num {
        font-family: $number_font_family;
        font-weight: $font_weight_number_bold;
        font-size: 40px;
        color: $color_black;
        margin-right: 6px;
      }
    }
    .prefix {
      font-size: 20px;
      color: $color_black;
      padding-right: 6px;
    }
  }
  .created {
    font-size: 16px;
    color: $color_black;
    position: absolute;
    bottom: 14px;
    right: 0;
  }
  ul {
    @include flex_between;
    padding-left: 30px;
    padding-right: 0px;
    margin-bottom: 4px;
    li {
      width: 100%;
      position: relative;
      text-align: center;
      h3 {
        position: absolute;
        top: -50px;
        left: 20px;
        width: 240px;
        min-width: 100%;
        text-align: left;
        line-height: 30px;
      }
      p {
        display: inline-block;
        border-radius: 4px;
        padding-left: 14px;
        padding-right: 10px;
        min-width: 62px;
        font-size: 20px;
        vertical-align: middle;
        border: 2px solid transparent;
        line-height: 36px;
        height: 36px;
        .num {
          font-family: $number_font_family;
          font-size: 30px;
          font-weight: $font_weight_number_bold;
          margin-right: 4px;
        }
      }
      &.current {
        p {
          color: $color_white;
          background-color: $color_light_blue;
          .num {
            color: $color_white;
          }
        }
      }
    }
  }
}
</style>
