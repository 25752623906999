<template>
  <div>
    <Header current="calendar"/>
    <div class="container">
      <div class="content-wrapper">
        <CalendarMenu class="tablet"/>
        <div class="content">
          <ControlHeader class="sp"/>
          <EventCalendarHeader/>
          <EventCalendarList :month="$route.params.year + $route.params.month"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import smoothscroll from 'smoothscroll-polyfill';
import mixin from '@/mixin/index.js';
import setparam from '@/mixin/setparam.js';
import apicall from '@/mixin/apicall.js';

// @ is an alias to /src
import Header from '@/components/Header.vue';
import CalendarMenu from '@/components/menu/CalendarMenu.vue';
import ControlHeader from '@/components/ControlHeader.vue';
import EventCalendarHeader from '@/components/EventCalendarHeader.vue';
import EventCalendarList from '@/components/EventCalendarList.vue';

export default {
  name: 'EventCalendar',

  components: {
    Header,
    CalendarMenu,
    ControlHeader,
    EventCalendarHeader,
    EventCalendarList
  },

  methods: {
    async fetchSales() {
      if (!this.unisCustomerCode) {
        return;
      }
      const from = this.$route.params.year + this.$route.params.month + '01';
      const date = this.getDate(from);
      const to = this.getDateStr(this.getNDaysLater(this.getNMonthsLater(date, 1), -1));
      await this.$store.dispatch('fetchSales', { unisCustomerCode: this.unisCustomerCode, mode: 'day', from, to });
    },

    scrollToToday() {
      Vue.nextTick(() => {
        const element = this.$el.querySelector('.list-event-calendar .today');
        if (element != null) {
          const top = element.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({top: top, behavior: 'smooth'});
        } else {
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
      });
    }
  },

  async created() {
    await this.fetchSales();
  },

  mounted() {
    smoothscroll.polyfill();
    this.scrollToToday();
  },

  watch: {
    async $route() {
      await this.fetchSales();
      this.scrollToToday();
    }
  },

  mixins: [mixin, setparam, apicall]
};
</script>
