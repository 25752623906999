<template>
  <div>
    <Header current="mypage"/>
    <div class="container">
      <div class="content-wrapper">
        <!-- <MypageMenu tag="shopinfo"/> -->
        <div class="content mypage">
          <h2>UNIS顧客IDを入力してください</h2>
          <dl>
            <dt>UNIS顧客ID</dt>
            <dd><input type="unis_customer_cd" v-model="customerCode"/></dd>
          </dl>
          <p v-if="isError" class="message">UNIS顧客IDが存在しません。再度確認してください</p>
          <p class="btn-gradient"><a href="" @click.prevent="onClicked">再設定する</a></p>
          <p class="message">
            UNIS顧客IDが不明な場合は、<br>以下からお問い合わせください。<br>
            <a href="https://usen.com/contact/service-uic/input.php" target="_blank">USENインフォメーションセンターお問い合わせフォーム</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
// import MypageMenu from '@/components/menu/MypageMenu.vue';

export default {
  name: 'ConfirmUser',

  components: {
    Header,
    // MypageMenu,
  },

  data() {
    return {
      customerCode: '',
      isError: false,
    };
  },

  methods: {
    async onClicked() {
      if (this.customerCode === '') {
        this.isError = true;
      } else {
        this.isError = false;
        if(this.customerCode === this.$store.getters.getUnisCustomerCode){
          this.isError = false;
          this.$router.push({ path: '/mypage/password/set'} );
        }else {
          this.isError = true;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@include mypage-common;
</style>
