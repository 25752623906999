var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", { attrs: { current: "calendar" } }),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "content-wrapper" },
          [
            _c("CalendarMenu", { staticClass: "tablet" }),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("ControlHeader", { staticClass: "sp" }),
                _c("EventCalendarHeader"),
                _c("EventCalendarList", {
                  attrs: {
                    month: _vm.$route.params.year + _vm.$route.params.month,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }