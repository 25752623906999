var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AnalyticsGraphWrapper", { staticClass: "graph-wrapper" }, [
    _c("h4", [_vm._v(_vm._s(_vm.title))]),
    _c(
      "p",
      { attrs: { id: "wrapper" } },
      [
        _c("bar-chart", {
          attrs: {
            height: 120,
            "chart-data": _vm.chartData,
            options: _vm.options,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }