<template>
  <Menu>
    <ul class="list-menu-shopinfo">
      <li v-for="menu in menuList.shopInfo" :key="menu.path" :class="{ selected: menu.tag === tag }">
        <p><router-link :to="menu.path">{{ menu.title }}</router-link></p>
      </li>
    </ul>
    <div v-if="payView">
      <p class="menu-title">U ペイ</p>
      <ul class="list-menu-upay">
        <li v-for="menu in menuList.upay" :key="menu.path" :class="{ selected: menu.tag === tag }">
          <p><router-link :to="menu.path">{{ menu.title }}</router-link></p>
        </li>
      </ul>
    </div>
  </Menu>
</template>

<script>
import Menu from '@/components/menu/Menu.vue';

export default {
  name: 'MypageMenu',

  components: {
    Menu
  },

  props: {
    payView: {
      type: Boolean,
      required: true,
    },
    tag: {
      type: String,
      required: true
    }
  },

  data: function() {
    return {
      menuList: {
        shopInfo: [
          {
            path: '/mypage/shopinfo',
            tag: 'shopinfo',
            title: '店舗・事業所情報',
          },
          // {
          //   path: '/mypage/mybusiness',
          //   tag: 'mybusiness',
          //   title: 'Googleマイビジネス情報',
          // },
        ],
        upay: [
          {
            path: '/mypage/upay/payment',
            tag: 'payment',
            title: '入金予定',
          },
          {
            path: '/mypage/upay/transition',
            tag: 'transition',
            title: '取扱高推移',
          },
          // {
          //   path: '/mypage/upay/admin',
          //   tag: 'admin',
          //   title: '管理画面',
          // },
        ]
      }
    };
  }
};
</script>
