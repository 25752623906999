var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-color" },
    [
      _c("Header", { attrs: { current: "top" } }),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "bg" }),
            _c("WeatherCalendarHeader", {
              attrs: { mode: _vm.mode },
              on: { "change-mode": _vm.onChangeMode },
            }),
            _c("WeatherCalendar", {
              attrs: {
                mode: _vm.mode,
                date: _vm.date,
                days: _vm.days,
                now: _vm.now,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }