var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AnalyticsGraphWrapper", [
    _c("h4", [_vm._v("顔認識数グラフ")]),
    _c("div", { staticClass: "control" }, [
      _c("div", { staticClass: "gender" }),
      _c("div", { staticClass: "label" }, [
        _c("span", { staticClass: "color color-valid" }),
        _vm._v("認識数"),
      ]),
      _vm.visitorOption
        ? _c("div", { staticClass: "label" }, [
            _c("span", { staticClass: "color color-invalid" }),
            _vm._v("未認識数"),
          ])
        : _vm._e(),
      _vm.visitorOption
        ? _c("div", { staticClass: "label" }, [
            _c("span", { staticClass: "line color-line" }),
            _vm._v("認識比率"),
          ])
        : _vm._e(),
    ]),
    _vm.visitorOption
      ? _c(
          "p",
          { staticClass: "graph" },
          [
            _c(
              "span",
              { staticClass: "scale-label", style: { left: "20px" } },
              [_vm._v("人")]
            ),
            _c("StackedBarChart", {
              attrs: { height: 300, chartData: _vm.chartDataAnonymous },
            }),
          ],
          1
        )
      : _c(
          "p",
          { staticClass: "graph" },
          [
            _c(
              "span",
              { staticClass: "scale-label", style: { left: "20px" } },
              [_vm._v("人")]
            ),
            _c("StackedBarChart", {
              attrs: { height: 300, chartData: _vm.chartDataNoAnonymous },
            }),
          ],
          1
        ),
    _vm.visitorOption
      ? _c(
          "ul",
          { staticClass: "ratio-list first" },
          [
            _c("li", { staticClass: "title" }, [
              _c("ul", { staticClass: "title-list" }, [
                _c("li", [_vm._v("認識比率")]),
              ]),
            ]),
            _vm._l(_vm.visitorsList, function (visitors, index) {
              return _c("li", { key: index }, [
                _c("ul", { staticClass: "value-list" }, [
                  _c("li", { staticClass: "total" }, [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          (visitors
                            ? parseInt((visitors.known / visitors.total) * 100)
                            : 0) + "%"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }