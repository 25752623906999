<template>
    <div class="weather-calendar-header">
        <p class="location">
            {{ userData ? userData.customer_address1 : '' }}{{ userData ? userData.customer_address2 : '' }}<br />{{ weatherTime }}
        </p>
        <h2>天気予報</h2>
        <SelectBox title="" :options="selectBoxOptions" :value="mode" @update="onUpdate" />
    </div>
</template>

<script>
import SelectBox from '@/components/SelectBox.vue';

export default {
    name: 'WeatherCalendarHeader',

    components: {
        SelectBox,
    },

    props: {
        mode: {
            type: String,
            required: true,
        },
    },

    computed: {
        modeType: function () {
            return this.$store.state.analyticsModeType;
        },
        weatherTime: function () {
            return this.$store.getters.weatherTime;
        },
        selectBoxOptions: function () {
            return [
                {
                    value: this.modeType.HOUR,
                    title: '１時間',
                },
                {
                    value: this.modeType.THREE_HOURS,
                    title: '３時間',
                },
                {
                    value: this.modeType.DAY,
                    title: '１日',
                },
            ];
        },
        userData: function () {
            return this.$store.getters.getUserData;
        },
    },

    methods: {
        onUpdate(event) {
            this.$emit('change-mode', event);
        },
    },
};
</script>

<style scoped lang="scss">
.weather-calendar-header {
    position: relative;
    height: 80px;
    padding-top: 20px;
    h2 {
        color: $color_white;
        font-size: 24px;
        font-weight: $font_weight_bold;
        text-align: center;
        line-height: 40px;
    }
    .location {
        position: absolute;
        top: 22px;
        left: 22px;
        color: $color_white;
        font-size: 20px;
        font-weight: $font_weight_bold;
        line-height: 36px;
    }
    :deep .select-box {
        position: absolute;
        top: 15px;
        right: 20px;
        border: none;
        width: 200px;
        .title {
            display: none;
        }
        &::before {
            border-top: 10px solid rgb(255, 255, 255);
        }
        select {
            height: 50px;
            width: calc(100% - 48px);
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 4px;
            font-size: 20px;
            color: $color_white;
            font-weight: $font_weight_bold;
        }
    }
}
</style>
