import { render, staticRenderFns } from "./ShopInfo.vue?vue&type=template&id=1c773218&scoped=true&"
import script from "./ShopInfo.vue?vue&type=script&lang=js&"
export * from "./ShopInfo.vue?vue&type=script&lang=js&"
import style0 from "./ShopInfo.vue?vue&type=style&index=0&id=1c773218&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c773218",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/atsuko/devel/usen/u-analytics/portal/u-webportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c773218')) {
      api.createRecord('1c773218', component.options)
    } else {
      api.reload('1c773218', component.options)
    }
    module.hot.accept("./ShopInfo.vue?vue&type=template&id=1c773218&scoped=true&", function () {
      api.rerender('1c773218', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ShopInfo.vue"
export default component.exports