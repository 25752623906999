<template>
    <ul class="list-event-calendar">
        <li v-for="calendar in calendarList" :key="calendar.date" :class="{ today: calendar.isToday }">
            <p class="today-label" v-if="calendar.isToday">今日</p>
            <p class="date-weather" @click="onDateClicked(getDateObj(calendar.date))">
                <span class="date"
                    ><span class="num">{{ zeroPadding(getDateObj(calendar.date).date) }}</span
                    >日<span class="day">（{{ getDateObj(calendar.date).day }}）</span></span
                ><img v-if="calendar.weather" :src="'/images/icon_weather_' + calendar.weather.weather + '.svg'" /><span
                    v-if="calendar.weather"
                    class="rain"
                    >{{ calendar.weather.rainy }}%</span
                ><span v-if="calendar.weather" class="temp">{{ calendar.weather.max_temperature }}℃ / {{ calendar.weather.min_temperature }}℃</span>
            </p>
            <div class="event">
                <p v-if="calendar.event" class="base" :style="{ height: 61 * calendar.event.period - 5 + 'px' }">
                    <span class="center">{{ calendar.event.title }}</span>
                </p>
            </div>
            <p class="sales-visitors">
                <span v-if="calendar.sales">
                    <!-- <span class="sales">{{ (calendar.sales ? calendar.sales.currentResult : null) | delimiter | salesStr }}</span> /  -->
                    <!-- <span class="visitors">{{ (calendar.sales ? calendar.sales.currentVisitos : null) | delimiter | visitorsStr }}</span> -->
                </span>
            </p>
            <p class="sales-visitors past">
                <span v-if="calendar.sales">
                    <!-- <span class="sales">{{ (calendar.sales ? calendar.sales.previousResult : null) | delimiter | salesStr }}</span> / -->
                    <!-- <span class="visitors">{{ (calendar.sales ? calendar.sales.previousVisitors : null) | delimiter | visitorsStr }}</span> -->
                </span>
            </p>
        </li>
    </ul>
</template>

<script>
import mixin from '@/mixin/index.js';

export default {
    name: 'EventCalendarList',

    props: {
        month: {
            type: String,
            required: true,
        },
    },

    computed: {
        calendarList: function () {
            return this.$store.getters.getCalendarList(this.month);
        },
    },

    filters: {
        salesStr: function (sales) {
            return sales == null ? '' : sales + '円';
        },
        visitorsStr: function (visitors) {
            return visitors == null ? '' : visitors + '人';
        },
    },

    methods: {
        onDateClicked: async function (dateObj) {
            this.$router.push('/timeline/' + dateObj.year + '/' + this.zeroPadding(dateObj.month) + '/' + this.zeroPadding(dateObj.date));
        },
    },

    mixins: [mixin],
};
</script>

<style scoped lang="scss">
.list-event-calendar {
    overflow: hidden;
    margin-left: 80px;
    border-top: 2px solid $color_black;
    margin-bottom: 40px;
    li {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        min-height: 60px;
        border-bottom: 1px solid rgb(235, 235, 235);
        .today-label {
            position: absolute;
            top: 13px;
            left: 8px;
            width: 50px;
            height: 32px;
            border: 1px solid $color_light_blue;
            border-radius: 3px;
            color: $color_light_blue;
            font-size: 16px;
            font-weight: $font_weight_bold;
            text-align: center;
            line-height: 32px;
        }
        .date-weather,
        .temp {
            flex-grow: 0;
            font-size: 20px;
            height: 50px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .date-weather {
            width: 400px;
            margin-left: 68px;
            cursor: pointer;
            .date,
            .day,
            .rain,
            .temp {
                font-size: 20px;
                line-height: 50px;
            }
            .rain {
                display: inline-block;
                width: 54px;
                flex-grow: 0;
                flex-shrink: 0;
            }
            .date {
                .num {
                    position: relative;
                    top: -3px;
                    font-family: $number_font_family;
                    font-size: 28px;
                    font-weight: $font_weight_number_bold;
                    margin-right: 6px;
                    vertical-align: middle;
                }
            }
            .day {
                margin-left: -4px;
            }
            img {
                width: 80px;
                height: 40px;
                margin-right: 8px;
                margin-top: 5px;
            }
        }
        .temp {
            line-height: 50px;
            margin-right: 30px;
        }
        .event {
            position: relative;
            flex-grow: 1;
            flex-shrink: 1;
            margin-right: 20px;
            margin-left: 20px;
            .base {
                @include flex_center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                background-color: rgba(114, 197, 255, 0.4);
                border-top: 2px solid rgb(71, 140, 189);
                border-bottom: 2px solid rgb(71, 140, 189);
                height: 56px;
                z-index: 1;
                .center {
                    display: block;
                    text-align: center;
                    font-size: 18px;
                    font-weight: $font_weight_bold;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
        p.sales-visitors,
        p.visitors {
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 16px;
            margin-left: 16px;
            margin-right: 16px;
            line-height: 30px;
            margin-top: 15px;
            margin-bottom: 15px;
            &.past {
                color: $color_blue;
            }
        }
        p.sales-visitors {
            width: 140px;
        }
        p.visitors {
            text-align: center;
            width: 70px;
        }
        &.today {
            .date,
            .day {
                color: $color_light_blue;
            }
        }
    }
}

@include sp-screen {
    .list-event-calendar {
        margin-left: 0;
        border-top: 1px solid rgb(216, 216, 216);
        margin-bottom: 0;
        margin-top: 30px;
        li {
            height: 80px;
            .today-label {
                display: none;
            }
            .temp {
                display: none;
            }
            .date-weather {
                width: 250px;
                margin-left: 40px;
                margin-top: 15px;
                margin-bottom: 15px;
                .date,
                .day {
                    font-size: 24px;
                }
                .rain {
                    display: none;
                }
                .date {
                    .num {
                        font-size: 34px;
                    }
                }
                img {
                    width: 100px;
                    height: 50px;
                    margin-top: 0;
                }
            }
            .event {
                margin-left: 0;
                .base {
                    background-color: rgba(255, 177, 0, 0.15);
                    border-top: 2px solid rgb(246, 181, 78);
                    border-bottom: 2px solid rgb(246, 181, 78);
                    border-left: 2px solid rgb(246, 181, 78);
                    border-right: 2px solid rgb(246, 181, 78);
                }
            }
            p.sales-visitors,
            p.visitors {
                display: none;
            }
            &.today {
                position: relative;
                @include calendar-current;
                &::before {
                    top: 32px;
                    left: 16px;
                }
            }
        }
    }
}
</style>
