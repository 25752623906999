<template>
  <div>
    <Header current="mypage"/>
    <div class="container">
      <div class="content-wrapper">
        <!-- <MypageMenu tag="shopinfo"/> -->
        <div class="content mypage">
          <h2>パスワードの再設定</h2>
          <dl>
            <dt>お客様コード</dt>
            <dd><input type="password" v-model="customerCode"/></dd>
          </dl>
          <dl class="dummy">
            <dt></dt>
            <dd></dd>
          </dl>
          <dl>
            <dt>パスワード</dt>
            <dd><input type="password" v-model="password"/></dd>
          </dl>
          <dl :class="{ error: isError }">
            <dt>パスワード確認</dt>
            <dd><input type="password" v-model="passwordConfirm"/></dd>
          </dl>
          <p v-if="isError" class="message">パスワードが一致しません。再度確認してください</p>
          <p class="btn-gradient"><a href="" @click.prevent="onClicked">設定する</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
// import MypageMenu from '@/components/menu/MypageMenu.vue';

export default {
  name: 'ResetPassword',

  components: {
    Header,
    // MypageMenu,
  },

  data() {
    return {
      customerCode: '',
      password: '',
      passwordConfirm: '',
      isError: false,
    };
  },

  methods: {
    async onClicked() {
      if (this.password !== this.passwordConfirm) {
        this.isError = true;
      } else {
        this.isError = false;
        const res = await this.$store.dispatch('postUserPassCode',{password: this.password});
        if(res){
          this.isPostError = false;
          this.$router.push({ path: '/mypage/shopinfo' } );
        }else {
          this.isPostError = true;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@include mypage-common;
</style>
