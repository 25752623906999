<template>
    <div>
        <Header current="mypage" />
        <div class="container">
            <div class="content-wrapper">
                <MypageMenu tag="transition" :payView="payView" />
                <div class="content">
                    <!-- <MypageHeader class="header-mypage">
            <ul>
              <li class="selected"><a href="">１ヶ月</a></li>
              <li><a href="">３ヶ月</a></li>
              <li><a href="">１年</a></li>
            </ul>
          </MypageHeader> -->
                    <p class="attention">
                        本画面での「入金予定」「取扱高推移」は、2023年9月30日ご入金分にて更新を停止いたします。<br />
                        以降は、Webブラウザ版「USEN MEMBERSマイページ」でのご確認をお願いいたします。<br />

                        <a href="https://umembers.usen.com/entry/?utm_source=tabupay&utm_medium=referral&utm_campaign=web" taeget="_umembers"
                            >『USEN MEMBERS』への新規登録はこちらから</a
                        ><br />
                        ※閲覧している環境によっては、リンク先ページが開かない場合がございます。<br />
                        その際は、スマートフォン・パソコンから「USEN MEMBERS」で検索をお願いいたします。
                    </p>
                    <p class="fig">
                        <span class="scale-label">円</span>
                        <StackedBarChart :height="300" :chartData="chartData" />
                    </p>
                    <ul class="table">
                        <li class="header">
                            <ul>
                                <li class="dummy"></li>
                                <li class="visa-master">VISA/MASTER</li>
                                <li class="jcb-other">JCB/その他</li>
                                <li class="epay">電子マネー</li>
                                <li class="upay-jpn">Uペイ QR(国内)</li>
                                <li class="upay-global">Uペイ QR(中国)</li>
                            </ul>
                        </li>
                        <li v-show="getPaymentTransaction(date)" v-for="date in dates" :key="getDateStr(date)">
                            <ul>
                                <li>{{ getDateStr(date) }}</li>
                                <li v-for="payment in payments" :key="payment.title">{{ getPaymentValue(payment, date) | delimiter }}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import mixin from '@/mixin/index.js';
import setparam from '@/mixin/setparam.js';
import mypageapicall from '@/mixin/mypageapicall.js';
import Header from '@/components/Header.vue';
import MypageMenu from '@/components/menu/MypageMenu.vue';
// import MypageHeader from '@/components/mypage/MypageHeader.vue';
import StackedBarChart from '@/components/analytics/StackedBarChart.vue';

export default {
    name: 'UPayTransition',

    components: {
        Header,
        MypageMenu,
        // MypageHeader,
        StackedBarChart,
    },

    data() {
        return {
            from: '20200901',
            to: '20220901',
            payments: [
                {
                    title: 'VISA/MASTER',
                    color: '#8abe62',
                },
                {
                    title: 'JCB/その他',
                    color: '#7ad3d7',
                },
                {
                    title: '電子マネー',
                    color: '#7798d2',
                },
                {
                    title: 'Uペイ QR(国内)',
                    color: '#9f75cf',
                },
                {
                    title: 'Uペイ QR(中国)',
                    color: '#dbb26f',
                },
            ],
        };
    },

    computed: {
        auth() {
            return this.$store.state.auth;
        },

        dates() {
            const fromDate = this.getDate(this.from);
            const toDate = this.getDate(this.to);
            const date = fromDate;
            const dates = [];

            while (date.getTime() < toDate.getTime()) {
                dates.push(new Date(date));
                date.setMonth(date.getMonth() + 1);
            }
            return dates;
        },

        labels() {
            return this.dates.map((date) => this.getDateStr(date));
        },

        datasets() {
            const datasets = [];

            this.payments.forEach((payment) => {
                const data = {
                    label: payment.title,
                    borderWidth: 1,
                    backgroundColor: payment.color,
                    borderColor: payment.color,
                    data: [],
                };
                this.dates.forEach((date) => {
                    const value = this.getPaymentValue(payment, date);
                    data.data.push(value);
                });
                datasets.push(data);
            });
            return datasets;
        },

        chartData() {
            return {
                labels: this.labels,
                datasets: this.datasets,
            };
        },
    },

    methods: {
        getDateStr(date) {
            const year = date.getFullYear();
            const month = this.zeroPadding(date.getMonth() + 1);
            return year + '年' + month + '月';
        },

        getPaymentTransaction(date) {
            return this.$store.getters.getPaymentTransaction(this.getMonthStr(date));
        },

        getPaymentValue(payment, date) {
            const transaction = this.getPaymentTransaction(date);
            if (transaction) {
                return transaction[payment.title];
            } else {
                return 0;
            }
        },

        async fetchPaymentTransaction() {
            await this.$store.dispatch('fetchPaymentTransaction', { unisCustomerCode: this.unisCustomerCode, from: this.from, to: this.to });
        },
    },

    async created() {
        if (this.auth.user == null) {
            return;
        }
        await this.fetchPaymentTransaction();
    },

    watch: {
        auth: {
            async handler() {
                if (this.auth.user != null) {
                    await this.fetchPaymentTransaction();
                }
            },
            deep: true,
        },
    },

    mixins: [mixin, setparam, mypageapicall],
};
</script>

<style scoped lang="scss">
.header-mypage {
    :deep(.btn-back) {
        display: none;
    }
}
.attention {
    margin-left: 50px;
    color: red;
    margin-bottom: 12px;
}
.fig {
    position: relative;
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 40px;
    border: 1px solid rgb(150, 150, 150);
    padding-top: 50px;
    padding-bottom: 50px;
    img {
        display: block;
        margin: 10px;
    }
    .scale-label {
        @include scale-label;
    }
}
.table {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 40px;
    > li {
        border-top: 1px solid rgb(216, 216, 216);
        &.header {
            background-color: rgb(240, 240, 240);
            ul {
                li {
                    position: relative;
                    font-weight: bold;
                    &::before {
                        content: '';
                        display: block;
                        width: 8px;
                        height: 30px;
                        position: absolute;
                        top: 5px;
                        left: 0;
                    }
                    &.visa-master {
                        &::before {
                            background-color: rgb(138, 190, 98);
                        }
                    }
                    &.jcb-other {
                        &::before {
                            background-color: rgb(122, 211, 215);
                        }
                    }
                    &.epay {
                        &::before {
                            background-color: rgb(119, 152, 210);
                        }
                    }
                    &.upay-jpn {
                        &::before {
                            background-color: rgb(159, 117, 207);
                        }
                    }
                    &.upay-global {
                        &::before {
                            background-color: rgb(219, 178, 111);
                        }
                    }
                }
            }
        }
        ul {
            @include flex_between;
            li {
                height: 40px;
                font-size: 16px;
                color: $color_black;
                line-height: 40px;
                width: 160px;
                text-indent: 16px;
            }
        }
    }
}
</style>
