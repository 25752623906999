var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", { attrs: { current: "top" } }),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "content-top" },
          [
            _c(
              "h2",
              [
                _c("MyDate", [
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(_vm.today.year)),
                  ]),
                  _vm._v("年"),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(_vm.zeroPadding(_vm.today.month))),
                  ]),
                  _vm._v("月"),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(_vm.zeroPadding(_vm.today.date))),
                  ]),
                  _vm._v("日"),
                ]),
                _c("span", { staticClass: "location" }, [
                  _vm._v(
                    _vm._s(_vm.userData ? _vm.userData.customer_area_name : "")
                  ),
                ]),
                _vm._m(0),
              ],
              1
            ),
            _c("Weather"),
            _c("News"),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "detail" }, [
      _c("a", { attrs: { href: "" } }, [_vm._v("詳細を見る")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }