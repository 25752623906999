<template>
  <div class="menu">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Menu'
};
</script>

<style scoped lang="scss">
.menu {
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.75);
  .menu-title {
    color: $color_light_gray;
    font-size: 16px;
    padding-left: 10px;
    padding-bottom: 6px;
    margin-top: 56px;
    @include ellipsis;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  ul {
    li {
      overflow: hidden;
      height: 80px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      p {
        width: 190px;
        height: 70px;
        border-radius: 6px;
        margin-left: 5px;
        margin-top: 5px;
        @include ellipsis;
        a {
          display: block;
          color: $color_dark_gray;
          font-size: 14px;
          font-weight: $font_weight_bold;
          line-height: 70px;
          text-indent: 14px;
        }
      }
      &.selected {
        p {
          background-color: $color_white;
          box-shadow: $box_shadow_weak;
          a {
            color: $color_light_blue;
          }
        }
      }
    }
  }
}
</style>
