var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "shop-info" }, [
    _vm.shop.useUregi
      ? _c("li", [
          _c("h3", [_vm._v("昨日の売上")]),
          _c("p", { staticClass: "value" }, [
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.result.yesterday.sales)),
            ]),
            _vm._v(" 万円"),
          ]),
          _vm._m(0),
        ])
      : _c("li", [
          _c("h3", [_vm._v("一昨日の来客数")]),
          _c("p", { staticClass: "value" }, [
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.result.dayBeforeYesterday.visitors)),
            ]),
            _vm._v(" 人"),
          ]),
          _vm._m(1),
        ]),
    _c("li", [
      _c("h3", [_vm._v("昨日の来客数")]),
      _c("p", { staticClass: "value" }, [
        _c("span", { staticClass: "num" }, [
          _vm._v(_vm._s(_vm.result.yesterday.visitors)),
        ]),
        _vm._v(" 人"),
      ]),
      _vm._m(2),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "fig" }, [
      _c("img", { attrs: { src: "/images/shopinfo_sample1.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "fig" }, [
      _c("img", { attrs: { src: "/images/shopinfo_sample2.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "fig" }, [
      _c("img", { attrs: { src: "/images/shopinfo_sample2.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }