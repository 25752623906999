export default {
  computed: {
    unisCustomerCode() {
      return this.$store.getters.getUnisCustomerCode;
    }
  },

  // mounted() {
  //   // console.log('setParams mounted.');
  //   window.setParams = this.setParams
  // },

  // methods: {
  //   async setParams(data){
  //     // console.log('setParams.')
  //     await this.$store.dispatch('fetchUser', {'unisCustomerCode': data});
  //   },
  // }
}
