var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", { attrs: { current: "analytics" } }),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "content-wrapper" },
          [
            _c("AnalyticsMenu", {
              attrs: {
                tag: "seats",
                largeBizCd: _vm.largeBizCd,
                visitorView: _vm.visitorView,
              },
            }),
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "content-shop-analytics" },
                [
                  _c("ShopSearchHeader", {
                    attrs: {
                      areas: _vm.areaCode,
                      genres: _vm.bizCode,
                      pref: _vm.pref,
                      city: _vm.city,
                      large: _vm.largeBizCd,
                      middle: _vm.middleBizCd,
                      small: _vm.smallBizCd,
                    },
                    on: {
                      "show-info": function ($event) {
                        _vm.isInfoDialogShown = true
                      },
                    },
                  }),
                  _c("ShopAnalyticsAxis", {
                    attrs: {
                      mode: "MODE_COUNT",
                      shopCount: _vm.storeTotal(),
                      created: { year: 2020, month: 10 },
                    },
                  }),
                  _c("ShopAnalyticsGraph", {
                    attrs: {
                      title: "座席数別店舗比率",
                      labels: _vm.restaurantSeats.labels
                        ? _vm.restaurantSeats.labels
                        : [],
                      "data-collection": _vm.restaurantSeats.data
                        ? _vm.restaurantSeats.data
                        : [],
                    },
                  }),
                  _vm.isInfoDialogShown
                    ? _c("InfoDialog", {
                        attrs: { kind: _vm.kind },
                        on: {
                          close: function ($event) {
                            _vm.isInfoDialogShown = false
                          },
                        },
                      })
                    : _vm._e(),
                  _c("p", { staticClass: "notice" }, [
                    _vm._v(
                      " 公開情報を基に当社が独自で行った分析結果になりますので参考値としてご利用ください。 "
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }