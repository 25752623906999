<template>
    <div></div>
</template>

<script>
// @ is an alias to /src
import setparam from '@/mixin/setparam.js';
import analyticssubmenu from '@/mixin/analyticssubmenu.js';

export default {
    name: 'AnalyticsHandler.',

    computed: {
        auth() {
            return this.$store.state.auth;
        },
    },
    watch: {
        auth: {
            async handler() {
                if (this.auth.user != null) {
                    await this.$store.dispatch('fetchVisitorsDataExists', {
                        unisCustomerCode: this.unisCustomerCode,
                    });
                    console.log(this.unisCustomerCode);
                    console.log(this.$store.state.visitorsExists);
                    if (this.$store.state.visitorsExists) {
                        this.$router.push({ path: '/analytics/myshop/visitors-and-sales/day' });
                    } else {
                        this.$router.push({ path: '/analytics/shops/all' });//ステージング
                        // this.$router.push({ path: '/analytics/promo' });//本番
                    }
                }
            },
            deep: true,
        },
    },

    async created() {
        console.log(this.auth.user.unis_customer_cd);

        if (this.auth.user != null) {
            await this.$store.dispatch('fetchVisitorsDataExists', {
                unisCustomerCode: this.unisCustomerCode,
            });
            await console.log(this.$store.state.visitorsExists);
            if (this.$store.state.visitorsExists) {
                this.$router.push({ path: '/analytics/myshop/visitors-and-sales/day' });
            } else {
                this.$router.push({ path: '/analytics/shops/all' });//ステージング
                // this.$router.push({ path: '/analytics/promo' });//本番
            }
        }
    },
    mixins: [setparam, analyticssubmenu],
};
</script>
