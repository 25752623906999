<template>
  <div class="analytics-tab">
    <ul>
      <li v-for="(tabItem, index) in tabItems" :key="tabItem.title" :class="{ selected: index === currentIndex }" @click="$emit('select', index)" >
        {{ tabItem.title }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsTab',

  props: {
    tabItems: {
      type: Array,
      required: true
    },
    currentIndex: {
      type: Number,
      required: true
    },
  },
};
</script>

<style scoped lang="scss">
.analytics-tab {
  ul {
    @include flex_center;
    align-items: flex-start;
    border-bottom: 2px solid $color_blue;
    height: 48px;
    padding-left: 18px;
    padding-right: 18px;
    margin-bottom: 8px;
    li {
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
      height: 46px;
      line-height: 46px;
      color: rgb(84, 84, 84);
      font-size: 16px;
      font-weight: $font_weight_bold;
      text-align: center;
      background-color: rgb(240, 240, 240);
      border-top: 2px solid rgb(240, 240, 240);
      border-left: 2px solid rgb(240, 240, 240);
      border-right: 2px solid rgb(240, 240, 240);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      margin-left: 6px;
      margin-right: 6px;
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
      &.selected {
        height: 48px;
        color: $color_blue;
        background-color: $color_white;
        border-top: 2px solid $color_blue;
        border-left: 2px solid $color_blue;
        border-right: 2px solid $color_blue;
      }
    }
  }
}
</style>
