var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", { attrs: { current: "mypage" } }),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "content-wrapper" }, [
          _c("div", { staticClass: "content mypage" }, [
            _c("h2", [_vm._v("UNIS顧客IDを入力してください")]),
            _c("dl", [
              _c("dt", [_vm._v("UNIS顧客ID")]),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customerCode,
                      expression: "customerCode",
                    },
                  ],
                  attrs: { type: "unis_customer_cd" },
                  domProps: { value: _vm.customerCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.customerCode = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm.isError
              ? _c("p", { staticClass: "message" }, [
                  _vm._v("UNIS顧客IDが存在しません。再度確認してください"),
                ])
              : _vm._e(),
            _c("p", { staticClass: "btn-gradient" }, [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onClicked.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("再設定する")]
              ),
            ]),
            _vm._m(0),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "message" }, [
      _vm._v(" UNIS顧客IDが不明な場合は、"),
      _c("br"),
      _vm._v("以下からお問い合わせください。"),
      _c("br"),
      _c(
        "a",
        {
          attrs: {
            href: "https://usen.com/contact/service-uic/input.php",
            target: "_blank",
          },
        },
        [_vm._v("USENインフォメーションセンターお問い合わせフォーム")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }