var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_c("Header", { attrs: { current: "analytics" } }), _vm._m(0)],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "content-wrapper" }, [
        _c("img", { attrs: { src: "/images/bk_eyespromo_left.png" } }),
        _c("div", { staticClass: "promo" }, [
          _c("img", { attrs: { src: "/images/bk_eyespromo_right.jpg" } }),
          _c("a", { attrs: { href: "https://iot.usen.com/u-eyes/" } }, [
            _c("img", { attrs: { src: "/images/btn_eyes.png" } }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }