<template>
<div>
    <Header current="analytics"/>
    <div class="container">
        <div class="content-wrapper">
            <img src="/images/bk_eyespromo_left.png" />
            <div class="promo">
                <img src="/images/bk_eyespromo_right.jpg" />
                <a href="https://iot.usen.com/u-eyes/"><img src="/images/btn_eyes.png" /></a>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import mixin from '@/mixin/index.js';
import setparam from '@/mixin/setparam.js';
import shopapicall from '@/mixin/shopapicall.js';
import analyticssubmenu from '@/mixin/analyticssubmenu.js';
import Header from '@/components/Header.vue';

export default {
    name: 'ShopPromo',
    components: {
        Header
    },
    mixins: [mixin, setparam, shopapicall,analyticssubmenu]
}
</script>
<style lang="scss">
.promo {
  position: relative;
}
.promo a {
    position: absolute;
    top: 85%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    /*以下装飾*/
    margin:0;/*余計な隙間を除く*/
}
.promo img {
  width: 100%;
}
</style>
