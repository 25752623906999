<template>
    <Dialog title="期間設定" @back="$emit('cancel')" @close="$emit('cancel')">
        <template #content>
            <div class="calendar-wrapper">
                <div>
                    <p class="title">FROM</p>
                    <v-date-picker class="calendar" v-model="fromDate" is-inline />
                </div>
                <div>
                    <p class="title">TO</p>
                    <v-date-picker class="calendar" v-model="toDate" is-inline />
                </div>
            </div>
        </template>
        <template #footer>
            <div class="btn-wrapper">
                <p class="btn-white"><a href="" @click.prevent="$emit('cancel')">キャンセル</a></p>
                <p class="btn-gradient"><a href="" @click.prevent="$emit('next', { fromDate, toDate })">次へ</a></p>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from '@/components/dialog/Dialog.vue';

export default {
    name: 'DatePickerDialog',

    components: {
        Dialog,
    },

    data() {
        return {
            fromDate: new Date(),
            toDate: new Date(),
        };
    },
};
</script>

<style scoped lang="scss">
.dialog {
    :deep(.dialog-base) {
        width: 800px;
    }
}
.calendar-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding-top: 40px;
    padding-bottom: 40px;
    .title {
        font-size: 18px;
        font-weight: $font_weight_bold;
        margin-bottom: 20px;
        text-align: center;
    }
}
.btn-wrapper {
    @include flex_center;
    .btn-gradient,
    .btn-white {
        @include btn-common;
        margin-top: 17px;
        margin-bottom: 0;
        margin-left: 15px;
        margin-right: 15px;
        width: 140px;
    }
    .btn-gradient {
        @include btn-gradient;
    }
    .btn-white {
        @include btn-white;
    }
}
</style>
