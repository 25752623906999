<template>
  <div>
    <Header current="top"/>
    <div class="container">
      <div class="content-top">
        <h2>
          <!-- 環境：{{env}} -->
          <MyDate><span class="num">{{ today.year }}</span>年<span class="num">{{ zeroPadding(today.month) }}</span>月<span class="num">{{ zeroPadding(today.date) }}</span>日</MyDate>
          <span class="location">{{ userData ? userData.customer_area_name : '' }}</span>
          <span class="detail"><a href="">詳細を見る</a></span>
        </h2>
        <Weather/>
        <News/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import MyDate from '@/components/Date.vue';
import Weather from '@/components/Weather.vue';
import News from '@/components/News.vue';
import mixin from '@/mixin/index.js';
import setparam from '@/mixin/setparam.js';
import apicall from '@/mixin/apicall.js';

export default {
  name: 'Top',

  components: {
    Header,
    MyDate,
    Weather,
    News
  },

  computed: {
    env: function(){
      return process.env.NODE_ENV;
    },
    today: function() {
      return this.getDateObj(new Date());
    },
    userData: function() {
      return this.$store.getters.getUserData;
    },
  },

  mixins: [mixin, setparam, apicall]
};
</script>

<style scoped lang="scss">
.content-top {
  position: relative;
  z-index: 1;
  h2 {
    color: $color_white;
    font-size: 20px;
    position: relative;
    text-shadow: $text_shadow;
    margin-top: 24px;
    margin-left: 24px;
    .location {
      font-size: 18px;
      font-weight: $font_weight_bold;
      margin-left: 14px;
    }
    .detail {
      display: none;
      position: absolute;
      bottom: 0;
      right: 10px;
      a {
        color: $color_white;
        font-size: 18px;
        font-weight: $font_weight_bold;
      }
    }
  }
  .list-news {
    padding-top: 40px;
  }
}

@include sp-screen {
  .content-top {
    h2 {
      .location {
        display: block;
        margin-left: 0;
      }
      .detail {
        display: block;
      }
    }
  }
}
</style>
