<script>
import Chart from 'chart.js';
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
    mounted() {
        this.addPlugin({
            id: 'draw-values',
            beforeUpdate: function (chart) {
                const ctx = chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                gradient.addColorStop(0, 'rgb(93, 107, 151)');
                gradient.addColorStop(1, 'rgb(136, 158, 226)');
                chart.data.datasets.forEach(function (dataset) {
                    dataset.backgroundColor = gradient;
                });
            },
            afterDatasetsDraw: function (chart) {
                const ctx = chart.ctx;

                chart.data.datasets.forEach(function (dataset, index) {
                    var meta = chart.getDatasetMeta(index);
                    if (!meta.hidden) {
                        meta.data.forEach(function (element, i) {
                            var dataString = `${Number(dataset.data[i]).toLocaleString()}店`;
                            const fontSize = 14;
                            const fontStyle = 'normal';
                            const fontFamily = 'san-serif';
                            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
                            ctx.textAlign = 'center'; // 文字位置 start, end, left, right, center
                            ctx.textBaseline = 'top'; // 文字高 middle, top, bottom
                            const position = element.tooltipPosition();
                            const padding = 10;
                            ctx.fillText(dataString, position.x, position.y - fontSize / 2 - padding);
                        });
                    }
                });
            },
        });
        this.renderChart(this.chartData, this.options);
    },
};
</script>
