<template>
  <div class="weather-calendar-daily">
    <h2>{{ dateStr }}</h2>
    <div class="wrapper">
      <div class="left">
        <p class="btn-prev" :class="{ disabled: !canPrev() }" @click="prev"><img src="/images/btn_calendar_prev.svg"></p>
      </div>
      <ul v-if="mode === modeType.HOUR">
        <li v-for="n in displayNum" :key="n">
         <WeatherCalendarListItem :weather="getWeather(startHour + n - 1)" :mode="mode" :hour="startHour + n - 1" />
        </li>
      </ul>
      <ul v-if="mode === modeType.THREE_HOURS">
        <li v-for="n in [0, 3, 6, 9, 12, 15, 18, 21]" :key="n">
         <WeatherCalendarListItem :weather="getWeather(n)" :mode="mode" :hour="n" />
        </li>
      </ul>
      <div class="right">
        <p class="btn-next" :class="{ disabled: !canNext() }" @click="next"><img src="/images/btn_calendar_next.svg"></p>
      </div>
    </div>
  </div>
</template>

<script>
import WeatherCalendarListItem from '@/components/WeatherCalendarListItem.vue';
import mixin from '@/mixin/index.js';

export default {
  name: 'WeatherCalendarDaily',

  components: {
    WeatherCalendarListItem,
  },

  props: {
    date: {
      type: String,
      required: true
    },
    displayNum: {
      type: Number,
      required: true
    },
    initialStartHour: {
      type: Number,
      required: true
    },
    mode: {
      type: String,
      default: 'hour'
    },
  },

  data() {
    return {
      startHour: 0,
    };
  },

  computed: {
    modeType: function() {
      return this.$store.state.analyticsModeType;
    },
    dateStr: function() {
      const dateObj = this.getDateObj(this.date);
      return dateObj.year + '/' + dateObj.month + '/' + dateObj.date + ' ' + dateObj.day + '曜日';
    },
    weatherList: function() {
      return this.$store.getters.getWeatherList('hour', this.date);
    },
  },

  methods: {
    canPrev: function() {
      return this.startHour > 0;
    },
    canNext: function() {
      return this.startHour + this.displayNum < 23;
    },
    prev: function() {
      if (!this.canPrev()) {
        return
      }
      this.startHour--;
    },
    next: function() {
      if (!this.canNext()) {
        return
      }
      this.startHour++;
    },
    getWeather: function(n) {
      const date = this.date + this.zeroPadding(n);
      const weather = this.weatherList.find((weather) => weather.date === date);
      return weather;
    },
  },

  created() {
    this.startHour = this.initialStartHour;
  },

  mixins: [mixin]
}
</script>

<style scoped lang="scss">
.weather-calendar-daily {
  background-color: $color_white;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  padding-top: 10px;
  h2 {
    color: $color_black;
    font-size: 24px;
    font-weight: $font_weight_bold;
    line-height: 36px;
    text-align: center;
    margin-bottom: 10px;
  }
  .wrapper {
    @include flex_center;
    ul {
      @include flex_between;
      width: 1080px;
      margin-bottom: 14px;
      li {
        width: 120px;
      }
    }
    .left, .right {
      position: relative;
      width: 80px;
      height: 260px;
      flex-grow: 0;
      flex-shrink: 0;
      .btn-prev, .btn-next {
        position: absolute;
        top: 80px;
        width: 15px;
        height: 30px;
        cursor: pointer;
        &.btn-prev {
          left: 16px;
        }
        &.btn-next {
          right: 16px;
        }
        &.disabled {
          opacity: 0.3;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
