var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-analytics" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c("SelectBox", {
        attrs: {
          title: "期間選択：",
          options: _vm.selectBoxOptions,
          value: _vm.mode,
        },
        on: { update: _vm.onUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }