<script>
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    mounted() {
        this.addPlugin({
            id: 'stacked-graph',
            beforeUpdate: function (chart) {
                const ctx = chart.ctx;
                const maleGradient = ctx.createLinearGradient(0, 0, 0, 300);
                maleGradient.addColorStop(0, 'rgb(75, 181, 227)');
                maleGradient.addColorStop(1, 'rgb(125, 219, 244)');
                const femaleGradient = ctx.createLinearGradient(0, 0, 0, 300);
                femaleGradient.addColorStop(0, 'rgb(242, 152, 157)');
                femaleGradient.addColorStop(1, 'rgb(254, 198, 202)');
                const newCustomerGradient = ctx.createLinearGradient(0, 0, 0, 300);
                newCustomerGradient.addColorStop(0, 'rgb(133, 119, 178)');
                newCustomerGradient.addColorStop(1, 'rgb(168, 139, 244)');
                const repeaterGradient = ctx.createLinearGradient(0, 0, 0, 300);
                repeaterGradient.addColorStop(0, 'rgb(127, 198, 192)');
                repeaterGradient.addColorStop(1, 'rgb(172, 238, 234)');

                chart.data.datasets.forEach(function (dataset) {
                    switch (dataset.backgroundColor) {
                        case '#4BB5E3':
                            dataset.backgroundColor = maleGradient;
                            break;
                        case '#F2989D':
                            dataset.backgroundColor = femaleGradient;
                            break;
                        case '#8577B2':
                            dataset.backgroundColor = newCustomerGradient;
                            break;
                        case '#7FC6C0':
                            dataset.backgroundColor = repeaterGradient;
                            break;
                    }
                });
            },
        });
        this.renderChart(this.chartData, {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            scales: {
                xAxes: [
                    {
                        barPercentage: 0.45,
                        stacked: true,
                    },
                ],
                yAxes: [
                    {
                        id: 'y-axis-1',
                        stacked: true,
                        ticks: {
                            min: 0,
                            // max: 10,
                            userCallback: function (label) {
                                if (Math.floor(label) === label) {
                                    return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                }
                            },
                        },
                        scaleLabel: {
                            display: false,
                        },
                    },
                    {
                        id: 'y-axis-2',
                        position: 'right',
                        ticks: {
                            fontColor: 'black',
                            beginAtZero: true,
                            max: 100,
                            stepSize: 20,
                            callback: function (val) {
                                return val + '%';
                            },
                        },
                    },
                ],
            },
            layout: {
                padding: {
                    left: 56,
                    right: 70,
                    top: 0,
                    bottom: 0,
                },
            },
            tooltips: {
                titleFontSize: 17,
                titleSpacing: 10,
                titleMarginBottom: 10,
                bodyFontSize: 17,
                bodySpacing: 10,
                footerFontSize: 17,
                footerSpacing: 10,
                footerMarginTop: 10,
                xPadding: 10,
                yPadding: 10,
                caretPadding: 12,
            },
        });
    },
};
</script>
