<template>
    <div class="header-shop-search">
        <img class="info-view" src="/images/icon_info.png"  @click="onIconClicked"/>
        <div class="select-wrapper">
            <p class="title">業種</p>
            <select name="genre" class="large" v-model="genreLargeValue" disabled>
                <option v-for="genre in genres" :key="genre.id" :value="genre.id">{{ genre.name }}</option>
            </select>
            <select name="genre" class="middle" v-model="genreMiddleValue">
                <option v-for="genre in selectedGenreLarge ? selectedGenreLarge.children : []" :key="genre.id" :value="genre.id">{{ genre.name }}</option>
            </select>
            <select v-if="!hideSmall" name="genre" class="small" v-model="genreSmallValue">
                <option v-for="genre in selectedGenreMiddle ? selectedGenreMiddle.children : []" :key="genre.id" :value="genre.id">{{ genre.name }}</option>
            </select>

        </div>
        <div class="select-wrapper">
            <p class="title">エリア</p>
            <select name="genre" class="large" v-model="areaLargeValue">
                <option v-for="area in areas" :key="area.id" :value="area.id">{{ area.name }}</option>
            </select>
            <select name="genre" class="middle" v-model="areaSmallValue">
                <option v-for="area in selectedAreaLarge ? selectedAreaLarge.contains : []" :key="area.id" :value="area.id">{{ area.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShopSearchHeader',

    props: ['genres', 'large', 'middle', 'small', 'areas', 'pref', 'city', 'hideSmall'],

    data: function () {
        return {
            genreLargeValue: this.large,
            genreMiddleValue: this.middle,
            genreSmallValue: this.small,
            areaLargeValue: this.pref,
            areaSmallValue: this.city,
        };
    },

    computed: {
        selectedGenreLarge: function () {
            return this.genres.find((genre) => genre.id === this.genreLargeValue);
        },
        selectedGenreMiddle: function () {
            if (this.selectedGenreLarge == null) {
                return null;
            }
            return this.selectedGenreLarge.children.find((genre) => genre.id === this.genreMiddleValue);
        },
        selectedGenreSmall: function () {
            if (this.selectedGenreMiddle == null) {
                return null;
            }
            return this.selectedGenreMiddle.children.find((genre) => genre.id === this.genreSmallValue);
        },
        selectedAreaLarge: function () {
            return this.areas.find((area) => area.id === this.areaLargeValue);
        },
        selectedAreaSmall: function () {
            if (this.selectedAreaLarge == null) {
                return null;
            }
            return this.selectedAreaLarge.contains.find((area) => area.id === this.areaSmallValue);
        },
    },

    methods: {
        saveShopSearchOptions() {
            if (!this.genreLargeValue
                || !this.genreMiddleValue
                || !this.genreSmallValue
                || !this.areaLargeValue
                || !this.areaSmallValue) {
                return;
            }
            this.$store.commit('setShopSearchOptions', {
                options: {
                    largeBizCd: this.genreLargeValue,
                    middleBizCd: this.genreMiddleValue,
                    smallBizCd: this.genreSmallValue,
                    pref: this.areaLargeValue,
                    city: this.areaSmallValue,
                }
            })
        },
        onIconClicked: function() {
            this.$emit('show-info');
        },
    },

    watch: {
        large: function() {
            this.genreLargeValue = this.large;
        },
        middle: function() {
            this.genreMiddleValue = this.middle;
        },
        small: function() {
            this.genreSmallValue = this.small;
        },
        pref: function() {
            this.areaLargeValue = this.pref;
        },
        city: function() {
            this.areaSmallValue = this.city;
        },
        genreLargeValue: function () {
            if (this.selectedGenreLarge) {
                this.genreMiddleValue = this.selectedGenreLarge.children[0].id;
            }
        },
        genreMiddleValue: function () {
            if (this.selectedGenreMiddle) {
                this.genreSmallValue = this.selectedGenreMiddle.children[0].id;
            }
        },
        genreSmallValue: function () {
            this.$parent.largeBizCd = this.genreLargeValue;
            this.$parent.middleBizCd = this.genreMiddleValue;
            this.$parent.smallBizCd = this.genreSmallValue;
            this.saveShopSearchOptions();
        },
        areaLargeValue: function () {
            if (this.selectedAreaLarge) {
                this.areaSmallValue = this.selectedAreaLarge.contains[0].id;
            }
        },
        areaSmallValue: function () {
            this.$parent.pref = this.areaLargeValue;
            this.$parent.city = this.areaSmallValue;
            this.saveShopSearchOptions();
        },
    },
};
</script>

<style scoped lang="scss">
.header-shop-search {
    margin-top: 24px;
    margin-bottom: 24px;
    .select-wrapper {
        margin-bottom: 10px;
        .title {
            width: 70px;
            display: inline-block;
            color: $color_dark_gray;
            font-size: 16px;
            line-height: 45px;
        }
        select {
            @include ellipsis;
            display: inline-block;
            height: 45px;
            min-width: 140px;
            max-width: 400px;
            background-color: rgb(238, 238, 238);
            border-radius: 4px;
            color: $color_black;
            font-size: 16px;
            font-weight: $font_weight_bold;
            line-height: 36px;
            border: none;
            padding-left: 6px;
            padding-right: 6px;
            margin-right: 10px;
            option {
                height: 36px;
            }
            &:disabled {
                opacity: 0.6;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 4px;
            }
            &.large {
                max-width: 140px;
            }
            &.middle, &.small {
                width: 340px;
            }
        }
    }
}
.info-view{
    width:20px;
    float:right;
}
</style>
