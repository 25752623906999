<template>
    <div class="dialog">
        <div class="dialog-base">
            <div class="dialog-header">
                <p class="title">{{ title }}</p>
                <p class="btn-back"><a href="/" @click.prevent="$emit('back')">戻る</a></p>
                <p class="btn-close" @click.prevent="$emit('close')"></p>
            </div>
            <div class="dialog-content">
                <slot name="content" />
            </div>
        <div class="dialog-footer">
            <slot name="footer"/>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dialog',

    props: {
        title: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped lang="scss">
.dialog {
    @include flex_center;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .dialog-base {
        background-color: $color_white;
        border-radius: 10px;
        box-shadow: $box_shadow;
        width: 1000px;
        .dialog-header {
            overflow: hidden;
            position: relative;
            height: 80px;
            border-bottom: 1px solid rgb(220, 220, 220);
            .title {
                text-align: center;
                width: 490px;
                margin-left: auto;
                margin-right: auto;
                color: $color_black;
                font-size: 20px;
                font-weight: $font_weight_bold;
                line-height: 1em;
                margin-top: 30px;
            }
            .btn-back {
                @include btn-back;
                position: absolute;
                top: 30px;
                left: 44px;
                margin-left: 0;
                margin-top: 0;
            }
            .btn-close {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 40px;
                height: 40px;
                background-image: url('/images/icon_close.svg');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                &:active {
                    opacity: 0.5;
                }
            }
        }
        .dialog-footer {
            height: 80px;
            border-top: 1px solid rgb(220, 220, 220);
        }
    }
}
</style>
