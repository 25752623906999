<template>
    <Dialog title="表示間隔" @back="$emit('back')" @close="$emit('cancel')">
        <template #content>
            <div class="calendar-wrapper">
                <div>
                    <p class="title">FROM</p>
                    <p class="date">{{ getFromDateStr(fromDate) }}</p>
                </div>
                <div class="arrow">
                    <img src="/images/from_to.svg" />
                </div>
                <div>
                    <p class="title">TO</p>
                    <p class="date">{{ getToDateStr(toDate) }}</p>
                </div>
            </div>
            <div class="select">
                <p>表示の間隔を設定してください。</p>
                <ul>
                    <li v-if="!hideDayMode">
                        <label><input type="radio" name="mode" value="day" v-model="mode" />日ごと</label>
                    </li>
                    <li>
                        <label><input type="radio" name="mode" value="week" v-model="mode" />週ごと</label>
                    </li>
                    <li>
                        <label><input type="radio" name="mode" value="month" v-model="mode" />月ごと</label>
                    </li>
                    <li>
                        <label><input type="radio" name="mode" value="three" v-model="mode" />３ヶ月ごと</label>
                    </li>
                </ul>
            </div>
        </template>
        <template #footer>
            <div class="btn-wrapper">
                <p class="btn-white"><a href="" @click.prevent="$emit('cancel')">キャンセル</a></p>
                <p class="btn-gradient"><a href="" @click.prevent="onDoneButtonClicked">確定</a></p>
            </div>
        </template>
    </Dialog>
</template>

<script>
import mixin from '@/mixin/index.js';
import Dialog from '@/components/dialog/Dialog.vue';

export default {
    name: 'DatePickerDialog',

    components: {
        Dialog,
    },

    props: {
        hideDayMode: {
            type: Boolean,
            default: false,
        },
        fromDate: {
            type: Date,
            required: true,
        },
        toDate: {
            type: Date,
            required: true,
        },
    },

    data() {
        return {
            mode: 'day',
        };
    },

    computed: {
        modeType: function () {
            return this.$store.state.analyticsModeType;
        },
    },

    methods: {
        getFromDate(date) {
            switch (this.mode) {
                case this.modeType.DAY:
                    return date;
                case this.modeType.WEEK:
                    return this.getFirstDateOfWeek(date);
                case this.modeType.MONTH:
                    return new Date(date.getFullYear(), date.getMonth(), 1);
                case this.modeType.THREE:
                    return this.getFirstMonthOfThree(date);
                default:
                    return '';
            }
        },
        getToDate(date) {
            switch (this.mode) {
                case this.modeType.DAY:
                    return date;
                case this.modeType.WEEK:
                    return this.getLastDateOfWeek(date);
                case this.modeType.MONTH:
                    return new Date(date.getFullYear(), date.getMonth(), 1);
                case this.modeType.THREE:
                    return this.getLastMonthOfThree(date);
                default:
                    return '';
            }
        },
        getDateStr(date) {
            if (this.mode == this.modeType.DAY || this.mode == this.modeType.WEEK) {
                return date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日';
            } else {
                return date.getFullYear() + '年' + (date.getMonth() + 1) + '月';
            }
        },
        getFromDateStr(date) {
            return this.getDateStr(this.getFromDate(date));
        },
        getToDateStr(date) {
            return this.getDateStr(this.getToDate(date));
        },
        onDoneButtonClicked() {
            let fromDate;
            let toDate;
            switch (this.mode) {
                case this.modeType.DAY:
                    fromDate = this.getFromDate(this.fromDate);
                    toDate = this.getToDate(this.toDate);
                    break;
                case this.modeType.WEEK:
                    fromDate = this.getFromDate(this.fromDate);
                    toDate = this.getFirstDateOfWeek(this.getToDate(this.toDate));
                    break;
                case this.modeType.MONTH:
                    fromDate = this.getFromDate(this.fromDate);
                    toDate = this.getToDate(this.toDate);
                    break;
                case this.modeType.THREE:
                    fromDate = this.getFromDate(this.fromDate);
                    toDate = this.getFirstMonthOfThree(this.getToDate(this.toDate));
                    break;
                default:
                    break;
            }
            this.$emit('done', {
                mode: this.mode,
                fromDate: fromDate,
                toDate: toDate,
            });
        },
    },

    mixins: [mixin],
};
</script>

<style scoped lang="scss">
.dialog {
    :deep(.dialog-base) {
        width: 800px;
    }
}
.calendar-wrapper {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding-top: 40px;
    padding-bottom: 40px;
    .title {
        font-size: 18px;
        font-weight: $font_weight_bold;
        margin-bottom: 20px;
        text-align: center;
    }
    .arrow {
        position: absolute;
        top: 96px;
        left: 0;
        width: 100%;
        text-align: center;
        img {
            width: 77px;
            height: 50px;
        }
    }
    .date {
        height: 80px;
        width: 254px;
        border-radius: 6px;
        border: 1px solid rgb(150, 150, 150);
        line-height: 80px;
        text-align: center;
        font-size: 24px;
        font-weight: $font_weight_bold;
        font-family: HiraginoSans;
    }
}
.select {
    margin-top: 60px;
    margin-left: 74px;
    margin-right: 74px;
    p {
        font-size: 16px;
        font-weight: $font_weight_bold;
        margin-bottom: 20px;
    }
    ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin-bottom: 58px;
        li {
            margin-right: 50px;
            label {
                font-size: 16px;
                font-weight: $font_weight_bold;
                cursor: pointer;
                input {
                    margin-right: 10px;
                }
            }
        }
    }
}
.btn-wrapper {
    @include flex_center;
    .btn-gradient,
    .btn-white {
        @include btn-common;
        margin-top: 17px;
        margin-bottom: 0;
        margin-left: 15px;
        margin-right: 15px;
        width: 140px;
    }
    .btn-gradient {
        @include btn-gradient;
    }
    .btn-white {
        @include btn-white;
    }
}
</style>
