<template>
    <AnalyticsGraphWrapper>
        <h4>顔認識数グラフ</h4>
        <div class="control">
            <div class="gender"></div>
            <!-- <div class="label-category"> -->
            <div class="label"><span class="color color-valid"></span>認識数</div>
            <div v-if="visitorOption" class="label"><span class="color color-invalid"></span>未認識数</div>
            <div v-if="visitorOption" class="label"><span class="line color-line"></span>認識比率</div>
            <!-- </div> -->
        </div>
        <p v-if="visitorOption" class="graph">
            <span class="scale-label" :style="{ left: '20px' }">人</span>
            <StackedBarChart :height="300" :chartData="chartDataAnonymous" />
        </p>
        <p v-else class="graph">
            <span class="scale-label" :style="{ left: '20px' }">人</span>
            <StackedBarChart :height="300" :chartData="chartDataNoAnonymous" />
        </p>
        <ul v-if="visitorOption" class="ratio-list first">
            <li class="title">
                <ul class="title-list">
                    <li>認識比率</li>
                </ul>
            </li>
            <li v-for="(visitors, index) in visitorsList" :key="index">
                <ul class="value-list">
                    <li class="total">
                        <span class="text">{{ (visitors ? parseInt((visitors.known / visitors.total) * 100) : 0) + '%' }}</span>
                    </li>
                </ul>
            </li>
        </ul>
    </AnalyticsGraphWrapper>
</template>

<script>
import AnalyticsGraphWrapper from '@/components/analytics/AnalyticsGraphWrapper.vue';
import StackedBarChart from '@/components/analytics/StackedBarChart.vue';

export default {
    name: 'AnalyticsRecognitionGraph',

    components: {
        AnalyticsGraphWrapper,
        StackedBarChart,
    },

    props: {
        mode: {
            type: String,
            required: true,
        },
        leftSide: {
            type: Object,
            required: true,
        },
        num: {
            type: Number,
            required: true,
        },
        visitorOption: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        modeType: function () {
            return this.$store.state.analyticsModeType;
        },
        chartDataAnonymous: function () {
            return {
                labels: Array(this.num).fill(''),
                datasets: [
                    {
                        type: 'line',
                        label: '取得比率',
                        data: this.getVisitorsByRecognitionRatio(),
                        borderColor: '#588FD9',
                        fill: false,
                        lineTension: 0,
                        yAxisID: 'y-axis-2',
                    },
                    {
                        label: '取得数',
                        borderWidth: 1,
                        backgroundColor: '#CFCF39',
                        borderColor: '#CFCF39',
                        lineTension: 0,
                        pointHitRadius: 20,
                        data: this.getVisitorsByRecognition(true),
                        yAxisID: 'y-axis-1',
                    },
                    {
                        label: '未取得数',
                        borderWidth: 1,
                        backgroundColor: '#A4A4A4',
                        borderColor: '#A4A4A4',
                        lineTension: 0,
                        pointHitRadius: 20,
                        data: this.getVisitorsByRecognition(false),
                        yAxisID: 'y-axis-1',
                    },
                ],
            };
        },
        chartDataNoAnonymous: function () {
            return {
                labels: Array(this.num).fill(''),
                datasets: [
                    {
                        label: '取得数',
                        borderWidth: 1,
                        backgroundColor: '#CFCF39',
                        borderColor: '#CFCF39',
                        lineTension: 0,
                        pointHitRadius: 20,
                        data: this.getVisitorsByRecognition(true),
                    },
                ],
            };
        },
        visitorsList: function () {
            return this.$store.getters.getVisitors(this.mode, this.leftSide, this.num);
        },
    },
    methods: {
        getVisitorsByRecognition: function (isValid) {
            return this.$store.getters.getVisitorsByRecognition(isValid, this.mode, this.leftSide, this.num);
        },
        getVisitorsByRecognitionRatio: function () {
            return this.$store.getters.getVisitorsByRecognitionRatio(this.mode, this.leftSide, this.num);
        },
    },
};
</script>

<style scoped lang="scss">
.color-valid {
    background-color: #cfcf39;
}
.color-invalid {
    background-color: #a4a4a4;
}
.color-line {
    background-color: #588fd9;
}
.ratio-list {
    @include ratio-list;
    margin-bottom: 80px;
}
.line {
    width: 20px;
    height: 2px;
}
.scale-label {
    @include scale-label;
    top: calc(50% - 28px);
}
.graph {
    position: relative;
}
</style>
