var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-mypage" },
    [
      _c("p", { staticClass: "btn-back" }, [
        _c(
          "a",
          {
            attrs: { href: "/" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.back.apply(null, arguments)
              },
            },
          },
          [_vm._v("戻る")]
        ),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }