<template>
  <div class="select-box">
    <p class="title">{{ title }}</p>
    <select :value="value" @change="onChange">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.title }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectBox",

  props: {
    value: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },

  methods: {
    onChange: function(event) {
      this.$emit("update", event.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.select-box {
  position: relative;
  width: 138px;
  height: 44px;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 6px;
  position: relative;
  caret-color: transparent;
  .title {
    position: absolute;
    top: 0;
    left: -110px;
    width: 100px;
    height: 44px;
    color: rgb(84, 84, 84);
    font-size: 16px;
    text-align: right;
    line-height: 44px;
  }
  &::before {
    position: absolute;
    top: 24px;
    right: 12px;
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgb(95, 95, 95);
    pointer-events: none;
  }
  select {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    appearance: none;
    font-size: 16px;
    font-weight: $font_weight_bold;
    line-height: 44px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
