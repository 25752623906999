<template>
    <div class="header-control">
        <p class="btn-back"><a href="/" @click.prevent="back">戻る</a></p>
    </div>
</template>

<script>
export default {
    name: 'ControlHeader',

    methods: {
        back: function () {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped lang="scss">
.header-control {
    overflow: hidden;
    height: 60px;
    border-bottom: 1px solid rgb(216, 216, 216);
    .btn-back {
        @include btn-back;
    }
    @include sp-screen {
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: none;
        padding-top: 10px;
    }
}
</style>
