<template>
    <div class="dialog">
        <div class="dialog-base">
            <div class="dialog-header">
                <p class="title">{{ title }}</p>
                <p class="btn-close" @click.prevent="$emit('close')"></p>
            </div>
            <div class="dialog-content">
                <p class="desc">{{ desc1 }}<br>{{ desc2 }}</p>
            </div>
            <div class="dialog-footer">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoDialog',

    props: {
        kind: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            title: '',
            desc1: '',
            desc2: '',
        };
    },
    created: function () {
        switch (this.kind) {
            case 'all':
                this.title = '対象店舗数';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内の店舗数・事務所数を表示しています。';
                this.desc2 = '競合ビジネスの数、その増減などを把握することができます。';
                break;
            case 'new':
                this.title = '新規店舗数';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内の新規開業数を表示しています。';
                this.desc2 = '開業数の増減や、開業が多い時期などを把握することができます。';
                break;
            case 'seats':
                this.title = '座席数別店舗数比率';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗の座席数割合をグラフ表示しています。';
                this.desc2 = '大型店・小規模店舗の分布割合を把握することができます。';
                break;
            case 'lunch':
                this.title = '平均単価（ランチ）';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗でランチ営業を実施している店舗のランチ価格割合をグラフ表示しています。';
                this.desc2 = 'エリア内のランチ平均単価を把握することができます。';
                break;
            case 'dinner':
                this.title = '平均単価（ディナー）';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗のディナー時間帯の価格割合をグラフ表示しています。';
                this.desc2 = 'エリア内のディナー時間帯の平均単価を把握することができます。';
                break;
            case 'holiday':
                this.title = '定休日';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗の定休日の割合をグラフ表示しています。';
                this.desc2 = 'エリア内店舗における定休日が多い曜日を把握することができます。';
                break;
            case 'opening':
                this.title = '営業開始時間';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗の営業開始時間の割合をグラフ表示しています。';
                this.desc2 = 'エリア内店舗における営業開始時間の平均を把握することができます。';
                break;
            case 'closing':
                this.title = '営業終了時間';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗の営業終了時間の割合をグラフ表示しています。';
                this.desc2 = 'エリア内店舗における営業終了時間の平均を把握することができます。';
                break;
            case 'card':
                this.title = 'クレカ使用可否';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗のクレジットカード決済可否の割合をグラフ表示しています。';
                this.desc2 = 'エリア内店舗におけるクレジットカード決済ができるかできないかの割合を把握することができます。';
                break;
            case 'payment':
                this.title = '支払い方法';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗で利用できる決済方法の種類と割合をグラフ表示しています。';
                this.desc2 = 'エリア内店舗における決済手段の種類、その比率を把握することができます。';
                break;
            case 'years':
                this.title = '営業年数';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗の営業年数の割合をグラフ表示しています。';
                this.desc2 = '長く営業している店舗の割合、新規店舗の割合を把握することができます。';
                break;
            case 'parking':
                this.title = '駐車場の有無';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗の駐車場保有をグラフ表示しています。';
                this.desc2 = 'エリア内店舗における駐車場の有無の比率を把握することができます。';
                break;
            case 'smoking':
                this.title = '喫煙可否';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗の喫煙可否の割合をグラフ表示しています。';
                this.desc2 = 'エリア内店舗における喫煙可否の比率を把握することができます。';
                break;
            case 'cut':
                this.title = '平均単価（カット）';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗のカット単価の割合をグラフ表示しています。';
                this.desc2 = 'エリア内のカット平均単価を把握することができます。';
                break;
            case 'obsession':
                this.title = 'こだわり条件';
                this.desc1 = 'USEN独自で集計したデータ分析による、エリア内にある店舗のこだわり条件の種類と割合をグラフ表示しています。';
                this.desc2 = 'エリア内店舗におけるこだわり条件の種類、その比率を把握することができます。';
                break;
        }
    },
};
</script>

<style scoped lang="scss">
.dialog {
    @include flex_center;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .dialog-base {
        background-color: $color_white;
        border-radius: 10px;
        box-shadow: $box_shadow;
        width: 1000px;
        .dialog-header {
            overflow: hidden;
            position: relative;
            height: 80px;
            border-bottom: 1px solid rgb(220, 220, 220);
            .title {
                text-align: center;
                width: 490px;
                margin-left: auto;
                margin-right: auto;
                color: $color_black;
                font-size: 20px;
                font-weight: $font_weight_bold;
                line-height: 1em;
                margin-top: 30px;
            }
            .btn-back {
                @include btn-back;
                position: absolute;
                top: 30px;
                left: 44px;
                margin-left: 0;
                margin-top: 0;
            }
            .btn-close {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 40px;
                height: 40px;
                background-image: url('/images/icon_close.svg');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                &:active {
                    opacity: 0.5;
                }
            }
        }
        .dialog-content{
            font-size:18px;
            height:120px;
            padding:40px;
        }
        .dialog-footer {
            height: 80px;
            border-top: 1px solid rgb(220, 220, 220);
        }
    }
}
</style>
