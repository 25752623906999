<template>
  <div class="weather-calendar-monthly">
      <ul>
        <li v-for="(weather, index) in weatherList" :key="weather.date">
          <div class="item">
            <h2 v-if="index === 0 || getDate(weather.date) === 1">{{ getMonthStr(weather.date) }}</h2>
            <WeatherCalendarListItem :weather="weather" mode="day" :date="getDate(weather.date)" :day="getDay(weather.date)" />
          </div>
        </li>
      </ul>
  </div>
</template>

<script>
import WeatherCalendarListItem from '@/components/WeatherCalendarListItem.vue';
import mixin from '@/mixin/index.js';

export default {
  name: 'WeatherCalendarMonthly',

  components: {
    WeatherCalendarListItem,
  },

  computed: {
    weatherList: function() {
      return this.$store.getters.getWeatherList('day');
    },
  },

  methods: {
    getMonthStr: function(date) {
      const dateObj = this.getDateObj(date);
      return dateObj.year + '/' + dateObj.month;
    },
    getDate: function(date) {
      const dateObj = this.getDateObj(date);
      return parseInt(dateObj.date);
    },
    getDay: function(date) {
      const dateObj = this.getDateObj(date);
      return dateObj.day;
    },
  },

  mixins: [mixin]
}
</script>

<style scoped lang="scss">
.weather-calendar-monthly {
  background-color: $color_white;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 1064px;
    margin-left: auto;
    margin-right: auto;
    li {
      width: 152px;
      flex-grow: 0;
      flex-shrink: 0;
      .item {
        width: 100%;
        position: relative;
        padding-top: 56px;
        padding-bottom: 44px;
        border-bottom: 1px solid rgb(226, 226, 226);
        h2 {
          position: absolute;
          top: 10px;
          left: 20px;
          color: $color_black;
          font-size: 24px;
          font-weight: $font_weight_bold;
          line-height: 36px;
        }
        .weather-calendar-list-item {
          width: 122px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
</style>
