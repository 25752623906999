<template>
    <div>
        <Header current="mypage" />
        <div class="container">
            <div class="content-wrapper">
                <MypageMenu tag="payment" :payView="payView" />
                <div class="content">
                    <ul class="payments">
                        <p class="attention">
                            本画面での「入金予定」「取扱高推移」は、2023年9月30日ご入金分にて更新を停止いたします。<br />
                            以降は、Webブラウザ版「USEN MEMBERSマイページ」でのご確認をお願いいたします。<br />

                            <a href="https://umembers.usen.com/entry/?utm_source=tabupay&utm_medium=referral&utm_campaign=web" taeget="_umembers"
                                >『USEN MEMBERS』への新規登録はこちらから</a
                            ><br />
                            ※閲覧している環境によっては、リンク先ページが開かない場合がございます。<br />
                        その際は、スマートフォン・パソコンから「USEN MEMBERS」で検索をお願いいたします。
                        </p>
                        <li v-for="(paymentList, date) in payments" :key="date">
                            <h2>お振込予定日 {{ date }}</h2>
                            <ul class="list-payment">
                                <li v-for="payment in paymentList" :key="date + '_' + payment.type">
                                    <p class="type">{{ getPaymentTypeStr(payment) }}</p>
                                    <p class="amount">{{ formatAmount(payment.PAY_AMT) }}</p>
                                    <p class="btn-detail">
                                        <a href="" @click.prevent="goToPaymentDetail(payment.type, date)">詳細を見る</a>
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import MypageMenu from '@/components/menu/MypageMenu.vue';
import mixin from '@/mixin/index.js';
import setparam from '@/mixin/setparam.js';
import mypageapicall from '@/mixin/mypageapicall.js';

export default {
    name: 'UPayPayment',

    components: {
        Header,
        MypageMenu,
    },

    computed: {
        payments: function () {
            return this.$store.getters.payments;
        },
    },

    methods: {
        formatDate: function (date) {
            return date.substr(0, 4) + '年' + date.substr(4, 2) + '月' + date.substr(6, 2) + '日';
        },
        formatAmount: function (amount) {
            return '¥' + String(amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        },
        getPaymentTypeStr: function (payment) {
            switch (payment.type) {
                case 'PAYGATE':
                    return 'Uペイ / USEN PAYGATE';
                case 'UPAY':
                    return 'U ペイ';
                case 'UPAYQR':
                    return 'U ペイ QR';
                default:
                    return '';
            }
        },
        goToPaymentDetail: function (type, date) {
            this.$router.push('/mypage/upay/payment/' + type + '/' + date.replace(/\//g, '-'));
        },
    },
    async created() {
        const today = new Date();
        const from = this.getDateStr(new Date(2020, 5, 1));
        const to = this.getDateStr(new Date(today.getUTCFullYear(), today.getMonth() + 1, 0));
        await this.$store.dispatch('fetchPayments', {
            mode: 'day',
            from,
            to,
            unisCustomerCode: this.unisCustomerCode,
        });
    },

    mixins: [mixin, setparam, mypageapicall],
};
</script>

<style scoped lang="scss">
.attention {
    color: red;
    margin-bottom: 12px;
}
.payments {
    margin-top: 46px;
    margin-left: 66px;
    margin-right: 34px;
    margin-bottom: 46px;
    > li {
        margin-bottom: 66px;
        h2 {
            color: $color_black;
            font-size: 20px;
            font-weight: $font_weight_bold;
            margin-bottom: 16px;
        }
        .list-payment {
            border: 2px solid rgb(217, 217, 217);
            border-radius: 6px;
            padding-left: 48px;
            li {
                @include flex_between;
                height: 80px;
                border-bottom: 1px solid rgb(217, 217, 217);
                &:last-child {
                    border-bottom: none;
                }
                .type,
                .amount,
                .btn-detail {
                    height: 46px;
                    line-height: 46px;
                    margin-top: 17px;
                }
                .type {
                    flex-grow: 0;
                    width: 270px;
                    font-size: 16px;
                    font-weight: $font_weight_bold;
                }
                .amount {
                    flex-grow: 1;
                    font-size: 26px;
                    font-weight: $font_weight_bold;
                    color: $color_light_blue;
                }
                .btn-detail {
                    flex-grow: 0;
                    width: 200px;
                    background-color: $color_light_blue;
                    border-radius: 23px;
                    margin-right: 10px;
                    box-shadow: $box_shadow;
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        color: $color_white;
                        font-size: 20px;
                        font-weight: $font_weight_bold;
                    }
                }
            }
        }
    }
}
</style>
