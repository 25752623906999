export default {
  filters: {
    delimiter: function(value) {
      return value == null ? null : String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
  },

  methods: {
    zeroPadding(n) {
      return ('00' + n).slice(-2);
    },

    getDate(d) {
      if (typeof d === 'string') {
        /*
         * '20200810' のような形式の場合
         */
        const year = d.substr(0, 4);
        const month = d.substr(4, 2);
        const date = d.substr(6, 2);
        return new Date(year, month - 1, date);

      } else {
        /*
         * { year: 2020, month: 8, date: 10 } のような形式の場合
         */
        if (d.hour) {
          return new Date(d.year, d.month - 1, d.date, d.hour);
        } else {
          return new Date(d.year, d.month - 1, d.date);
        }
      }
    },

    getDateObj(d) {
      if (typeof d === 'string') {
        const year = d.substr(0, 4);
        const month = d.substr(4, 2);
        const date = d.substr(6, 2);
        const hour = d.substr(8, 2);
        if (hour) {
          d = new Date(year, month - 1, date, hour);
        } else {
          d = new Date(year, month - 1, date);
        }
      }
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const date = d.getDate();
      const day = d.getDay();
      const days = [ '日', '月', '火', '水', '木', '金', '土' ];
      const hour = d.getHours();
      return { year, month, date, day: days[day], hour };
    },

    getMonthStr(d) {
      const year = d.getFullYear();
      const month = this.zeroPadding(d.getMonth() + 1);
      return '' + year + month;
    },

    getDateStr(d) {
      const year = d.getFullYear();
      const month = this.zeroPadding(d.getMonth() + 1);
      const date = this.zeroPadding(d.getDate());
      return '' + year + month + date;
    },

    getDateHourStr(d) {
      const year = d.getFullYear();
      const month = this.zeroPadding(d.getMonth() + 1);
      const date = this.zeroPadding(d.getDate());
      const hours = this.zeroPadding(d.getHours());
      return '' + year + month + date + hours;
    },

    /*
     * n日後を取得
     */
    getNDaysLater(d, n) {
      const date = new Date(d);
      date.setDate(date.getDate() + n);
      return date;
    },

    /*
     * n時間後を取得
     */
    getNHoursLater(d, n) {
      const date = new Date(d);
      date.setHours(date.getHours() + n);
      return date;
    },

    /*
     * nヶ月後を取得
     */
    getNMonthsLater(d, n) {
      const date = new Date(d);
      date.setMonth(date.getMonth() + n);
      return date;
    },

    /*
     * 週の最初の日を取得
     */
    getFirstDateOfWeek(d) {
      const date = new Date(d);
      const day = d.getDay();
      const diff = d.getDate() - day;
      date.setDate(diff)
      return date;
    },

    /*
     * 週の最後の日を取得
     */
    getLastDateOfWeek(d) {
      const date = this.getFirstDateOfWeek(d);
      date.setDate(date.getDate() + 6);
      return date;
    },

    /*
     * ３ヶ月ごとに区切った最初の月を取得
     */
    getFirstMonthOfThree(d) {
      const date = new Date(d);
      const month = Math.floor(d.getMonth() / 3) * 3
      date.setMonth(month);
      return date;
    },

    /*
     * ３ヶ月ごとに区切った最後の月を取得
     */
    getLastMonthOfThree(d) {
      const date = this.getFirstMonthOfThree(d);
      date.setMonth(date.getMonth() + 2);
      return date;
    },
    /*
     * 先月を取得
     */
     getPrevMonth() {
      const dateObj = new Date();
      const timezoneOffset = dateObj.getTimezoneOffset() / 60 + 9;
      dateObj.setHours(dateObj.getHours() + timezoneOffset);
      dateObj.setMonth(dateObj.getMonth() -1 );
  
      const y = String(dateObj.getFullYear());
      const m = String(dateObj.getMonth() + 1).padStart(2, '0');
  
      return `${y}${m}`;
    }
  }
}
