<template>
    <AnalyticsGraphWrapper>
        <h4>新規 or リピーター<img @click="viewHint('ageRatio')" class="info-img-label" src="/images/icon_info.png" /></h4>
        <div class="control">
            <div class="gender">
                <label><input type="checkbox" v-model="showAge" />年代別を表示</label>
            </div>
            <div class="label"><span class="color color-new"></span>新規</div>
            <div class="label"><span class="color color-repeater"></span>リピーター</div>
            <img class="info-img-right" src="/images/icon_info.png" @click="viewHint('repeater')" />
        </div>
        <p class="graph">
            <span class="scale-label" :style="{ left: graphType === 'Bar' ? '20px' : '70px' }">人</span>
            <StackedBarChart v-if="graphType === 'Bar'" :height="300" :chartData="chartData" />
            <LineChart v-if="graphType === 'Line'" :height="300" :chartData="chartData" :options="lineGraphOptions" />
        </p>
        <ul class="ratio-list first" :class="{ 'show-age': showAge }">
            <li class="title">
                <ul class="title-list">
                    <li class="total info" @click="viewHint('newRepeaterRatio')"><img class="info-img" src="/images/icon_info.png" />新規全体</li>
                    <li v-show="showAge">10代</li>
                    <li v-show="showAge">20代</li>
                    <li v-show="showAge">30代</li>
                    <li v-show="showAge">40代</li>
                    <li v-show="showAge">50代</li>
                    <li v-show="showAge">60代</li>
                    <li v-show="showAge">70代以上</li>
                </ul>
            </li>
            <li v-for="(visitors, index) in visitorsList" :key="index">
                <ul class="value-list">
                    <li class="total">
                        <span class="text total">{{ (visitors ? visitors.newCustomer.ratio : 0) + '%' }}</span>
                    </li>
                    <li v-show="showAge" v-for="n in 7" :key="n">
                        <span class="text">{{ (visitors ? visitors.newCustomer.ratioByAge[n - 1] : 0) + '%' }}</span>
                    </li>
                </ul>
            </li>
        </ul>
        <ul class="ratio-list second" :class="{ 'show-age': showAge }">
            <li class="title">
                <ul class="title-list">
                    <li class="total info" @click="viewHint('newRepeaterRatio')"><img class="info-img" src="/images/icon_info.png" />ﾘﾋﾟｰﾀｰ全体</li>
                    <li v-show="showAge">10代</li>
                    <li v-show="showAge">20代</li>
                    <li v-show="showAge">30代</li>
                    <li v-show="showAge">40代</li>
                    <li v-show="showAge">50代</li>
                    <li v-show="showAge">60代</li>
                    <li v-show="showAge">70代以上</li>
                </ul>
            </li>
            <li v-for="(visitors, index) in visitorsList" :key="index">
                <ul class="value-list">
                    <li class="total">
                        <span class="text total">{{ (visitors ? visitors.repeater.ratio : 0) + '%' }}</span>
                    </li>
                    <li v-show="showAge" v-for="n in 7" :key="n">
                        <span class="text">{{ (visitors ? visitors.repeater.ratioByAge[n - 1] : 0) + '%' }}</span>
                    </li>
                </ul>
            </li>
        </ul>
    </AnalyticsGraphWrapper>
</template>

<script>
import AnalyticsGraphWrapper from '@/components/analytics/AnalyticsGraphWrapper.vue';
import StackedBarChart from '@/components/analytics/StackedBarChart.vue';
import LineChart from '@/components/analytics/LineChart.vue';

export default {
    name: 'AnalyticsRepeaterGraph',

    components: {
        AnalyticsGraphWrapper,
        StackedBarChart,
        LineChart,
    },

    props: {
        isPrediction: {
            type: Boolean,
            default: false,
        },
        isAverage: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            required: true,
        },
        leftSide: {
            type: Object,
            required: true,
        },
        num: {
            type: Number,
            required: true,
        },
        graphType: {
            type: String,
            default: 'Bar',
        },
    },

    data: function () {
        return {
            showAge: false,
            lineGraphOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {
                                display: false,
                            },
                            ticks: {
                                min: 0,
                                userCallback: function (label) {
                                    if (Math.floor(label) === label) {
                                        return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    }
                                },
                            },
                        },
                    ],
                },
                layout: {
                    padding: {
                        left: 100,
                        right: 130,
                        top: 0,
                        bottom: 0,
                    },
                },
                tooltips: {
                    titleFontSize: 17,
                    titleSpacing: 10,
                    titleMarginBottom: 10,
                    bodyFontSize: 17,
                    bodySpacing: 10,
                    footerFontSize: 17,
                    footerSpacing: 10,
                    footerMarginTop: 10,
                    xPadding: 10,
                    yPadding: 10,
                    caretPadding: 12,
                },
            },
        };
    },

    computed: {
        modeType: function () {
            return this.$store.state.analyticsModeType;
        },
        chartData: function () {
            return {
                labels: Array(this.num).fill(''),
                datasets: [
                    {
                        label: '新規',
                        borderWidth: this.graphType === 'Bar' ? 1 : 3,
                        backgroundColor: this.graphType === 'Bar' ? '#8577B2' : 'transparent',
                        borderColor: '#8577B2',
                        lineTension: 0,
                        pointHitRadius: 20,
                        data: this.getVisitorsByCustomerType('newCustomer'),
                    },
                    {
                        label: 'リピーター',
                        borderWidth: this.graphType === 'Bar' ? 1 : 3,
                        backgroundColor: this.graphType === 'Bar' ? '#7FC6C0' : 'transparent',
                        borderColor: '#7FC6C0',
                        lineTension: 0,
                        pointHitRadius: 20,
                        data: this.getVisitorsByCustomerType('repeater'),
                    },
                ],
            };
        },
        visitorsList: function () {
            return this.$store.getters.getVisitors(this.mode, this.leftSide, this.num);
        },
    },
    methods: {
        getVisitorsByCustomerType: function (type) {
            return this.$store.getters.getVisitorsByCustomerType(type, this.mode, this.leftSide, this.num);
        },
        viewHint(kind) {
            this.$emit('viewHint', kind);
        },
    },
};
</script>

<style scoped lang="scss">
.info {
    cursor: pointer;
}
.info-img {
    margin-top: 5px;
    margin-right: 3px;
    width: 20px;
}
.info-img-label {
    cursor: pointer;
    margin-top: 3px;
    margin-left: 12px;
    width: 20px;
}
.info-img-right {
    cursor: pointer;
    margin-top: 80px;
    width: 20px;
}
.color-new {
    background-color: #8577b2;
}

.color-repeater {
    background-color: #7fc6c0;
}
.ratio-list {
    @include ratio-list;
}
.scale-label {
    @include scale-label;
    top: calc(50% - 28px);
}
.graph {
    position: relative;
}
</style>
