<template>
  <div>
    <Header current="mypage"/>
    <div class="container">
      <div class="content-wrapper">
        <MypageMenu tag="mybusiness"/>
        <div class="content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import MypageMenu from '@/components/menu/MypageMenu.vue';

export default {
  name: 'MyBusiness',

  components: {
    Header,
    MypageMenu,
  }
};
</script>
