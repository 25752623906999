<template>
  <span class="date"><slot></slot></span>
</template>

<script>
export default {
  name: 'Date'
};
</script>

<style scoped lang="scss">
.date {
  font-size: 20px;
  vertical-align: bottom;
  .num {
    font-family: $number_font_family;
    font-size: 40px;
    font-weight: $font_weight_number_bold;
    &:not(:first-child) {
      margin-left: 6px;
    }
  }
}
</style>
