import Vue from 'vue';
import Vuex from 'vuex';
import config from '../config.js';
import createPersistedState from 'vuex-persistedstate';

const env = process.env.NODE_ENV;

Vue.use(Vuex);

const zeroPadding = (n) => {
    return ('00' + n).slice(-2);
};

const getDateStr = (d) => {
    const year = d.getFullYear();
    const month = zeroPadding(d.getMonth() + 1);
    const date = zeroPadding(d.getDate());
    return '' + year + month + date;
};

const getDateHourStr = (d) => {
    const year = d.getFullYear();
    const month = zeroPadding(d.getMonth() + 1);
    const date = zeroPadding(d.getDate());
    const hour = zeroPadding(d.getHours());
    return '' + year + month + date + hour;
};

export default new Vuex.Store({
    state: {
        isDebug: false,

        shop: {
            icon: 'user_sample.svg',
            name: 'meguro cafe',
            isPaid: false,
            useUregi: true,
        },

        weatherList: {},

        newsList: [
            {
                id: 1,
                title: '助成金、いくら受給できるかを診断',
                thumb: 'news_sample4.png',
                content: '',
                nativeTab: {
                    tab: 4,
                    sub: 0,
                    content: 0,
                },
            },
            {
                id: 2,
                title: 'お得な会員向け通販サイトUSEN CART',
                thumb: 'news_sample5.png',
                content: '',
                nativeTab: {
                    tab: 3,
                    sub: 0,
                    content: 0,
                },
            },
            {
                id: 3,
                title: '放送チャンネルの楽曲を確認できます',
                thumb: 'news_sample6.png',
                content: '',
                nativeTab: {
                    tab: 5,
                    sub: 1,
                    content: 0,
                },
            },
            // {
            //   id: 4,
            //   title: '近くにカフェが１店オープンしました',
            //   thumb: 'news_sample1.png',
            //   content: '(自店舗から半径5km圏内)'
            // },
            // {
            //   id: 5,
            //   title: '軽減税率について',
            //   thumb: 'news_sample2.png',
            //   content: 'ポイント還元の仕組みも説明します'
            // },
            // {
            //   id: 6,
            //   title: 'キッチン用品が値下げ',
            //   thumb: 'news_sample3.png',
            //   content: '鍋、フライパン、食器など 10日間限定'
            // },
        ],

        eventList: [],

        salesList: [],

        visitorsList: {},

        visitorOption: false,

        visitorsAverageList: {},

        visitorsExists: false,

        payments: {},

        paymentCheck: false,

        paymentTransaction: {},

        auth: {
            lastLogin: null,
            passCodeExist: false,
            unisCustomerCode: null,
            user: null,
        },

        analyticsModeType: {
            HOUR: 'hour',
            THREE_HOURS: 'three-hours',
            DAY: 'day',
            WEEK: 'week',
            MONTH: 'month',
            THREE: 'three',
        },
        weatherTime: '',
        weatherIconTable: {
            晴れ: '100',
            '晴れ 時々 くもり': '101',
            '晴れ 一時 雨': '102',
            '晴れ 時々 雨': '103',
            '晴れ 一時 雪': '104',
            '晴れ 時々 雪': '105',
            '晴れ のち くもり': '111',
            '晴れ のち 雨': '114',
            '晴れ のち 雪': '117',
            くもり: '200',
            'くもり 時々 晴れ': '201',
            'くもり 一時 雨': '202',
            'くもり 時々 雨': '203',
            'くもり 一時 雪': '204',
            'くもり 時々 雪': '205',
            'くもり のち 晴れ': '211',
            'くもり のち 雨': '214',
            'くもり のち 雪': '217',
            雨: '300',
            '雨 時々 晴れ': '301',
            '雨 時々 くもり': '302',
            '雨 時々 雪': '303',
            '雨 一時 雪': '309',
            '雨 のち 晴れ': '311',
            '雨 のち くもり': '313',
            '雨 のち 雪': '315',
            雪: '400',
            '雪 時々 晴れ': '401',
            '雪 時々 くもり': '402',
            '雪 時々 雨': '403',
            '雪 一時 雨': '409',
            '雪 のち 晴れ': '411',
            '雪 のち くもり': '413',
            '雪 のち 雨': '414',
        },
        storeCount: {
            all: [],
            new: [],
        },
        restaurant: {
            seats: {},
            lunchPrice: {},
            dinnerPrice: {},
            takeout: {},
            holiday: {},
            opening: {},
            closing: {},
            card: {},
            payment: {},
            years: {},
            parking: {},
            private: {},
            smoking: {},
            info: {},
        },
        beauty: {
            seats: {},
            cutPrice: {},
            holiday: {},
            opening: {},
            closing: {},
            card: {},
            payment: {},
            obsession: {},
        },
        bizCode: [],
        areaCode: [],
        shopSearchOptions: null,
        userProduct: [],
    },

    getters: {
        weatherTime: (state) => {
            return state.weatherTime;
        },
        /*
         * 天気の情報（日単位/時単位）を取得
         */
        getWeatherList: (state) => (mode, dateFilter) => {
            let today = new Date();
            if (mode === 'day') {
                today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            } else {
                today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours());
            }
            const weatherList = state.weatherList[mode];
            const results = [];
            for (let date in weatherList) {
                // 'hour' のときは日付を指定して取得
                if (mode === 'hour' && date) {
                    if (date.substr(0, 8) !== dateFilter) {
                        continue;
                    }
                }

                const weather = Object.assign({}, weatherList[date]);

                let d;
                if (mode === 'day') {
                    d = new Date(date.substr(0, 4), date.substr(4, 2) - 1, date.substr(6, 2));
                } else {
                    d = new Date(date.substr(0, 4), date.substr(4, 2) - 1, date.substr(6, 2), date.substr(8, 2));
                }
                if (d < today) {
                    weather.type = 'past';
                } else if (d > today) {
                    weather.type = 'future';
                } else {
                    weather.type = 'current';
                }
                weather.date = date;

                // アイコン名に変換
                weather.weather = state.weatherIconTable[weather.weather];

                // 催事のデータを結合
                const event = state.eventList.find((event) => event.date === date.substr(4, 4));
                if (event) {
                    weather.event = event.event;
                }
                results.push(weather);
            }
            return results;
        },

        /*
         * 天気の情報（日単位/時単位）を取得
         */
        getAnalyticsWeatherList: (state) => (mode, leftSide, num) => {
            const weatherList = state.weatherList[mode];
            const results = [];
            let date;
            switch (mode) {
                case state.analyticsModeType.HOUR:
                    date = new Date(leftSide.year, leftSide.month - 1, leftSide.date, leftSide.hour);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateHourStr(date);
                        const weather = weatherList && weatherList[dateStr] ? Object.assign({}, weatherList[dateStr]) : null;
                        if (weather != null) {
                            weather.weather = state.weatherIconTable[weather.weather];
                        }
                        results.push(weather);
                        date.setHours(date.getHours() + 1);
                    }
                    break;
                case state.analyticsModeType.DAY:
                    date = new Date(leftSide.year, leftSide.month - 1, leftSide.date);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateStr(date);
                        const weather = weatherList && weatherList[dateStr] ? Object.assign({}, weatherList[dateStr]) : null;
                        if (weather != null) {
                            weather.weather = state.weatherIconTable[weather.weather];
                        }
                        results.push(weather);
                        date.setDate(date.getDate() + 1);
                    }
                    break;
                default:
                    break;
            }
            return results;
        },

        /*
         * 催事の情報を取得
         */
        getEvent: (state) => (date) => {
            const event = state.eventList.find((event) => event.date === date);
            return event ? event.event : '';
        },

        /*
         * 売り上げの情報を取得
         */
        getSalesValue: (state) => (mode, leftSide, num) => {
            const salesList = state.salesList[mode];
            const results = [];
            let date;
            const pushResult = (dateStr) => {
                let value = 0;
                if (salesList) {
                    const sales = salesList.find((sales) => {
                        if(sales.start && dateStr === sales.start){
                            return sales.start.match(dateStr);
                        }

                    });
                    if (sales) {
                        value = sales.Data.sales;
                    }
                }
                results.push(value);
            };
            switch (mode) {
                case state.analyticsModeType.DAY:
                    date = new Date(leftSide.year, leftSide.month - 1, leftSide.date);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateStr(date);
                        pushResult(dateStr);
                        date.setDate(date.getDate() + 1);
                    }
                    break;
                case state.analyticsModeType.WEEK:
                    date = new Date(leftSide.year, leftSide.month - 1, leftSide.date);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateStr(date);
                        pushResult(dateStr);
                        date.setDate(date.getDate() + 7);
                    }
                    break;
                case state.analyticsModeType.MONTH:
                    date = new Date(leftSide.year, leftSide.month - 1);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateStr(date).substr(0, 6);
                        pushResult(dateStr);
                        date.setMonth(date.getMonth() + 1);
                    }
                    break;
                case state.analyticsModeType.THREE:
                    date = new Date(leftSide.year, leftSide.month - 1);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateStr(date).substr(0, 6);
                        pushResult(dateStr);
                        date.setMonth(date.getMonth() + 3);
                    }
                    break;
                default:
                    break;
            }
            return results;
        },

        getVisitorsCore: (state) => (visitorsList, mode, leftSide, num) => {
            const results = [];
            let date;
            const pushResult = (dateStr) => {
                let visitorData = null;
                if (visitorsList) {
                    const visitors = visitorsList.find((visitors) => {
                        if (visitors.sk) {
                            return visitors.sk.match(dateStr);
                        } else if (visitors.start) {
                            return visitors.start.match(dateStr);
                        }
                    });
                    if (visitors) {
                        visitorData = visitors.Data;
                    }
                }
                results.push(visitorData);
            };
            switch (mode) {
                case state.analyticsModeType.HOUR:
                    date = new Date(leftSide.year, leftSide.month - 1, leftSide.date, leftSide.hour);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateHourStr(date);
                        pushResult(dateStr);
                        date.setHours(date.getHours() + 1);
                    }
                    break;
                case state.analyticsModeType.DAY:
                    date = new Date(leftSide.year, leftSide.month - 1, leftSide.date);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateStr(date);
                        pushResult(dateStr);
                        date.setDate(date.getDate() + 1);
                    }
                    break;
                case state.analyticsModeType.WEEK:
                    date = new Date(leftSide.year, leftSide.month - 1, leftSide.date);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateStr(date);
                        pushResult(dateStr);
                        date.setDate(date.getDate() + 7);
                    }
                    break;
                case state.analyticsModeType.MONTH:
                    date = new Date(leftSide.year, leftSide.month - 1);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateStr(date).substr(0, 6);
                        pushResult(dateStr);
                        date.setMonth(date.getMonth() + 1);
                    }
                    break;
                case state.analyticsModeType.THREE:
                    date = new Date(leftSide.year, leftSide.month - 1);
                    for (let i = 0; i < num; i++) {
                        const dateStr = getDateStr(date).substr(0, 6);
                        pushResult(dateStr);
                        date.setMonth(date.getMonth() + 3);
                    }
                    break;
                default:
                    break;
            }
            // console.log(results);
            return results;
        },

        /*
         * 来客数情報を取得
         */
        getVisitors: (state, getter) => (mode, leftSide, num) => {
            const visitorsList = state.visitorsList[mode];
            return getter.getVisitorsCore(visitorsList, mode, leftSide, num);
        },

        /*
         * 来客数合計を取得
         */
        getVisitorsTotal: (state, getters) => (mode, leftSide, num) => {
            return getters.getVisitors(mode, leftSide, num).map((visitors) => (visitors == null ? 0 : visitors.total));
        },
        /*
         * 来客数（顔認識分）を取得
         */
        getVisitorsKnownTotal: (state, getters) => (mode, leftSide, num) => {
            return getters.getVisitors(mode, leftSide, num).map((visitors) => (visitors == null ? 0 : visitors.known));
        },
        /*
         * 来客数（男女別）を取得
         */
        getVisitorsByGender: (state, getters) => (gender, mode, leftSide, num) => {
            return getters.getVisitors(mode, leftSide, num).map((visitors) => (visitors == null ? 0 : visitors[gender].total));
        },

        /*
         * 来客数（新規・リピーター別）を取得
         */
        getVisitorsByCustomerType: (state, getters) => (type, mode, leftSide, num) => {
            return getters.getVisitors(mode, leftSide, num).map((visitors) => (visitors == null ? 0 : visitors[type].total));
        },

        /*
         * 来客数（取得・未取得別）を取得
         */
        getVisitorsByRecognition: (state, getters) => (isValid, mode, leftSide, num) => {
            if (isValid) {
                return getters.getVisitors(mode, leftSide, num).map((visitors) => (visitors == null ? 0 : visitors.known));
            } else {
                return getters.getVisitors(mode, leftSide, num).map((visitors) => (visitors == null ? 0 : visitors['anonymous'].total));
            }
        },
        getVisitorsByRecognitionRatio: (state, getters) => (mode, leftSide, num) => {
            return getters
                .getVisitors(mode, leftSide, num)
                .map((visitors) => (visitors == null ? 0 : visitors ? parseInt((visitors.known / visitors.total) * 100) : 0));
        },
        /*
         * 来客数平均情報を取得
         */
        getVisitorsAverage: (state, getters) => (mode, leftSide, num) => {
            const visitorsList = state.visitorsAverageList[mode];
            return getters.getVisitorsCore(visitorsList, mode, leftSide, num);
        },

        /*
         * 来客数平均を取得
         */
        getVisitorsAverageTotal: (state, getters) => (mode, leftSide, num) => {
            return getters.getVisitors(mode, leftSide, num).map((visitors) => (visitors == null ? 0 : visitors.total));
        },

        /*
         * 来客数平均（男女別）を取得
         */
        getVisitorsAverageByGender: (state, getters) => (gender, mode, leftSide, num) => {
            return getters.getVisitors(mode, leftSide, num).map((visitors) => (visitors == null ? 0 : visitors[gender].total));
        },

        /*
         * 来客数平均（新規・リピーター別）を取得
         */
        getVisitorsAverageByCustomerType: (state, getters) => (type, mode, leftSide, num) => {
            return getters.getVisitors(mode, leftSide, num).map((visitors) => (visitors == null ? 0 : visitors[type].total));
        },

        /*
         * 対象総店舗数取得
         */
        getStoreCountAll: (state) => (date) => {
            const count = state.storeCount.all.find((count) => count.sk === date);
            if (count) {
                return count.data.y;
            }
            return 0;
        },

        /*
         * 新規店舗数取得
         */
        getStoreCountNew: (state) => (date) => {
            const count = state.storeCount.new.find((count) => count.sk === date);
            if (count) {
                return count.data.y;
            }
            return 0;
        },
        /*
         * Uレジ契約顧客か
         */
        isUregiUser: (state) => {
            if(state.userProduct.includes('40')){
                return true;
            }
            if(state.userProduct.includes('41')){
                return true;
            }
            if(state.userProduct.includes('47')){
                return true;
            }
            if(state.userProduct.includes('48')){
                return true;
            }
            return false;
        },
        /*
         * ビジター表示オプション
         */
        visitorOption: (state) => {
            return state.visitorOption;
        },

        /*
         * 天気/催事カレンダー取得
         */
        getCalendarList: (state) => (month) => {
            let calendarList = [];
            const weatherList = state.weatherList.day;
            const eventList = state.eventList;
            const firstDay = new Date(month.substr(0, 4), month.substr(4, 2) - 1);
            const lastDay = new Date(month.substr(0, 4), month.substr(4, 2), 0);
            const date = new Date(firstDay);
            let today = new Date();
            today = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            let firstEvent = null;

            while (date <= lastDay) {
                let obj = {};
                const dateStr = getDateStr(date);
                obj.date = dateStr;

                // 天気のデータを結合
                if (weatherList && weatherList[dateStr]) {
                    const weather = Object.assign({}, weatherList[dateStr]);
                    weather.weather = state.weatherIconTable[weather.weather];
                    obj.weather = weather;
                }

                // 売り上げ/来客数のデータを結合
                // if (state.salesList['day']) {
                //     const sales = state.salesList['day'].find((sales) => sales.sk === dateStr);
                //     if (sales) {
                //         obj.sales = sales.Data;
                //     }
                // }

                // 催事のデータを結合
                const event = eventList.find((event) => event.date === dateStr.substr(4, 4));
                if (event && event.event) {
                    // event プロパティは連続する同一の催事の最初の日にのみ設定
                    if (firstEvent == null) {
                        firstEvent = { title: event.event, period: 1 };
                        obj.event = firstEvent;
                    } else {
                        // 連続する２日目以降は、初日の event の period を加算する
                        if (firstEvent.title === event.event) {
                            firstEvent.period += 1;
                        } else {
                            firstEvent = { title: event.event, period: 1 };
                            obj.event = firstEvent;
                        }
                    }
                } else {
                    firstEvent = null;
                }
                obj.isToday = date.getTime() === today.getTime();
                calendarList.push(obj);
                date.setDate(date.getDate() + 1);
            }
            return calendarList;
        },

        /*
         * UPay（入金予定）の取得
         */
        payments: (state) => {
            const result = {};
            for (const date in state.payments.PAYGATE) {
                const obj = state.payments.PAYGATE[date];
                obj.type = 'PAYGATE';
                if (result[date] == null) {
                    result[date] = [];
                }
                result[date].push(obj);
            }
            for (const date in state.payments.UPAY) {
                const obj = state.payments.UPAY[date];
                obj.type = 'UPAY';
                if (result[date] == null) {
                    result[date] = [];
                }
                result[date].push(obj);
            }
            for (const date in state.payments.UPAYQR) {
                const obj = state.payments.UPAYQR[date];
                obj.type = 'UPAYQR';
                if (result[date] == null) {
                    result[date] = [];
                }
                result[date].push(obj);
            }
            return result;
        },

        getPayment: (state) => (type, date) => {
            return state.payments[type][date.replace(/-/g, '/')];
        },

        getPaymentTransaction: (state) => (date) => {
            return state.paymentTransaction[date];
        },

        getPaymentCheck: (state) => () => {
            return state.paymentCheck;
        },
        /*
         * マイページの状態を取得
         */
        isAuthenticated: (state) => {
            if (!state.auth.lastLogin) {
                return false;
            }
            const currentUnixTime = Math.floor(Date.now() / 1000);
            //ログイン後24時間経過していればログアウト
            if (currentUnixTime > state.auth.lastLogin + 1 * 24 * 60 * 60) {
                //ログアウト
                state.auth.lastLogin = null;
                return false;
            } else {
                return true;
            }
        },
        getUnisCustomerCode: (state) => {
            return state.auth.unisCustomerCode;
        },
        getUserData: (state) => {
            return state.auth.user;
        },
        passCodeExist: (state) => {
            return state.auth.passCodeExist;
        },
        lastLogin: (state) => {
            return state.auth.lastLogin;
        },
        restaurantSeats: (state) => {
            return state.restaurant.seats;
        },
        restaurantLunchPrice: (state) => {
            return state.restaurant.lunchPrice;
        },
        restaurantDinnerPrice: (state) => {
            return state.restaurant.dinnerPrice;
        },
        restaurantTakeout: (state) => {
            return state.restaurant.takeout;
        },
        restaurantHoliday: (state) => {
            return state.restaurant.holiday;
        },
        restaurantOpening: (state) => {
            return state.restaurant.opening;
        },
        restaurantClosing: (state) => {
            return state.restaurant.closing;
        },
        restaurantCard: (state) => {
            return state.restaurant.card;
        },
        restaurantPayment: (state) => {
            return state.restaurant.payment;
        },
        restaurantYears: (state) => {
            return state.restaurant.years;
        },
        restaurantParking: (state) => {
            return state.restaurant.parking;
        },
        restaurantPrivate: (state) => {
            return state.restaurant.private;
        },
        restaurantSmoking: (state) => {
            return state.restaurant.smoking;
        },
        restaurantInfo: (state) => {
            return state.restaurant.info;
        },
        beautySeats: (state) => {
            return state.beauty.seats;
        },
        beautyCutPrice: (state) => {
            return state.beauty.cutPrice;
        },
        beautyHoliday: (state) => {
            return state.beauty.holiday;
        },
        beautyOpening: (state) => {
            return state.beauty.opening;
        },
        beautyClosing: (state) => {
            return state.beauty.closing;
        },
        beautyCard: (state) => {
            return state.beauty.card;
        },
        beautyPayment: (state) => {
            return state.beauty.payment;
        },
        beautyObsession: (state) => {
            return state.beauty.obsession;
        },
        bizCode: (state) => {
            return state.bizCode;
        },
        areaCode: (state) => {
            return state.areaCode;
        },
    },

    mutations: {
        setWeather(state, { mode, data }) {
            for (const date in data) {
                if (state.weatherList[mode] == null) {
                    Vue.set(state.weatherList, mode, {});
                }
                Vue.set(state.weatherList[mode], date, data[date]);
            }
        },
        setWeatherMeta(state, { data }) {
            if (data.dataTime) {
                //'2021-05-19 16'
                const d = data.dataTime.split(' ');
                const date = d[0].split('-');
                state.weatherTime = `${date[0]}年${date[1]}月${date[2]}日 ${d[1]}時 現在`;
            } else {
                const today = new Date();
                state.weatherTime = `${today.getFullYear()}年${today.getMonth() + 1}月${today.getDate()}日 ${today.getHours() - 1}時 現在`;
            }
        },
        setEvents(state, { data }) {
            state.eventList = data;
        },

        setSales(state, { mode, data }) {
            Vue.set(state.salesList, mode, data);
        },

        setVisitors(state, { mode, data }) {
            Vue.set(state.visitorsList, mode, data);
        },

        setVisitorOption(state, data) {
            state.visitorOption = data.display_anonymous;
        },

        setVisitorsAverage(state, { mode, data }) {
            Vue.set(state.visitorsAverageList, mode, data);
        },
        setVisitorsDataExists(state, { data }) {
            state.visitorsExists = data.data;
        },
        setPayments(state, { data }) {
            state.payments = data;
        },
        setPaymentCheck(state, { data }) {
            state.paymentCheck = data.data;
        },
        setPaymentTransaction(state, { data }) {
            state.paymentTransaction = data;
        },
        setUserProduct(state, { data }) {
            state.userProduct = data;
        },   
        setLastLoginUnixTime(state) {
            state.auth.lastLogin = Math.floor(Date.now() / 1000);
        },
        updateLastLoginUnixTime(state, unixTime) {
            state.auth.lastLogin = unixTime;
        },
        clearLogin(state) {
            state.auth.lastLogin = null;
        },
        setUserData(state, { user }) {
            state.auth.unisCustomerCode = user.data.unis_customer_cd;
            state.auth.user = user.data;
            state.auth.lastLogin = user.lastLogin;
            if (user.passcode) {
                state.auth.passCodeExist = true;
            }
        },
        setStoreCountAll(state, { data }) {
            state.storeCount.all = data;
        },
        setStoreCountNew(state, { data }) {
            state.storeCount.new = data;
        },
        setRestaurantSeats(state, { data }) {
            state.restaurant.seats = data;
        },
        setRestaurantLunchPrice(state, { data }) {
            state.restaurant.lunchPrice = data;
        },
        setRestaurantDinnerPrice(state, { data }) {
            state.restaurant.dinnerPrice = data;
        },
        setRestaurantTakeout(state, { data }) {
            state.restaurant.takeout = data;
        },
        setRestaurantHoliday(state, { data }) {
            state.restaurant.holiday = data;
        },
        setRestaurantOpening(state, { data }) {
            state.restaurant.opening = data;
        },
        setRestaurantClosing(state, { data }) {
            state.restaurant.closing = data;
        },
        setRestaurantCard(state, { data }) {
            state.restaurant.card = data;
        },
        setRestaurantPayment(state, { data }) {
            state.restaurant.payment = data;
        },
        setRestaurantYears(state, { data }) {
            state.restaurant.years = data;
        },
        setRestaurantParking(state, { data }) {
            state.restaurant.parking = data;
        },
        setRestaurantPrivate(state, { data }) {
            state.restaurant.private = data;
        },
        setRestaurantSmoking(state, { data }) {
            state.restaurant.smoking = data;
        },
        setRestaurantCdvInfo(state, { data }) {
            state.restaurant.info = data;
        },
        setBeautySeats(state, { data }) {
            state.beauty.seats = data;
        },
        setBeautyCutPrice(state, { data }) {
            state.beauty.cutPrice = data;
        },
        setBeautyHoliday(state, { data }) {
            state.beauty.holiday = data;
        },
        setBeautyOpening(state, { data }) {
            state.beauty.opening = data;
        },
        setBeautyClosing(state, { data }) {
            state.beauty.closing = data;
        },
        setBeautyCard(state, { data }) {
            state.beauty.card = data;
        },
        setBeautyPayment(state, { data }) {
            state.beauty.payment = data;
        },
        setBeautyObsession(state, { data }) {
            state.beauty.obsession = data;
        },
        setBizCodeData(state, { data }) {
            state.bizCode = data;
        },
        setAreaCodeData(state, { data }) {
            state.areaCode = data;
        },
        setShopSearchOptions(state, { options }) {
            state.shopSearchOptions = options;
        },
    },

    actions: {
        async fetchUrl(context, { url, unisCustomerCode }) {
            const headers = {
                'x-api-key': config[env].apiKey,
            };
            if (unisCustomerCode) {
                headers['x-unis-cd'] = unisCustomerCode;
                // headers['access-control-allow-origin'] = "*";
            }
            const res = await fetch(url, {
                mode: 'cors',
                headers: headers,
            });
            return await res.json();
        },
        async postJson(context, { url, payload }) {
            const headers = {
                'x-api-key': config[env].apiKey,
            };
            if (payload.unisCustomerCode) {
                headers['x-unis-cd'] = payload.unisCustomerCode;
            }
            const res = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(payload),
            });
            if (res.status !== 200) {
                return null;
            } else {
                return await res.json();
            }
        },

        async fetchWeather(context, { mode, from, to }) {
            const url =
                config[env].apiBaseUrl + '/weather?mode=' + mode + '&area=' + context.state.auth.user.customer_area + '&from=' + from + '&to=' + to;
            const res = await context.dispatch('fetchUrl', { url });
            context.commit('setWeather', { mode, data: res.data });
            context.commit('setWeatherMeta', { data: res.meta });
        },

        async fetchEvents(context) {
            const url = config[env].apiBaseUrl + '/event_calendar';
            const res = await context.dispatch('fetchUrl', { url });
            context.commit('setEvents', { data: res });
        },

        async fetchSales(context, { unisCustomerCode, mode, from, to }) {
            const url = config[env].apiBaseUrl + '/sales?mode=' + mode + '&from=' + from + '&to=' + to;
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            context.commit('setSales', { mode, data: res });
        },

        async fetchVisitors(context, { unisCustomerCode, mode, from, to }) {
            const url = config[env].apiVisitorsBaseUrl + '/visitors?mode=' + mode + '&from=' + from + '&to=' + to;
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            context.commit('setVisitors', { mode, data: res });
        },

        async fetchVisitorOption(context, { unisCustomerCode }) {
            const url = config[env].apiVisitorsBaseUrl + '/visitors/option';
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            context.commit('setVisitorOption', res);
        },

        async fetchVisitorsAverage(context, { unisCustomerCode, mode, from, to }) {
            const url = config[env].apiVisitorsBaseUrl + '/average-visitors?mode=' + mode + '&from=' + from + '&to=' + to;
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            context.commit('setVisitorsAverage', { mode, data: res });
        },

        async fetchVisitorsDataExists(context, { unisCustomerCode }) {
            const url = config[env].apiVisitorsBaseUrl + '/average-visitors/user/exists';
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            context.commit('setVisitorsDataExists', { data: res });
        },
        async fetchPayments(context, { unisCustomerCode, from, to }) {
            const url = config[env].apiBaseUrl + '/payment?from=' + from + '&to=' + to;
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            context.commit('setPayments', { data: res });
        },

        async fetchPaymentCheck(context, { unisCustomerCode }) {
            const url = config[env].apiBaseUrl + '/payment_check';
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            context.commit('setPaymentCheck', { data: res });
        },
        async fetchUserProductCode(context, { unisCustomerCode }) {
            const url = config[env].apiBaseUrl + '/user/product';
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            context.commit('setUserProduct', { data: res });
        },
        async fetchPaymentTransaction(context, { unisCustomerCode, from, to }) {
            const url = config[env].apiBaseUrl + '/payment_transaction?from=' + from + '&to=' + to;
            const res = await context.dispatch('fetchUrl', { unisCustomerCode, url });
            context.commit('setPaymentTransaction', { data: res });
        },

        async fetchUser(context, { unisCustomerCode }) {
            const url = config[env].apiBaseUrl + '/user';
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            context.commit('setUserData', { user: res });
        },
        async postPassCodeCheck(context, payload) {
            const url = config[env].apiBaseUrl + '/codecheck';
            const res = await context.dispatch('postJson', { url, payload });
            if (res) {
                return await context.dispatch('postUserLastLogin');
                // context.commit('setLastLoginUnixTime');
            }
            return false;
        },
        async postUserPassCode(context, payload) {
            const url = config[env].apiBaseUrl + '/passcode';
            const res = await context.dispatch('postJson', { url, payload });
            if (res) {
                return await context.dispatch('postUserLastLogin');
            }
            return false;
        },
        async postUserLastLogin(context) {
            const url = config[env].apiBaseUrl + '/user/login';
            const lastLogin = Math.floor(Date.now() / 1000);
            const payload = {
                unisCustomerCode: context.state.auth.unisCustomerCode,
                lastlogin: lastLogin,
            };
            const res = await context.dispatch('postJson', { url, payload });
            if (res) {
                context.commit('updateLastLoginUnixTime', lastLogin);
                return true;
            }
            return false;
        },
        async getUserPassCode(context, unisCustomerCode) {
            const url = config[env].apiBaseUrl + '/passcode';
            const res = await context.dispatch('fetchUrl', { url, unisCustomerCode });
            return res.check;
        },
        async clearLogin(context) {
            context.commit('clearLogin');
        },

        async fetchBizCode(context) {
            const url = config[env].apiBaseUrl + '/analytics/master/bizcode';
            const res = await context.dispatch('fetchUrl', { url });

            const bizCode = res[0].data;
            const genres = [];
            for (const largeId in bizCode) {
                const large = {
                    id: largeId,
                    name: bizCode[largeId].name,
                    children: [],
                };

                for (const midId in bizCode[largeId].contains) {
                    const mid = {
                        id: midId,
                        name: bizCode[largeId].contains[midId].name,
                        children: [],
                    };

                    for (const smallId in bizCode[largeId].contains[midId].contains) {
                        mid.children.push(bizCode[largeId].contains[midId].contains[smallId]);
                    }

                    large.children.push(mid);
                }

                genres.push(large);
            }

            context.commit('setBizCodeData', { data: genres });
        },
        async fetchAreaCode(context) {
            const url = config[env].apiBaseUrl + '/analytics/master/city';
            const res = await context.dispatch('fetchUrl', { url });
            context.commit('setAreaCodeData', { data: res });
        },

        resetStoreCount(context, { graph }) {
            if (graph === '1') {
                context.commit('setStoreCountAll', { data: [] });
            } else if (graph === '2') {
                context.commit('setStoreCountNew', { data: [] });
            }
        },

        async fetchStoreCount(context, { from, to, pref_cd, area_cd, middle_biz_cd, graph }) {
            const url =
                config[env].apiBaseUrl +
                `/analytics/store/count?from=${from}&to=${to}&pref_cd=${pref_cd}&area_cd=${area_cd}&middle_biz_cd=${middle_biz_cd}&graph=${graph}`;
            const res = await context.dispatch('fetchUrl', { url });

            if (graph === '1') {
                context.commit('setStoreCountAll', { data: res });
            } else if (graph === '2') {
                context.commit('setStoreCountNew', { data: res });
            }
        },

        resetRestaurantTrend(context, payload) {
            const dataCollection = {
                labels: [],
                data: [],
            };
            if (payload.graph === '1') {
                context.commit('setRestaurantSeats', { data: dataCollection });
            } else if (payload.graph === '2') {
                context.commit('setRestaurantLunchPrice', { data: dataCollection });
            } else if (payload.graph === '3') {
                context.commit('setRestaurantDinnerPrice', { data: dataCollection });
            } else if (payload.graph === '4') {
                context.commit('setRestaurantTakeout', { data: dataCollection });
            } else if (payload.graph === '5') {
                context.commit('setRestaurantHoliday', { data: dataCollection });
            } else if (payload.graph === '6') {
                context.commit('setRestaurantOpening', { data: dataCollection });
            } else if (payload.graph === '7') {
                context.commit('setRestaurantClosing', { data: dataCollection });
            } else if (payload.graph === '8') {
                context.commit('setRestaurantCard', { data: dataCollection });
            } else if (payload.graph === '9') {
                context.commit('setRestaurantPayment', { data: dataCollection });
            } else if (payload.graph === '10') {
                context.commit('setRestaurantYears', { data: dataCollection });
            } else if (payload.graph === '11') {
                context.commit('setRestaurantParking', { data: dataCollection });
            } else if (payload.graph === '12') {
                context.commit('setRestaurantPrivate', { data: dataCollection });
            } else if (payload.graph === '13') {
                context.commit('setRestaurantSmoking', { data: dataCollection });
            } else if (payload.graph === '14') {
                context.commit('setRestaurantCdvInfo', { data: dataCollection });
            }
        },

        async fetchRestaurantTrend(context, payload) {
            const url =
                config[env].apiBaseUrl +
                '/analytics/restaurant/trend?' +
                `date=${payload.date}&pref_cd=${payload.pref_cd}&area_cd=${payload.area_cd}&small_biz_cd=${payload.small_biz_cd}&graph=${payload.graph}`;
            const res = await context.dispatch('fetchUrl', { url });

            const dataCollection = {
                labels: res.data ? res.data.map((d) => d.x) : [],
                data: res.data ? res.data.map((d) => d.y) : [],
            };

            if (payload.graph === '1') {
                context.commit('setRestaurantSeats', { data: dataCollection });
            } else if (payload.graph === '2') {
                context.commit('setRestaurantLunchPrice', { data: dataCollection });
            } else if (payload.graph === '3') {
                context.commit('setRestaurantDinnerPrice', { data: dataCollection });
            } else if (payload.graph === '4') {
                context.commit('setRestaurantTakeout', { data: dataCollection });
            } else if (payload.graph === '5') {
                context.commit('setRestaurantHoliday', { data: dataCollection });
            } else if (payload.graph === '6') {
                context.commit('setRestaurantOpening', { data: dataCollection });
            } else if (payload.graph === '7') {
                context.commit('setRestaurantClosing', { data: dataCollection });
            } else if (payload.graph === '8') {
                context.commit('setRestaurantCard', { data: dataCollection });
            } else if (payload.graph === '9') {
                context.commit('setRestaurantPayment', { data: dataCollection });
            } else if (payload.graph === '10') {
                context.commit('setRestaurantYears', { data: dataCollection });
            } else if (payload.graph === '11') {
                context.commit('setRestaurantParking', { data: dataCollection });
            } else if (payload.graph === '12') {
                context.commit('setRestaurantPrivate', { data: dataCollection });
            } else if (payload.graph === '13') {
                context.commit('setRestaurantSmoking', { data: dataCollection });
            } else if (payload.graph === '14') {
                context.commit('setRestaurantCdvInfo', { data: dataCollection });
            }
        },

        resetBeautyTrend(context, payload) {
            const dataCollection = {
                labels: [],
                data: [],
            };
            if (payload.graph === '1') {
                context.commit('setBeautySeats', { data: dataCollection });
            } else if (payload.graph === '2') {
                context.commit('setBeautyCutPrice', { data: dataCollection });
            } else if (payload.graph === '3') {
                context.commit('setBeautyHoliday', { data: dataCollection });
            } else if (payload.graph === '4') {
                context.commit('setBeautyOpening', { data: dataCollection });
            } else if (payload.graph === '5') {
                context.commit('setBeautyClosing', { data: dataCollection });
            } else if (payload.graph === '6') {
                context.commit('setBeautyCard', { data: dataCollection });
            }
        },

        async fetchBeautyTrend(context, payload) {
            const url =
                config[env].apiBaseUrl +
                '/analytics/beauty/trend?' +
                `date=${payload.date}&pref_cd=${payload.pref_cd}&area_cd=${payload.area_cd}&small_biz_cd=${payload.small_biz_cd}&graph=${payload.graph}`;
            const res = await context.dispatch('fetchUrl', { url });

            const dataCollection = {
                labels: res.data ? res.data.map((d) => d.x) : [],
                data: res.data ? res.data.map((d) => d.y) : [],
            };

            if (payload.graph === '1') {
                context.commit('setBeautySeats', { data: dataCollection });
            } else if (payload.graph === '2') {
                context.commit('setBeautyCutPrice', { data: dataCollection });
            } else if (payload.graph === '3') {
                context.commit('setBeautyHoliday', { data: dataCollection });
            } else if (payload.graph === '4') {
                context.commit('setBeautyOpening', { data: dataCollection });
            } else if (payload.graph === '5') {
                context.commit('setBeautyClosing', { data: dataCollection });
            } else if (payload.graph === '6') {
                context.commit('setBeautyCard', { data: dataCollection });
            } else if (payload.graph === '7') {
                context.commit('setBeautyPayment', { data: dataCollection });
            } else if (payload.graph === '8') {
                context.commit('setBeautyObsession', { data: dataCollection });
            }
        },
    },

    modules: {},

    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ],
});
