<template>
    <div>
        <Header current="analytics" />
        <div class="container">
            <div class="content-wrapper">
                <AnalyticsMenu tag="closing" :largeBizCd="largeBizCd"  :visitorView="visitorView"/>
                <div class="content">
                    <div class="content-shop-analytics">
                        <ShopSearchHeader
                            :areas="areaCode"
                            :genres="bizCode"
                            :pref="pref"
                            :city="city"
                            :large="largeBizCd"
                            :middle="middleBizCd"
                            :small="smallBizCd"
                        />
                        <ShopAnalyticsAxis mode="MODE_COUNT" :shopCount="storeTotal()" :created="{ year: 2020, month: 10 }" />
                        <ShopAnalyticsGraph
                            title="営業終了時間"
                            :labels="beautyClosing.labels ? beautyClosing.labels : []"
                            :data-collection="beautyClosing.data ? beautyClosing.data : []"
                        />
                        <p class="notice">
                            公開情報を基に当社が独自で行った分析結果になりますので参考値としてご利用ください。<br>
                            情報を公開している店舗に曜日別等で営業終了時間が複数ある場合、店舗ごとの最も遅い営業時間を基に分析を行っております。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import setparam from '@/mixin/setparam.js';
import shopapicall from '@/mixin/shopapicall.js';
import analyticssubmenu from '@/mixin/analyticssubmenu.js';
import Header from '@/components/Header.vue';
import AnalyticsMenu from '@/components/menu/AnalyticsMenu.vue';
import ShopSearchHeader from '@/components/analytics/ShopSearchHeader.vue';
import ShopAnalyticsAxis from '@/components/analytics/ShopAnalyticsAxis.vue';
import ShopAnalyticsGraph from '@/components/analytics/ShopAnalyticsGraph.vue';

export default {
    name: 'ShopBeautyClosing',

    computed: {
        beautyClosing: function () {
            return this.$store.getters.beautyClosing;
        },
    },

    components: {
        Header,
        AnalyticsMenu,
        ShopSearchHeader,
        ShopAnalyticsAxis,
        ShopAnalyticsGraph,
    },

    data: () => {
        return {
            category: '3',
            graph: '5',
            storeTotal: function () {
                if (this.beautyClosing.data == null) {
                    return 0;
                }
                return this.beautyClosing.data.reduce((acc, val) => acc + Number(val), 0);
            },
        };
    },

    mixins: [setparam, shopapicall, analyticssubmenu]
};
</script>

<style scoped lang="scss">
.content-shop-analytics {
    margin-left: 24px;
    margin-right: 24px;
    .notice {
        @include shop-notice;
    }
}
</style>
