export default {
    data: function () {
        return {};
    },
    computed: {
        auth() {
            return this.$store.state.auth;
        },
        visitorView: function () {
            return this.$store.state.visitorsExists;
        },
    },
    methods: {
        async fetchVisitorExists() {
            await this.$store.dispatch('fetchVisitorsDataExists', {
                unisCustomerCode: this.unisCustomerCode,
            });
        },
    },

    async created() {
        if (this.auth.user !== null) {
            await this.fetchVisitorExists();
        }
    },

    watch: {
        auth: {
            async handler() {
                if (this.auth.user !== null) {
                    // this.setCustomerInfo();
                    await this.fetchVisitorExists();
                }
            },
            deep: true,
        },
    },
};
