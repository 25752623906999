var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-box" }, [
    _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c(
      "select",
      { domProps: { value: _vm.value }, on: { change: _vm.onChange } },
      _vm._l(_vm.options, function (option) {
        return _c(
          "option",
          { key: option.value, domProps: { value: option.value } },
          [_vm._v(" " + _vm._s(option.title) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }