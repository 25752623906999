<template>
  <div>
    <Header current="analytics"/>
    <div class="container">
      <div class="content-wrapper">
        <AnalyticsMenu tag="new" :largeBizCd="largeBizCd" :visitorView="visitorView"/>
        <div class="content">
          <div class="content-shop-analytics">
            <ShopSearchHeader
              :areas="areaCode"
              :genres="bizCode"
              :pref="pref"
              :city="city"
              :large="largeBizCd"
              :middle="middleBizCd"
              :small="smallBizCd"
              :hideSmall="true"
              @show-info="isInfoDialogShown = true"
            />
            <ShopAnalyticsAxis mode="MODE_AXIS" :axisNum="axisNum"/>
            <ShopAnalyticsGraph title="新規店舗数"
              :labels="labels"
              :data-collection="dataCollection"
            />
            <InfoDialog v-if="isInfoDialogShown" @close="isInfoDialogShown = false" :kind="kind"/>
            <p class="notice">
              該当月に新規開業した店舗が無い場合は、すべて「0店」と表示されています。<br>
              当社の独自調査および公開情報を基に行った分析結果となりますので参考値としてご利用ください。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mixin from '@/mixin/index.js';
import setparam from '@/mixin/setparam.js';
import shopapicall from '@/mixin/shopapicall.js';
import analyticssubmenu from '@/mixin/analyticssubmenu.js';
import Header from '@/components/Header.vue';
import AnalyticsMenu from '@/components/menu/AnalyticsMenu.vue';
import ShopSearchHeader from '@/components/analytics/ShopSearchHeader.vue';
import ShopAnalyticsAxis from '@/components/analytics/ShopAnalyticsAxis.vue';
import ShopAnalyticsGraph from '@/components/analytics/ShopAnalyticsGraph.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';

export default {
  name: 'ShopAll',

  components: {
    Header,
    AnalyticsMenu,
    ShopSearchHeader,
    ShopAnalyticsAxis,
    ShopAnalyticsGraph,
    InfoDialog
  },

  data() {
    return {
      axisNum: 9,
      category: '1',
      graph: '2',
      isInfoDialogShown:false,
      kind:"new"
    };
  },

  computed: {
    labels() {
      return new Array(this.axisNum).fill('');
    },
    dataCollection() {
      const today = new Date();
      const leftSide = new Date(today.getFullYear(), today.getMonth(), 1);
      leftSide.setMonth(leftSide.getMonth() - (this.axisNum - 1));
      const data = [];
      for (let i = 0; i < this.axisNum; i++) {
        const date = new Date(leftSide);
        date.setMonth(date.getMonth() + i);
        const dateStr = this.getMonthStr(date);
        const count = this.$store.getters.getStoreCountNew(dateStr);
        data.push(count);
      }
      return data;
    }
  },

  mixins: [mixin, setparam, shopapicall, analyticssubmenu]
};
</script>

<style scoped lang="scss">
.content-shop-analytics {
  margin-left: 24px;
  margin-right: 24px;
  .notice {
    @include shop-notice;
  }
}
</style>
