<template>
  <div class="list-weather-wrapper clearfix" :class="{ compressed: isCompressed }">
    <button v-show="!isCompressed && shop.isPaid" class="btn-close tablet" @click="onCloseButtonClicked"></button>
    <ul class="list-weather">
      <li v-for="weather in weatherList" :key="weather.date" :class="{ today: weather.type == 'current' }" @click="onListItemClicked(weather)">
        <h3><span v-if="weather.type == 'current'" class="today-title">今日</span><span class="num">{{ zeroPadding(getDateObj(weather.date).date) }}</span> {{ getDateObj(weather.date).day }}曜日</h3>
        <p class="icon"><img :src="'images/icon_weather_' + weather.weather + '.svg'"></p>
        <p class="info">
          降水確率{{ weather.rainy }}%<br>
          {{ weather.max_temperature }}℃ / {{ weather.min_temperature }}℃
        </p>
        <p class="holiday">{{ weather.event }}</p>
      </li>
    </ul>
    <ShopInfo v-show="isCompressed"/>
  </div>
</template>

<script>
import ShopInfo from '@/components/ShopInfo.vue';
import $ from 'jquery';
import "slick-carousel/slick/slick.min.js";
import mixin from '@/mixin/index.js';

export default {
  name: 'Weather',

  components: {
    ShopInfo
  },

  data: function() {
    return {
      isCompressed: false,
      slider: null,
      slidesToShow: 7
    };
  },

  computed: {
    shop: function() {
      return this.$store.state.shop;
    },
    weatherList: function() {
      return this.isCompressed
        ? this.$store.getters.getWeatherList('day').filter(weather => weather.type !== 'past')
        : this.$store.getters.getWeatherList('day');
    },
    indexOfToday: function() {
      const today = this.getDateStr(new Date());
      let index = this.weatherList.findIndex((weather) => weather.date === today);
      if (index + this.slidesToShow > this.weatherList.length) {
        index = this.weatherList.length - this.slidesToShow;
      }
      return index;
    }
  },

  methods: {
    slick: function() {
      this.slider = $('.list-weather').slick({
        arrows: true,
        slidesToShow: this.slidesToShow,
        centerMode: false,
        infinite: false,
        initialSlide: this.indexOfToday,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 6
            }
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 920,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 740,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 2
            }
          },
        ]
      });
    },

    unslick: function() {
      if (this.slider != null) {
        $('.list-weather').slick('unslick');
        this.slider = null;
      }
    },

    expandWeatherList: function() {
      if (!this.isCompressed) {
        return;
      }
      if (this.slider != null) {
        return;
      }
      this.isCompressed = false;
      this.$nextTick(() => {
        this.slick();
      });
    },

    compressWeatherList: function() {
      if (this.slider == null) {
        return;
      }
      this.isCompressed = true;
      this.unslick();
    },

    isSmartphone: function() {
      return matchMedia('(max-width: 480px)').matches;
    },

    onCloseButtonClicked: function() {
      this.compressWeatherList();
    },

    onListItemClicked: function(weather) {
      if (this.isCompressed) {
        this.expandWeatherList();
      } else {
        const datObj = this.getDateObj(weather.date);
        this.$router.push('/timeline/' + datObj.year + '/' + this.zeroPadding(datObj.month) + '/' + this.zeroPadding(datObj.date));
      }
    },
  },

  mounted: function() {
    this.isCompressed = this.shop.isPaid && !this.isSmartphone();
    if (!this.isCompressed) {
      this.slick();
    }
  },

  watch: {
    weatherList() {
      if (!this.isCompressed) {
        this.$nextTick(() => {
          this.unslick();
          this.slick();
        });
      }
    }
  },

  mixins: [mixin]
};
</script>

<style scoped lang="scss">
.list-weather-wrapper {
  position: relative;
  .btn-close {
    position: absolute;
    top: -64px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-image: url("/images/icon_close.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    &:active {
      opacity: 0.5;
    }
  }
  .list-weather {
    overflow: hidden;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 10px;
    li {
      float: left;
      width: 169px;
      height: 248px;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.5);
      text-align: center;
      outline: none;
      cursor: pointer;
      &.today {
        background-color: rgba(255, 255, 255, 1.0);
        box-shadow: $box_shadow;
      }
      h3 {
        color: $color_black;
        font-size: 16px;
        margin-top: 16px;
        .today-title {
          font-size: 24px;
          font-weight: $font_weight_bold;
          vertical-align: bottom;
          margin-right: 4px;
        }
        .num {
          font-family: $number_font_family;
          font-size: 30px;
          font-weight: $font_weight_number_bold;
        }
      }
      .icon {
        margin-top: 8px;
        img {
          display: block;
          width: 144px;
          height: 72px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .info {
        color: $color_light_gray;
        font-size: 18px;
        margin-top: 12px;
      }
      .holiday {
        color: $color_blue;
        font-size: 16px;
        font-weight: $font_weight_bold;
        margin-top: 10px;
      }
    }
  }
  &.compressed {
    .list-weather {
      float: left;
      width: 360px;
      li {
        display: none;
        position: relative;
        h3, p {
          display: none;
        }
        &:nth-child(1) {
          display: block;
          z-index: 5;
          background-color: rgba(255, 255, 255, 1.0);
          h3, p {
            display: block;
          }
        }
        &:nth-child(2) {
          display: block;
          z-index: 4;
          background-color: rgba(255, 255, 255, 0.5);
          margin-left: -28px;
          h3, p {
            display: block;
          }
        }
        &:nth-child(3) {
          display: block;
          z-index: 3;
          background-color: rgba(255, 255, 255, 0.5);
          margin-left: -163px;
        }
        &:nth-child(4) {
          display: block;
          z-index: 2;
          background-color: rgba(255, 255, 255, 0.3);
          margin-left: -170px;
        }
        &:nth-child(5) {
          display: block;
          z-index: 1;
          background-color: rgba(255, 255, 255, 0.15);
          margin-left: -174px;
        }
      }
    }
  }
}
</style>
