<template>
    <AnalyticsGraphWrapper class="analytics-graph-wrapper">
        <h4>
            <a href="" @click.prevent="$emit('open')"><img :class="{ 'is-open': isOpen }" class="icon" src="/images/icon_accordion_open.svg" /></a
            >天気予報
        </h4>
        <ul class="analytics-weather-timeline">
            <li v-for="n in num" :key="n">
                <TimelineWeatherItem :weather="weatherList[n - 1]" :mode="mode" />
            </li>
        </ul>
    </AnalyticsGraphWrapper>
</template>

<script>
import AnalyticsGraphWrapper from '@/components/analytics/AnalyticsGraphWrapper.vue';
import TimelineWeatherItem from '@/components/TimelineWeatherItem.vue';

export default {
    name: 'AnalyticsWeather',

    components: {
        AnalyticsGraphWrapper,
        TimelineWeatherItem,
    },

    props: {
        mode: {
            type: String,
            required: true,
        },
        leftSide: {
            type: Object,
            required: true,
        },
        num: {
            type: Number,
            required: true,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        weatherList: function () {
            return this.$store.getters.getAnalyticsWeatherList(this.mode, this.leftSide, this.num);
        },
    },
};
</script>

<style scoped lang="scss">
.analytics-graph-wrapper {
    h4 {
        margin-left: -14px;
        a {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-right: 6px;
            position: relative;
            top: -6px;
            .icon {
                width: 100%;
                height: 100%;
                transition: all 300ms 0s ease;
                transform: rotate(0deg);
                &.is-open {
                    transform: rotate(90deg);
                }
            }
        }
    }
    .analytics-weather-timeline {
        @include flex_between;
        margin-left: 90px;
        margin-top: 12px;
        width: calc(100% - 165px);
    }
    & :deep(.analytics-weather-timeline) {
        min-height: 150px;
        li {
            width: 80px;
            margin-top: 8px;
            margin-bottom: 12px;
            &.prediction {
                opacity: 0.3;
            }
            .weather {
                .icon {
                    margin-left: -10px;
                }
                &.day {
                    .icon {
                        margin-left: 0;
                    }
                }
                .temp,
                .temp span,
                .rain,
                .wind,
                .humi {
                    color: $color_black;
                    margin-left: 7px;
                    padding-left: 0px;
                }
                .temp span {
                    margin-left: 0;
                }
                .temp,
                .temp span {
                    font-size: 18px;
                    white-space: nowrap;
                }
                .rain {
                    font-size: 18px;
                }
                .temp,
                .rain {
                    background-color: transparent;
                }
            }
        }
    }
}
.analytics-event-timeline {
    > li {
        width: 80px;
        margin-top: 13px;
        margin-bottom: 13px;
        .event {
            li {
                color: $color_black;
                font-size: 16px;
                margin-left: 7px;
                margin-bottom: 6px;
            }
        }
    }
}
</style>
