<template>
  <header v-if="isDebug">
    <div class="container">
      <div class="tablet">
        <nav>
          <ul>
            <li v-for="nav in tabletNavs" :key="nav.id" :class="{ selected: nav.id == current, home: nav.id == 'top' }">
              <router-link :to="nav.path"><img :src="'/images/' + nav.icon">{{ nav.title }}</router-link>
            </li>
          </ul>
        </nav>
        <p class="profile" :class="{ selected: current == 'mypage' }" @click="onProfileClicked">
          <img :src="'/images/' + shop.icon"><span class="name">{{ userData ? userData.customer_name : '' }}</span>
        </p>
      </div>
      <div class="sp">
        <p class="btn-menu"><a href="" @click.prevent="onMenuButtonClicked"><img src="/images/icon_menu.svg"></a></p>
        <h2 v-if="currentSpNav"><img :src="'/images/' + currentSpNav.icon">{{ currentSpNav.title }}</h2>
        <div class="menu" :class="{ 'is-open': isOpen }">
          <p class="btn-close"><a href="" @click.prevent="onCloseButtonClicked"><img src="/images/icon_close_white.svg"></a></p>
          <h2>メニュー</h2>
          <nav>
            <ul>
              <li v-for="nav in spNavs" :key="nav.id">
                <router-link :to="nav.path" @click.native="onListItemCliked"><img :src="'/images/' + nav.icon">{{ nav.title }}</router-link>
              </li>
              <li><router-link to="/" @click.native="onListItemCliked"><img :src="'/images/' + shop.icon">マイページ</router-link></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',

  props: {
    current: String
  },

  data: function() {
    return {
      isOpen: false,

      tabletNavs: [
        {
          id: 'top',
          path: '/',
          title: '',
          icon: 'icon_header_home.svg'
        },
        {
          id: 'calendar',
          path: '/calendar',
          title: '天気/催事',
          icon: 'icon_header_weather.svg'
        },
        {
          id: 'analytics',
          path: '/analytics',
          title: '店舗分析/カメラ',
          icon: 'icon_header_analytics.svg'
        },
        {
          id: 'shopping',
          path: '/shopping',
          title: '備品購入',
          icon: 'icon_header_shopping.svg'
        },
        {
          id: 'subsidy',
          path: '/subsidy',
          title: '補助金/助成金',
          icon: 'icon_header_subsidy.svg'
        },
        {
          id: 'info',
          path: '/info',
          title: 'サービス',
          icon: 'icon_header_info.svg'
        },
        {
          id: 'support',
          path: '/support',
          title: 'よくある質問',
          icon: 'icon_header_question.svg'
        },
      ],

      spNavs: [
        {
          id: 'top',
          path: '/',
          title: 'トップ',
          icon: 'icon_header_home.svg'
        },
        {
          id: 'calendar',
          path: '/calendar',
          title: '天気/催事カレンダー',
          icon: 'icon_header_weather.svg'
        },
        {
          id: 'shopping',
          path: '/shopping',
          title: '買い物',
          icon: 'icon_header_shopping.svg'
        },
        {
          id: 'info',
          path: '/info',
          title: 'インフォメーション',
          icon: 'icon_header_info.svg'
        },
        {
          id: 'support',
          path: '/support',
          title: 'サポート',
          icon: 'icon_header_question.svg'
        },
      ]
    };
  },

  computed: {
    isDebug: function() {
      return this.$store.state.isDebug;
    },
    shop: function() {
      return this.$store.state.shop;
    },
    userData: function() {
      return this.$store.getters.getUserData;
    },
    currentSpNav: function() {
      return this.spNavs.find(nav => nav.id === this.current);
    }
  },

  methods: {
    onMenuButtonClicked: function() {
      this.isOpen = true;
    },

    onCloseButtonClicked: function() {
      this.isOpen = false;
    },

    onListItemCliked: function() {
      this.isOpen = false;
    },

    onProfileClicked: function() {
      this.$router.push('/mypage').catch(()=>{});
    },
  },
};
</script>

<style scoped lang="scss">
header {
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22)), #3DA0C7;
  padding-top: 20px;
  height: 60px;
  position: relative;
  z-index: 2;
  nav {
    float: left;
    margin-left: 16px;
    ul {
      height: 60px;
      li {
        float: left;
        height: 44px;
        margin-top: 8px;
        line-height: 44px;
        margin-left: 4px;
        margin-right: 4px;
        a {
          display: block;
          color: $color_white;
          font-weight: $font_weight_bold;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 3px;
          img {
            width: 40px;
            height: 40px;
            margin-right: 2px;
            margin-top: 2px;
          }
        }
        &.home {
          a {
            width: 54px;
            text-align: center;
            img {
              margin-right: 0;
            }
          }
        }
        &.selected {
          a {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
  .profile {
    float: right;
    margin-top: 8px;
    margin-right: 10px;
    margin-bottom: 8px;
    height: 44px;
    border-radius: 3px;
    padding-left: 2px;
    padding-right: 10px;
    cursor: pointer;
    img {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      margin-top: 2px;
    }
    .name {
      color: $color_white;
      font-size: 16px;
      line-height: 40px;
      margin-top: 2px;
    }
    &.selected {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

@include sp-screen {
  header {
    .menu {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100vh;
      background: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22)), url("/images/bg_gradient.png"), #3DA0C7;
      background-repeat: repeat-x;
      h2 {
        margin-top: 20px;
      }
      &.is-open {
        display: block;
      }
    }
    .btn-close, .btn-menu {
      position: absolute;
      top: 30px;
      left: 10px;
      width: 40px;
      height: 40px;
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    h2 {
      height: 60px;
      line-height: 60px;
      color: $color_white;
      font-size: 18px;
      font-weight: $font_weight_bold;
      text-align: center;
      img {
        width: 40px;
        height: 40px;
        margin-top: 8px;
        margin-right: 2px;
      }
    }
    nav {
      float: none;
      padding-top: 0;
      margin-left: 40px;
      ul {
        height: auto;
        li {
          float: none;
          height: 90px;
          margin-top: 0;
          line-height: 90px;
          margin-left: 0;
          margin-right: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          a {
            font-size: 20px;
            padding-left: 0;
            padding-right: 0;
            img {
              width: 50px;
              height: 50px;
              margin-right: 20px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
