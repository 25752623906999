<template>
  <div class="error">
    <div class="base">
      <h2>問題が発生したため、ただいまアプリをご利用いただけません</h2>
      <p>
        サービスに関するお問い合わせをお受けいたしております。<br>
        USENインフォメーションセンターまでお問い合わせください。
      </p>
      <div class="info">
        <p class="title">USENインフォメーションセンター</p>
        <p class="phone">0120-117-440</p>
        <p class="sub">9:00～22:30 (年中無休)</p>
      </div>
      <div class="info">
        <p class="title">USENインフォメーションセンター お問い合わせフォーム</p>
        <p class="btn-gradient"><a href="" @click.prevent="onClicked">お問い合わせフォーム</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',

  methods: {
    onClicked() {

    }
  }
};
</script>

<style scoped lang="scss">
.error {
  @include flex_center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .base {
    text-align: center;
    background-color: $color_white;
    border-radius: 10px;
    width: 800px;
    max-width: calc(100% - 20px);
    padding-bottom: 20px;
    box-shadow: $box_shadow;
    h2 {
      font-size: 20px;
      font-weight: $font_weight_bold;
      margin-top: 50px;
      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
    p {
      font-size: 16px;
      padding-left: 20px;
      padding-right: 20px;
    }
    > p {
      margin-bottom: 30px;
    }
    .info {
      background-color: rgb(238, 238, 238);
      border-radius: 8px;
      color: $color_light_blue;
      width: 510px;
      max-width: calc(100% - 20px);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      overflow: hidden;
      .title {
        font-weight: $font_weight_bold;
        margin-top: 16px;
        margin-bottom: 20px;
      }
      .phone {
        font-size: 30px;
        font-weight: $font_weight_bold;
        font-family: 'HisaginoSans';
      }
      .sub {
        font-size: 16px;
        margin-bottom: 30px;
      }
      .btn-gradient {
        @include btn-common;
        @include btn-gradient;
        width: 260px;
        margin-top: 0;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
</style>
