<template>
  <div>
    <Header current="mypage"/>
    <div class="container">
      <div class="content-wrapper">
        <MypageMenu tag="payment" :payView="payView"/>
        <div class="content">
          <MypageHeader/>
          <div class="payments">
            <div class="row header">
              <p class="title">お振込予定日</p>
              <p class="date">{{ payment.PAYMENT_DATE }}</p>
              <p class="amount">{{ formatAmount(payment.PAY_AMT) }}</p>
            </div>
            <div class="row start">
              <p class="title">指定金融機関</p>
              <p class="value">{{ payment.BANK_NAME }}</p>
            </div>
            <div class="row start">
              <p class="title">金融機関支店名</p>
              <p class="value">{{ payment.BANK_BRANCH_NAME }}</p>
            </div>
            <div class="row start">
              <p class="title">口座種類</p>
              <p class="value">{{ payment.ACC_DIV === '1' ? '普通' : '' }}</p>
            </div>
            <div class="row start">
              <p class="title">口座番号</p>
              <p class="value">{{ payment.ACC_NO }}</p>
            </div>
            <div class="row start">
              <p class="title">口座名義</p>
              <p class="value">{{ payment.ACC_KNAME }}</p>
            </div>
            <div class="row statement-header">
              <p class="title">カード種類</p>
              <p class="item-header">取扱期間</p>
              <p class="item-header">取引件数</p>
              <p class="item-header">売上金額</p>
              <p class="item-header">料率</p>
              <p class="item-header">決済手数料</p>
              <p class="item-header">金額</p>
            </div>
            <div class="row" v-if="payment.CARD_NAME1">
              <p class="title">{{ payment.CARD_NAME1 }}</p>
              <p class="item">{{ payment.HANDL_PERIOD1 }}</p>
              <p class="item">{{ payment.TRAS_CNT1 }}</p>
              <p class="item">{{ formatAmount(payment.SALS_AMT1) }}</p>
              <p class="item">{{ payment.SLMT_COMM_RATE1 }}%</p>
              <p class="item">{{ formatAmount(payment.SLMT_COMM1) }}</p>
              <p class="item">{{ formatAmount(payment.AMT1) }}</p>
            </div>
            <div class="row" v-if="payment.CARD_NAME2">
              <p class="title">{{ payment.CARD_NAME2 }}</p>
              <p class="item">{{ payment.HANDL_PERIOD2 }}</p>
              <p class="item">{{ payment.TRAS_CNT2 }}</p>
              <p class="item">{{ formatAmount(payment.SALS_AMT2) }}</p>
              <p class="item">{{ payment.SLMT_COMM_RATE2 }}%</p>
              <p class="item">{{ formatAmount(payment.SLMT_COMM2) }}</p>
              <p class="item">{{ formatAmount(payment.AMT2) }}</p>
            </div>
            <div class="row" v-if="payment.CARD_NAME3">
              <p class="title">{{ payment.CARD_NAME3 }}</p>
              <p class="item">{{ payment.HANDL_PERIOD3 }}</p>
              <p class="item">{{ payment.TRAS_CNT3 }}</p>
              <p class="item">{{ formatAmount(payment.SALS_AMT3) }}</p>
              <p class="item">{{ payment.SLMT_COMM_RATE3 }}%</p>
              <p class="item">{{ formatAmount(payment.SLMT_COMM3) }}</p>
              <p class="item">{{ formatAmount(payment.AMT3) }}</p>
            </div>
            <div class="row" v-if="payment.CARD_NAME4">
              <p class="title">{{ payment.CARD_NAME4 }}</p>
              <p class="item">{{ payment.HANDL_PERIOD4 }}</p>
              <p class="item">{{ payment.TRAS_CNT4 }}</p>
              <p class="item">{{ formatAmount(payment.SALS_AMT4) }}</p>
              <p class="item">{{ payment.SLMT_COMM_RATE4 }}%</p>
              <p class="item">{{ formatAmount(payment.SLMT_COMM4) }}</p>
              <p class="item">{{ formatAmount(payment.AMT4) }}</p>
            </div>
            <div class="row" v-if="payment.CARD_NAME5">
              <p class="title">{{ payment.CARD_NAME5 }}</p>
              <p class="item">{{ payment.HANDL_PERIOD5 }}</p>
              <p class="item">{{ payment.TRAS_CNT5 }}</p>
              <p class="item">{{ formatAmount(payment.SALS_AMT5) }}</p>
              <p class="item">{{ payment.SLMT_COMM_RATE5 }}%</p>
              <p class="item">{{ formatAmount(payment.SLMT_COMM5) }}</p>
              <p class="item">{{ formatAmount(payment.AMT5) }}</p>
            </div>
            <div class="row detail-top">
              <p class="title wide">決済手数料にかかる消費税</p>
              <p class="amount">{{ formatAmount(payment.SLMT_COMM_TAX_USEN) }}</p>
            </div>
            <div class="row">
              <p class="title wide">前回未精算金額</p>
              <p class="amount">{{ formatAmount(payment.RETURN_OFFSET_AMT) }}</p>
            </div>
            <div class="row">
              <p class="title wide">振込手数料</p>
              <p class="amount">{{ formatAmount(payment.TRAS_COMM) }}</p>
            </div>
            <div class="row">
              <p class="title wide">振込手数料にかかる消費税</p>
              <p class="amount">{{ formatAmount(payment.TRAS_COMM_TAX) }}</p>
            </div>
            <div class="row">
              <p class="title wide">差し引きお振込金額</p>
              <p class="amount">{{ formatAmount(payment.PAY_AMT) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import MypageMenu from '@/components/menu/MypageMenu.vue';
import MypageHeader from '@/components/mypage/MypageHeader.vue';
import setparam from '@/mixin/setparam.js';
import mypageapicall from '@/mixin/mypageapicall.js';

export default {
  name: 'UPayPaymentDetail',

  components: {
    Header,
    MypageMenu,
    MypageHeader,
  },

  computed: {
    payment: function() {
      return this.$store.getters.getPayment(this.$route.params.type, this.$route.params.date);
    }
  },

  methods: {
    formatDate: function(date) {
      return date.substr(0, 4) + '年' + date.substr(4, 2) + '月' + date.substr(6, 2) + '日';
    },
    formatAmount: function(amount) {
      return '¥' + String(amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
  },
    mixins: [setparam, mypageapicall],
};
</script>

<style scoped lang="scss">
.payments {
  margin-top: 46px;
  margin-left: 108px;
  margin-right: 28px;
  margin-bottom: 46px;
  border-bottom: 1px solid rgb(196, 196, 196);
  padding-bottom: 6px;
  .row {
    @include flex_between;
    &.header {
      border-bottom: 2px solid $color_black;
      padding-bottom: 20px;
      margin-bottom: 16px;
      .date, .amount {
        font-size: 26px;
      }
    }
    &.statement-header {
      margin-top: 50px;
      border-top: 2px solid $color_black;
      border-bottom: 1px solid rgb(196, 196, 196);
      margin-bottom: 6px;
    }
    &.detail-top {
      border-top: 1px solid rgb(196, 196, 196);
      margin-top: 6px;
      padding-top: 6px;
    }
    &.start {
      justify-content: flex-start;
    }
    height: 50px;
    line-height: 50px;
    .title, .amount, .value, .item, .item-header {
      font-size: 16px;
    }
    .title, .item-header {
      color: rgb(87, 87, 87);
    }
    .title {
      flex-grow: 0;
      width: 150px;
      &.wide {
        width: 200px;
      }
    }
    .date {
      flex-grow: 1;
    }
    .item, .item-header {
      width: 100px;
    }
    .amount, .item, .value, .date {
      color: $color_black;
      font-weight: $font_weight_bold;
    }
    .title, .value, .date {
      text-align: left;
    }
    .amount, .item, .item-header {
      text-align: right;
    }
  }
}
</style>
