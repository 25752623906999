<template>
  <div>
    <Header current="analytics"/>
    <div class="container">
      <div class="content-wrapper">
        <AnalyticsMenu tag="visitors-prediction" :largeBizCd="largeBizCd" :visitorView="visitorView"/>
        <div class="content">
          <AnalyticsHeader :isPrediction="true" title="来客数の予測（β版）" :mode="$route.params.mode"/>
          <div class="content-analytics">
            <AnalyticsAxis :isPrediction="true" :mode="$route.params.mode" :leftSide="leftSide" :num="axisNum" @update="onAxisUpdated"/>
            <AnalyticsSalesGraph :mode="$route.params.mode" :num="axisNum"/>
            <AnalyticsGenderGraph :isPrediction="true" :mode="$route.params.mode"/>
            <AnalyticsRepeaterGraph :isPrediction="true" :mode="$route.params.mode"/>
            <AnalyticsWeather v-if="isWeatherShown" :mode="$route.params.mode" :leftSide="leftSide" :num="axisNum"/>
            <AnalyticsEvent v-if="isWeatherShown" :mode="$route.params.mode" :leftSide="leftSide" :num="axisNum"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mixin from '@/mixin/index.js';
import analyticsMixin from '@/mixin/analytics.js';
import analyticssubmenu from '@/mixin/analyticssubmenu.js';
import Header from '@/components/Header.vue';
import AnalyticsMenu from '@/components/menu/AnalyticsMenu.vue';
import AnalyticsHeader from '@/components/analytics/AnalyticsHeader.vue';
import AnalyticsAxis from '@/components/analytics/AnalyticsAxis.vue';
import AnalyticsSalesGraph from '@/components/analytics/AnalyticsSalesGraph.vue';
import AnalyticsGenderGraph from '@/components/analytics/AnalyticsGenderGraph.vue';
import AnalyticsRepeaterGraph from '@/components/analytics/AnalyticsRepeaterGraph.vue';
import AnalyticsWeather from '@/components/analytics/AnalyticsWeather.vue';
import AnalyticsEvent from '@/components/analytics/AnalyticsEvent.vue';

export default {
  name: 'VisitorsPrediction',

  components: {
    Header,
    AnalyticsMenu,
    AnalyticsHeader,
    AnalyticsAxis,
    AnalyticsSalesGraph,
    AnalyticsGenderGraph,
    AnalyticsRepeaterGraph,
    AnalyticsWeather,
    AnalyticsEvent,
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },
    largeBizCd: function() {
      return this.auth.user ? this.auth.user.customer_large_bizcd : '';
    },
    isWeatherShown: function() {
      return this.$route.params.mode === this.modeType.HOUR || this.$route.params.mode === this.modeType.DAY;
    }
  },

  created: function() {
    this.leftSide = this.getInitialLeftSide(Math.floor(this.axisNum / 2));
  },

  watch: {
    mode: function() {
      this.leftSide = this.getInitialLeftSide(Math.floor(this.axisNum / 2));
    }
  },

  mixins: [mixin, analyticsMixin, analyticssubmenu]
};
</script>

<style scoped lang="scss">
.content-analytics {
  position: relative;
  margin-bottom: 40px;
}
</style>
