var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", { attrs: { current: "mypage" } }),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "content-wrapper" },
          [_c("MypageMenu", { attrs: { tag: "shopinfo" } }), _vm._m(0)],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content mypage" }, [
      _c("h2", [_vm._v("登録変更を申請中です。")]),
      _c("p", { staticClass: "message" }, [
        _vm._v(" USENで登録変更を手続きしています。"),
        _c("br"),
        _vm._v(" もうしばらくおまちください。 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }