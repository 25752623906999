export default {
  dev: {
    apiBaseUrl: 'https://api-stg.ueyes.usen.com',
    apiVisitorsBaseUrl: 'https://pp9hbhxu7b.execute-api.ap-southeast-1.amazonaws.com/v1',
    apiKey: 'JadUq72pR44wlPDXJAsjq4b55L8AGinE8i9lwIeg',
  },
  stg: {
    apiBaseUrl: 'https://api-stg.ueyes.usen.com',
    apiVisitorsBaseUrl: 'https://pp9hbhxu7b.execute-api.ap-southeast-1.amazonaws.com/v1',
    apiKey: 'JadUq72pR44wlPDXJAsjq4b55L8AGinE8i9lwIeg',
  },
  prod:{
    apiBaseUrl: 'https://api.ueyes.usen.com',
    apiVisitorsBaseUrl: 'https://api.ueyes.usen.com',
    apiKey: 'Tl0IFDlgkE62UoReizoyy6xf1emN5puf7U66LOnw',
  }
}
