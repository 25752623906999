<template>
    <AnalyticsGraphWrapper>
        <h4 class="info" v-if="showSales" @click="viewHint('sales')"><img class="info-img" src="/images/icon_info.png" />売上</h4>
        <div v-if="showSales" class="control sales"></div>
        <p v-if="showSales">
            <LineChart :height="140" :chartData="chartData" :options="options" />
        </p>
        <div v-if="visitorOption" class="list-vistors">
            <h4>来客人数</h4>
            <ul>
                <li v-for="n in num" :key="n">{{ (visitorsValueList[n - 1] ? Number(visitorsValueList[n - 1]).toLocaleString() : 0) + '人' }}</li>
            </ul>
        </div>
        <div class="list-vistors">
            <h4 class="info" @click="viewHint('faceDetect')"><img class="info-img" src="/images/icon_info.png" />顔認識数</h4>
            <ul>
                <li v-for="n in num" :key="n">
                    {{ (visitorsKnownValueList[n - 1] ? Number(visitorsKnownValueList[n - 1]).toLocaleString() : 0) + '人' }}
                </li>
            </ul>
        </div>
    </AnalyticsGraphWrapper>
</template>

<script>
import mixin from '@/mixin/index.js';
import AnalyticsGraphWrapper from '@/components/analytics/AnalyticsGraphWrapper.vue';
import LineChart from '@/components/analytics/LineChart.vue';

export default {
    name: 'AnalyticsSalesGraph',

    components: {
        AnalyticsGraphWrapper,
        LineChart,
    },

    props: {
        mode: {
            type: String,
            required: true,
        },
        leftSide: {
            type: Object,
            required: true,
        },
        num: {
            type: Number,
            required: true,
        },
        visitorOption: {
            type: Boolean,
            required: true,
        }
    },

    data() {
        return {
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {
                                display: false,
                            },
                            ticks: {
                                min: 0,
                                callback: function (label) {
                                    return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                },
                            },
                        },
                    ],
                },
                layout: {
                    padding: {
                        left: 20,
                        right: 120,
                        top: 0,
                        bottom: 0,
                    },
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem) =>{
                            return [tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')]
                        }
                    },
                    titleFontSize: 17,
                    titleSpacing: 10,
                    titleMarginBottom: 10,
                    bodyFontSize: 17,
                    bodySpacing: 10,
                    footerFontSize: 17,
                    footerSpacing: 10,
                    footerMarginTop: 10,
                    xPadding: 10,
                    yPadding: 10,
                    caretPadding: 12,
                },
            },
        };
    },
    computed: {
        modeType: function () {
            return this.$store.state.analyticsModeType;
        },
        showSales: function () {
            switch (this.mode) {
                case this.modeType.HOUR:
                    return false;
                default:
                    return true;
            }
        },
        chartData: function () {
            return {
                labels: Array(this.num).fill(''),
                datasets: [
                    {
                        label: '',
                        borderWidth: 3,
                        backgroundColor: 'rgba(100, 203, 147, 0.2)',
                        borderColor: 'rgb(100, 203, 147)',
                        lineTension: 0,
                        pointHitRadius: 20,
                        data: this.salesValueList,
                    },
                ],
            };
        },
        salesValueList: function () {
            return this.$store.getters.getSalesValue(this.mode, this.leftSide, this.num);
        },
        visitorsValueList: function () {
            return this.$store.getters.getVisitorsTotal(this.mode, this.leftSide, this.num);
        },
        visitorsKnownValueList: function () {
            return this.$store.getters.getVisitorsKnownTotal(this.mode, this.leftSide, this.num);
        },
    },
    methods: {
        viewHint(kind) {
            this.$emit('viewHint', kind);
        },
    },

    mixins: [mixin],
};
</script>

<style scoped lang="scss">
.control {
    &.sales {
        margin-top: -10px;
    }
}
.info {
    cursor: pointer;
}
.info-img {
    width: 20px;
}
.list-vistors{
    position: relative;
    margin-top: -20px;
    // margin-bottom: 20px;
    ul {
        @include flex_between;
        justify-content: space-around;
        height: 50px;
        margin-left: 80px;
        width: calc(100% - 148px);
        li {
            width: 100px;
            text-align: center;
        }
    }
}
.list-vistors {
    margin-top: 3px;
    margin-bottom: 3px;
    ul {
        li {
            font-size: 20px;
            font-weight: $font_weight_bold;
            color: $color_black;
            line-height: 50px;
        }
    }
}
.list-day-sales {
    position: relative;
    margin-top: -50px;
    ul {
        @include flex_between;
        justify-content: space-around;
        margin-left: 80px;
        width: calc(100% - 148px);
        li {
            font-size: 16px;
            color: $color_black;
            line-height: 30px;
        }
    }
}
</style>
