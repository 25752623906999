<template>
    <div class="bg-color">
        <Header current="top" />
        <div class="container">
            <div class="content">
                <div class="bg"></div>
                <WeatherCalendarHeader :mode="mode" @change-mode="onChangeMode" />
                <WeatherCalendar :mode="mode" :date="date" :days="days" :now="now" />
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import WeatherCalendarHeader from '@/components/WeatherCalendarHeader.vue';
import WeatherCalendar from '@/components/WeatherCalendar.vue';
import mixin from '@/mixin/index.js';
import setparam from '@/mixin/setparam.js';

export default {
    name: 'Timeline',

    components: {
        Header,
        WeatherCalendarHeader,
        WeatherCalendar,
    },

    data() {
        return {
            mode: 'hour',
            days: 6,
        };
    },

    computed: {
        auth() {
            return this.$store.state.auth;
        },
        modeType() {
            return this.$store.state.analyticsModeType;
        },
        date() {
            return this.$route.params.year + this.$route.params.month + this.$route.params.date;
        },
        now() {
            const date = new Date();
            let hour = date.getHours();
            if (hour + 9 > 24) {
                hour = 24 - 9;
            }
            return hour;
        },
    },

    methods: {
        fetchWeatherDaily: async function () {
            const from = this.$route.params.year + this.$route.params.month + this.$route.params.date;
            const date = this.getDate(from);
            const toDate = this.getNDaysLater(date, this.days - 1);
            const to = this.getDateStr(toDate);
            await this.$store.dispatch('fetchWeather', { mode: 'hour', from, to });
        },
        fetchWeatherMonthly: async function () {
            const date = this.getDate(this.date);
            const from = this.getDateStr(this.getNDaysLater(date, -3));
            const to = this.getDateStr(this.getNDaysLater(date, 15));
            await this.$store.dispatch('fetchWeather', { mode: 'day', from, to });
        },
        onChangeMode: function (event) {
            this.mode = event;
            if (this.mode === this.modeType.DAY) {
                this.fetchWeatherMonthly();
            }
        },
    },

    created() {
        if (!this.auth.user) {
            return;
        }
        this.fetchWeatherDaily();
    },

    watch: {
        auth: {
            async handler() {
                if (this.auth.user != null) {
                    this.fetchWeatherDaily();
                }
            },
            deep: true,
        },
    },

    mixins: [mixin, setparam],
};
</script>

<style scoped lang="scss">
.content {
    z-index: 0;
    .bg {
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: -1;
        background: linear-gradient(to bottom, #177dd1, #489fdf);
    }
}
.bg-color {
    background: linear-gradient(to bottom, #177dd1, #489fdf);
}
</style>
