<template>
  <div class="header-analytics">
    <h2>{{ title }}</h2>
    <SelectBox title="期間選択：" :options="selectBoxOptions" :value="mode" @update="onUpdate" />
  </div>
</template>

<script>
import SelectBox from '@/components/SelectBox.vue';

export default {
  name: 'AnalyticsHeader',

  components: {
    SelectBox,
  },

  props: {
    isPrediction: {
      type: Boolean,
      default: false
    },
    isAverage: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },

  computed: {
    modeType: function() {
      return this.$store.state.analyticsModeType;
    },
    selectBoxOptions: function() {
      if (this.isAverage) {
        return [
          {
            value: this.modeType.WEEK,
            title: '１週間',
          },
          {
            value: this.modeType.MONTH,
            title: '１ヶ月',
          },
          {
            value: this.modeType.THREE,
            title: '３ヶ月',
          },
          {
            value: 'select',
            title: '期間選択',
          },
        ];
      } else {
        return [
          {
            value: this.modeType.HOUR,
            title: '１時間',
          },
          {
            value: this.modeType.DAY,
            title: '１日',
          },
          {
            value: this.modeType.WEEK,
            title: '１週間',
          },
          {
            value: this.modeType.MONTH,
            title: '１ヶ月',
          },
          {
            value: this.modeType.THREE,
            title: '３ヶ月',
          },
          {
            value: 'select',
            title: '期間選択',
          },
        ];
      }
    },
  },

  methods: {
    onListClicked: function(mode) {
      let baseUrl = '/analytics/myshop/visitors-and-sales/';
      if (this.isAverage) {
        baseUrl = '/analytics/myshop/visitors-average/';
      } else if (this.isPrediction) {
        baseUrl = '/analytics/myshop/visitors-prediction/';
      }
      if (mode !== this.mode) {
        this.$router.replace(baseUrl + mode);
      }
    },
    onIconClicked: function() {
      this.$emit('show-calendar');
    },
    onUpdate: function(event) {
      const value = event;
      if (value === 'select') {
        this.onIconClicked();
      } else {
        this.onListClicked(value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@mixin tab_items {
  padding-left: 10px;
  padding-right: 10px;
  a {
    display: inline-block;
    color: $color_black;
    font-size: 16px;
    line-height: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 3px;
    margin-top: 1px;
  }
  &.selected {
    a {
      color: $color_white;
      font-weight: $font_weight_bold;
      background-color: $color_light_blue;
    }
  }
}

.header-analytics {
  @include flex_between;
  align-items: center;
  height: 80px;
  margin-left: 20px;
  margin-right: 10px;
  h2 {
    font-size: 20px;
    font-weight: $font_weight_bold;
    line-height: 40px;
    .hint {
      margin-left: 30px;
      img {
        display: inline-block;
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>
