<template>
    <Menu>
        <div v-if="visitorView">
        <ul class="list-menu-function">
            <li v-for="menu in menuList.myShop" :key="menu.path" :class="{ selected: menu.tag === tag }">
                <p>
                    <router-link :to="menu.path">{{ menu.title }}</router-link>
                </p>
            </li>
        </ul>
        </div>
        <!-- 本番環境では他店舗分析をマスクする -->
        <p class="menu-title">他店舗分析/比較</p>
        <ul class="list-menu-analytics">
            <li v-for="menu in menuList.comparison" :key="menu.path" :class="{ selected: menu.tag === tag }">
                <p>
                    <router-link :to="menu.path">{{ menu.title }}</router-link>
                </p>
            </li>
        </ul>
    </Menu>
</template>

<script>
import Menu from '@/components/menu/Menu.vue';

export default {
    name: 'AnalyticsMenu',

    components: {
        Menu,
    },

    props: {
        largeBizCd: {
            type: String,
            required: true,
        },
        visitorView: {
            type: Boolean,
            required: true,
        },
        tag: {
            type: String,
            required: true,
        },
    },

    computed: {
        menuList() {
            const list = {
                myShop: [
                    {
                        path: '/analytics/myshop/visitors-and-sales/day',
                        tag: 'visitors-and-sales',
                        // title: '来客数と売上',
                        title: '来客数',
                    },
                    {
                        path: '/analytics/myshop/visitors-average/week',
                        tag: 'visitors-average',
                        title: '来客数の期間平均',
                    },
                    // {
                    //     path: '/analytics/myshop/visitors-prediction/day',
                    //     tag: 'visitors-prediction',
                    //     title: '来客数の予測（β版）',
                    // },
                ],
                comparison: [
                    {
                        path: '/analytics/shops/all',
                        tag: 'all',
                        title: '対象総店舗数',
                    },
                    {
                        path: '/analytics/shops/new',
                        tag: 'new',
                        title: '新規店舗数',
                    },
                    // {
                    //     path: '/analytics/shops/closed',
                    //     tag: 'closed',
                    //     title: '閉店店舗数',
                    // },
                ],
            };

            switch (this.largeBizCd) {
                case '10':
                    list.comparison = list.comparison.concat([
                        {
                            path: '/analytics/shops/restaurant/seats',
                            tag: 'seats',
                            title: '座席数別店舗数比率',
                        },
                        {
                            path: '/analytics/shops/restaurant/price/lunch',
                            tag: 'lunch',
                            title: '平均予算（ランチ）',
                        },
                        {
                            path: '/analytics/shops/restaurant/price/dinner',
                            tag: 'dinner',
                            title: '平均予算（ディナー）',
                        },
                        // {
                        //     path: '/analytics/shops/restaurant/takeout',
                        //     tag: 'takeout',
                        //     title: 'テイクアウトの有無',
                        // },
                        {
                            path: '/analytics/shops/restaurant/holiday',
                            tag: 'holiday',
                            title: '定休日',
                        },
                        {
                            path: '/analytics/shops/restaurant/hours/opening',
                            tag: 'opening',
                            title: '営業開始時間',
                        },
                        {
                            path: '/analytics/shops/restaurant/hours/closing',
                            tag: 'closing',
                            title: '営業終了時間',
                        },
                        {
                            path: '/analytics/shops/restaurant/card',
                            tag: 'card',
                            title: 'クレカ使用可否',
                        },
                        {
                            path: '/analytics/shops/restaurant/payment',
                            tag: 'payment',
                            title: '支払い方法',
                        },
                        {
                            path: '/analytics/shops/restaurant/years',
                            tag: 'years',
                            title: '営業年数',
                        },
                        {
                            path: '/analytics/shops/restaurant/parking',
                            tag: 'parking',
                            title: '駐車場の有無',
                        },
                        // {
                        //     path: '/analytics/shops/restaurant/private',
                        //     tag: 'private',
                        //     title: '個室の有無',
                        // },
                        {
                            path: '/analytics/shops/restaurant/smoking',
                            tag: 'smoking',
                            title: '喫煙可',
                        },
                        // {
                        //     path: '/analytics/shops/restaurant/info',
                        //     tag: 'info',
                        //     title: 'コロナ対策（お知らせ）',
                        // },
                    ]);
                    break;

                case '20':
                    list.comparison = list.comparison.concat([
                        {
                            path: '/analytics/shops/beauty/seats',
                            tag: 'seats',
                            title: '座席数別店舗数比率',
                        },
                        {
                            path: '/analytics/shops/beauty/price/cut',
                            tag: 'cut',
                            title: '平均単価（カット料金）',
                        },
                        {
                            path: '/analytics/shops/beauty/holiday',
                            tag: 'holiday',
                            title: '定休日',
                        },
                        {
                            path: '/analytics/shops/beauty/hours/opening',
                            tag: 'opening',
                            title: '営業開始時間',
                        },
                        {
                            path: '/analytics/shops/beauty/hours/closing',
                            tag: 'closing',
                            title: '営業終了時間',
                        },
                        {
                            path: '/analytics/shops/beauty/card',
                            tag: 'card',
                            title: 'クレカ使用可否',
                        },
                        {
                            path: '/analytics/shops/beauty/payment',
                            tag: 'payment',
                            title: '支払い方法',
                        },
                        {
                            path: '/analytics/shops/beauty/obsession',
                            tag: 'obsession',
                            title: 'こだわり条件',
                        },
                    ]);
                    break;

                default:
                    break;
            }
            return list;
        },
    },
};
</script>
