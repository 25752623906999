<template>
  <div>
    <Header current="analytics"/>
    <div class="container">
      <div class="content-wrapper">
        <AnalyticsMenu tag="visitors-average" :largeBizCd="largeBizCd" :visitorView="visitorView"/>
        <div class="content">
          <AnalyticsHeader :isAverage="true" title="来客数の期間平均" :mode="$route.params.mode" @show-calendar="isDatePickerDialogShown = true" />
          <div class="content-analytics">
            <AnalyticsAxis :mode="$route.params.mode" :leftSide="leftSide" :num="axisNum" :start="startDate" :end="endDate" @update="onAxisUpdated" />
            <AnalyticsSalesGraph :mode="$route.params.mode" :leftSide="leftSide" :num="axisNum" />
            <AnalyticsGenderGraph :mode="$route.params.mode" :leftSide="leftSide" :num="axisNum" graphType="Line" />
            <AnalyticsRepeaterGraph :mode="$route.params.mode" :leftSide="leftSide" :num="axisNum" graphType="Line" />
          </div>
        </div>
      </div>
      <DatePickerDialog v-if="isDatePickerDialogShown" @cancel="isDatePickerDialogShown = false" @next="onDatePickerNext" />
      <IntervalPickerDialog v-if="isIntervalPickerDialogShown" :hideDayMode="true" :fromDate="fromDate" :toDate="toDate" @cancel="isIntervalPickerDialogShown = false" @done="onIntervalPickerDone" @back="onIntervalDialogBack" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mixin from '@/mixin/index.js';
import analyticsMixin from '@/mixin/analytics.js';
import setparam from '@/mixin/setparam.js';
import apicall from '@/mixin/apicall.js';
import analyticssubmenu from '@/mixin/analyticssubmenu.js';
import Header from '@/components/Header.vue';
import AnalyticsMenu from '@/components/menu/AnalyticsMenu.vue';
import AnalyticsHeader from '@/components/analytics/AnalyticsHeader.vue';
import AnalyticsAxis from '@/components/analytics/AnalyticsAxis.vue';
import AnalyticsSalesGraph from '@/components/analytics/AnalyticsSalesGraph.vue';
import AnalyticsGenderGraph from '@/components/analytics/AnalyticsGenderGraph.vue';
import AnalyticsRepeaterGraph from '@/components/analytics/AnalyticsRepeaterGraph.vue';
import DatePickerDialog from '@/components/dialog/DatePickerDialog.vue';
import IntervalPickerDialog from '@/components/dialog/IntervalPickerDialog.vue';

export default {
  name: 'VisitorsAverage',

  components: {
    Header,
    AnalyticsMenu,
    AnalyticsHeader,
    AnalyticsAxis,
    AnalyticsSalesGraph,
    AnalyticsGenderGraph,
    AnalyticsRepeaterGraph,
    DatePickerDialog,
    IntervalPickerDialog,
  },

  data() {
    return {
      isDatePickerDialogShown: false,
      isIntervalPickerDialogShown: false,
      fromDate: new Date(),
      toDate: new Date(),
    };
  },

  computed: {
    // envstg: function(){
    //     return process.env.NODE_ENV==="stg" ? true : false;
    // },
    today: function() {
      return this.getDateObj(new Date());
    },
    auth() {
      return this.$store.state.auth;
    },
    largeBizCd: function() {
      return this.auth.user ? this.auth.user.customer_large_bizcd : '';
    },
  },

  methods: {
    onDatePickerNext(e) {
      this.fromDate = e.fromDate;
      this.toDate = e.toDate;
      this.isDatePickerDialogShown = false;
      this.isIntervalPickerDialogShown = true;
    },

    onIntervalPickerDone(e) {
      const from = this.getDateStr(e.fromDate);
      const to = this.getDateStr(e.toDate);
      this.$router.push('/analytics/myshop/visitors-average/' + e.mode + '?from=' + from + '&to=' + to);
      this.isIntervalPickerDialogShown = false;
    },

    onIntervalDialogBack() {
      this.isIntervalPickerDialogShown = false;
      this.isDatePickerDialogShown = true;
    }
  },

  async created() {
    this.leftSide = this.getInitialLeftSide(this.axisNum - 1);
  },

  watch: {
    mode: function() {
      this.leftSide = this.getInitialLeftSide(this.axisNum - 1);
    },
    '$route': {
      handler() {
        if (this.$route.query.from != null && this.$route.query.to != null) {
          this.leftSide = this.getInitialLeftSide();
        }
      },
      deep: true
    }
  },

  mixins: [mixin, analyticsMixin, setparam, apicall,analyticssubmenu]
};
</script>

<style scoped lang="scss">
.content-analytics {
  position: relative;
  margin-bottom: 40px;
}
</style>
