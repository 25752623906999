export default {

  data() {
    return {
      largeBizCd: '',
      middleBizCd: '',
      smallBizCd: '',
      pref: '',
      city: '',
    };
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },
    areaCode: function () {
      return this.$store.getters.areaCode;
    },
    bizCode: function () {
      return this.$store.getters.bizCode;
    },
    shopSearchOptions: function() {
      return this.$store.state.shopSearchOptions;
    },
  },

  methods: {
    async fetchBizAndAreaCode() {
      await this.$store.dispatch('fetchBizCode');
      await this.$store.dispatch('fetchAreaCode');
    },

    resetStoreCount() {
      this.$store.dispatch('resetStoreCount', {
        graph: this.graph,
      });
    },

    resetRestaurantTrend() {
      this.$store.dispatch('resetRestaurantTrend', {
        graph: this.graph,
      });
    },

    resetBeautyTrend() {
      this.$store.dispatch('resetBeautyTrend', {
        graph: this.graph,
      });
    },

    async fetchStoreCount() {
      const today = new Date();
      const date = new Date(today.getFullYear(), today.getMonth(), 1);
      const to = this.getMonthStr(date);
      date.setMonth(date.getMonth() - (this.axisNum - 1));
      const from = this.getMonthStr(date);
      await this.$store.dispatch('fetchStoreCount', {
        pref_cd: this.pref,
        area_cd: this.city,
        middle_biz_cd: this.middleBizCd,
        category: this.category,
        graph: this.graph,
        from: from,
        to: to,
      });
    },

    async fetchRestaurantTrend() {
      await this.$store.dispatch('fetchRestaurantTrend', {
        date: this.getPrevMonth(),
        pref_cd: this.pref,
        area_cd: this.city,
        small_biz_cd: this.smallBizCd,
        category: this.category,
        graph: this.graph,
      });
    },

    async fetchBeautyTrend() {
      await this.$store.dispatch('fetchBeautyTrend', {
        date: this.getPrevMonth(),
        pref_cd: this.pref,
        area_cd: this.city,
        small_biz_cd: this.smallBizCd,
        category: this.category,
        graph: this.graph,
      });
    },
    getPrevMonth() {
      const dateObj = new Date();
      const timezoneOffset = dateObj.getTimezoneOffset() / 60 + 9;
      dateObj.setHours(dateObj.getHours() + timezoneOffset);
      dateObj.setMonth(dateObj.getMonth() -1 );
      const y = String(dateObj.getFullYear());
      const m = String(dateObj.getMonth() + 1).padStart(2, '0');
      return `${y}${m}`;
    },

    async resetTrend() {
      switch (this.category) {
        case '1':
          await this.resetStoreCount();
          break;
        case '2':
          await this.resetRestaurantTrend();
          break;
        case '3':
          await this.resetBeautyTrend();
          break;
        default:
          break;
      }
    },

    async fetchTrend() {
      switch (this.category) {
        case '1':
          await this.fetchStoreCount();
          break;
        case '2':
          await this.fetchRestaurantTrend();
          break;
        case '3':
          await this.fetchBeautyTrend();
          break;
        default:
          break;
      }
    },

    setCustomerInfo() {
      //そのカスタマのもっているエリアと業種で指定する
      if (this.shopSearchOptions != null) {
        this.largeBizCd = this.shopSearchOptions.largeBizCd;
        this.middleBizCd = this.shopSearchOptions.middleBizCd;
        this.smallBizCd = this.shopSearchOptions.smallBizCd;
        this.pref = this.shopSearchOptions.pref;
        this.city = this.shopSearchOptions.city;
      } else {
        const user = this.auth.user;
        this.largeBizCd = user.customer_large_bizcd;
        this.middleBizCd = user.customer_middle_bizcd;
        this.smallBizCd = user.customer_small_bizcd;
        this.pref = user.customer_area.slice(0, 2);
        this.city = user.customer_area.slice(2, 5);
      }
    }
  },

  async created() {
    if (this.auth.user == null) {
      return;
    }
    this.resetTrend();
    this.setCustomerInfo();
    await this.fetchBizAndAreaCode();
    await this.fetchTrend();
  },

  watch: {
    auth: {
      async handler() {
        if (this.auth.user != null) {
          this.setCustomerInfo();
          await this.fetchBizAndAreaCode();
          await this.fetchTrend();
        }
      },
      deep: true
    },
    smallBizCd: async function () {
      await this.fetchTrend();
    },
    city: async function () {
      await this.fetchTrend();
    },
  },
}
