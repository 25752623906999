var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Menu", [
    _vm.visitorView
      ? _c("div", [
          _c(
            "ul",
            { staticClass: "list-menu-function" },
            _vm._l(_vm.menuList.myShop, function (menu) {
              return _c(
                "li",
                { key: menu.path, class: { selected: menu.tag === _vm.tag } },
                [
                  _c(
                    "p",
                    [
                      _c("router-link", { attrs: { to: menu.path } }, [
                        _vm._v(_vm._s(menu.title)),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _c("p", { staticClass: "menu-title" }, [_vm._v("他店舗分析/比較")]),
    _c(
      "ul",
      { staticClass: "list-menu-analytics" },
      _vm._l(_vm.menuList.comparison, function (menu) {
        return _c(
          "li",
          { key: menu.path, class: { selected: menu.tag === _vm.tag } },
          [
            _c(
              "p",
              [
                _c("router-link", { attrs: { to: menu.path } }, [
                  _vm._v(_vm._s(menu.title)),
                ]),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }