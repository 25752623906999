<template>
  <AnalyticsGraphWrapper class="analytics-graph-wrapper">
    <h4>イベント</h4>
    <ul class="analytics-event-timeline">
      <li v-for="n in num" :key="n">
        <ul class="event">
          <li>{{ getEvent(n - 1) }}</li>
        </ul>
      </li>
    </ul>
  </AnalyticsGraphWrapper>
</template>

<script>
import AnalyticsGraphWrapper from '@/components/analytics/AnalyticsGraphWrapper.vue';
import mixin from '@/mixin/index.js';

export default {
  name: 'AnalyticsEvent',

  components: {
    AnalyticsGraphWrapper,
  },

  props: {
    mode: {
      type: String,
      required: true
    },
    leftSide: {
      type: Object,
      required: true
    },
    num: {
      type: Number,
      required: true
    },
  },

  computed: {
    modeType: function() {
      return this.$store.state.analyticsModeType;
    },
  },

  methods: {
    getEvent: function(index) {
      const date = this.getDate(this.leftSide);
      if (this.mode === this.modeType.HOUR) {
        date.setHours(date.getHours() + index);
      } else {
        date.setDate(date.getDate() + index);
      }
      return this.$store.getters.getEvent(this.getDateStr(date).substr(4, 4));
    }
  },

  mixins: [mixin]
};
</script>

<style scoped lang="scss">
.analytics-graph-wrapper {
  border-bottom: none;
}
.analytics-event-timeline {
  @include flex_between;
  margin-left: 90px;
  width: calc(100% - 165px);
}
.analytics-event-timeline {
  > li {
    width: 80px;
    margin-top: 13px;
    margin-bottom: 13px;
    .event {
      li {
        color: $color_black;
        font-size: 16px;
        margin-left: 7px;
        margin-bottom: 6px;
      }
    }
  }
}
</style>
