<template>
  <ul class="shop-info">
    <li v-if="shop.useUregi">
      <h3>昨日の売上</h3>
      <p class="value"><span class="num">{{ result.yesterday.sales }}</span> 万円</p>
      <p class="fig"><img src="/images/shopinfo_sample1.svg"></p>
    </li>
    <li v-else>
      <h3>一昨日の来客数</h3>
      <p class="value"><span class="num">{{ result.dayBeforeYesterday.visitors }}</span> 人</p>
      <p class="fig"><img src="/images/shopinfo_sample2.svg"></p>
    </li>
    <li>
      <h3>昨日の来客数</h3>
      <p class="value"><span class="num">{{ result.yesterday.visitors }}</span> 人</p>
      <p class="fig"><img src="/images/shopinfo_sample2.svg"></p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ShopInfo',

  data: function() {
    return {
      result: {
        dayBeforeYesterday: {
          visitors: 165
        },
        yesterday: {
          sales: 32,
          visitors: 158
        }
      }
    };
  },

  computed: {
    shop: function() {
      return this.$store.state.shop;
    },
  }
};
</script>

<style scoped lang="scss">
.shop-info {
  overflow: hidden;
  float: right;
  width: calc(100% - 470px);
  height: 248px;
  margin-top: 20px;
  margin-right: 20px;
  background-color: $color_white;
  border-radius: 4px;
  box-shadow: $box_shadow;
  li {
    float: left;
    width: calc((100% - 30px) / 2);
    height: 100%;
    h3, .value {
      color: $color_dark_gray;
      font-weight: $font_weight_bold;
    }
    h3 {
      float: left;
      font-size: 24px;
      margin-top: 16px;
      margin-bottom: 10px;
      padding-left: 14px;
      padding-right: 14px;
    }
    .value {
      float: right;
      font-size: 20px;
      vertical-align: bottom;
      margin-top: 16px;
      padding-left: 14px;
      padding-right: 14px;
      .num {
        font-family: $number_font_family;
        font-size: 30px;
        font-weight: $font_weight_bold;
      }
    }
    .fig {
      clear: both;
      width: calc(100% - 28px);
      height: 176px;
      padding-left: 14px;
      padding-right: 14px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &:first-child {
      margin-right: 30px;
    }
  }
}
</style>
