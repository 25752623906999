var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", { attrs: { current: "analytics" } }),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "content-wrapper" },
          [
            _c("AnalyticsMenu", {
              attrs: {
                tag: "opening",
                largeBizCd: _vm.largeBizCd,
                visitorView: _vm.visitorView,
              },
            }),
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "content-shop-analytics" },
                [
                  _c("ShopSearchHeader", {
                    attrs: {
                      areas: _vm.areaCode,
                      genres: _vm.bizCode,
                      pref: _vm.pref,
                      city: _vm.city,
                      large: _vm.largeBizCd,
                      middle: _vm.middleBizCd,
                      small: _vm.smallBizCd,
                    },
                  }),
                  _c("ShopAnalyticsAxis", {
                    attrs: {
                      mode: "MODE_COUNT",
                      shopCount: _vm.storeTotal(),
                      created: { year: 2020, month: 10 },
                    },
                  }),
                  _c("ShopAnalyticsGraph", {
                    attrs: {
                      title: "営業開始時間",
                      labels: _vm.beautyOpening.labels
                        ? _vm.beautyOpening.labels
                        : [],
                      "data-collection": _vm.beautyOpening.data
                        ? _vm.beautyOpening.data
                        : [],
                    },
                  }),
                  _vm._m(0),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "notice" }, [
      _vm._v(
        " 公開情報を基に当社が独自で行った分析結果になりますので参考値としてご利用ください。"
      ),
      _c("br"),
      _vm._v(
        " 情報を公開している店舗に曜日別等で営業開始時間が複数ある場合、店舗ごとの最も早い営業時間を基に分析を行っております。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }