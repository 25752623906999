<template>
  <Menu>
    <ul class="list-month">
      <li v-for="month in months" :key="month.month" :class="{ selected: month.year == $route.params.year && month.month == $route.params.month }">
        <p><a href="" @click.prevent="onClick(month)">{{ month.year }}年{{ zeroPadding(month.month) }}月</a></p>
      </li>
    </ul>
  </Menu>
</template>

<script>
import Menu from '@/components/menu/Menu.vue';
import mixin from '@/mixin/index.js';

export default {
  name: 'CalendarMenu',

  components: {
    Menu
  },

  computed: {
    months() {
      const today = new Date();
      let month = new Date(today.getFullYear(), today.getMonth(), 1);
      const months = []
      for (let i = 0; i < 12; i++) {
        months.push(this.getDateObj(month));
        month.setMonth(month.getMonth() + 1);
      }
      return months;
    }
  },

  methods: {
    async onClick(dateObj) {
      this.$router.push('/calendar/' + dateObj.year + '/' + this.zeroPadding(dateObj.month)).catch(()=>{});
    }
  },

  mixins: [mixin]
};
</script>

<style scoped lang="scss">
.menu {
  ul {
    li {
      p {
        a {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
